import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setSectionDataToAnalyticsResultView } from "actions";

import "./ParticipantCourseSummarySection.scss";

import dateFormat from "utils/dateFormat";

function ParticipantCourseSummarySection(props) {
  const { t } = useTranslation();

  const { sectionProgress } = props;
  const dispatch = useDispatch();

  const cls =
    "Participant-course-summary-section" +
    (sectionProgress.startedAt
      ? " Participant-course-summary-section--isOngoing"
      : "") +
    (sectionProgress.success
      ? " Participant-course-summary-section--isSuccess"
      : "") +
    (sectionProgress.verified
      ? " Participant-course-summary-section--isVerified"
      : "");

  const nonSuccessChapters = sectionProgress.chapters.filter(chapter => {
    return !chapter.success;
  });

  return (
    <div className={cls}>
      <header className="Participant-course-summary-section__header">
        <h2 className="Participant-course-summary-section__heading">
          {t("SECTION")}:{" "}
          <span className="Participant-course-summary-section__headinghighlight">
            {sectionProgress.title}
          </span>
        </h2>
        <h3 className="Participant-course-summary-section__subheading">
          {/* TODO check startedAt to see if unstarted */}
          {sectionProgress.success ? t("PASSED") : sectionProgress.startedAt ? t("analytics.ONGOING") : t("analytics.UNSTARTED")}
        </h3>
        {sectionProgress.success ? (
          <h3 className="Participant-course-summary-section__subheading Participant-course-summary-section__subheading--verifiedstatus">
            {sectionProgress.verified ? t("analytics.VERIFIED") : t("analytics.NOT_YET_VERIFIED")}
          </h3>
        ) : null}
      </header>
      {!sectionProgress.success ? (
        <div className="Participant-course-summary-section__breakdown">
          <h4 className="Participant-course-summary-section__breakdownheading">
            {t("analytics.CHAPTER_MISSING_PASSED_TASKS_ARE")}:
          </h4>
          <ul className="Participant-course-summary-section__breakdownlist">
            {nonSuccessChapters.map(chapter => {
              return (
                <li
                  key={chapter.id}
                  className="Participant-course-summary-section__breakdownitem"
                >
                  <span>{chapter.title}</span>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      <button
        type="button"
        className="Participant-course-summary-section__detailslink"
        onClick={() =>
          dispatch(setSectionDataToAnalyticsResultView(sectionProgress))
        }
      >
        {t("analytics.SEE_ALL_THE_PARTICIPANTS_ANSWERS")}
      </button>

      {sectionProgress.startedAt ? (
        <p className="Participant-course-summary-section__date">
          {t("analytics.STARTED_ON_X", { date: dateFormat(sectionProgress.startedAt) })}
        </p>
      ) : null}

      {/* TODO is this value correct? */}
      {sectionProgress.verifiedAt ? (
        <p className="Participant-course-summary-section__date">
          {t("analytics.VERIFIED_LAST_ON_X", {
            date: dateFormat(sectionProgress.verifiedAt)
          })}
        </p>
      ) : null}
    </div>
  );
}

export default ParticipantCourseSummarySection;
