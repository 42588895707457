import React from "react";

import FactBlock from "./FactBlock";

function BucLmsBuilderColumnItem(props) {
  const { block } = props;
  return (
    <div className="Fact-block--BucLmsBuilderColumnItem">
      {block.innerBlocks.map((innerBlock, i) => {
        return (
          <FactBlock
            key={i}
            block={innerBlock}
            allowedBlocks={[
              "CoreEmbed",
              "CoreHeading",
              "CoreImage",
              "CoreList",
              "CoreParagraph",
              "BucLmsBuilderImageAreaMap",
              "BucLmsBuilderImagePointMap",
              "BucLmsBuilderModal"
            ]}
          />
        );
      })}
    </div>
  );
}

export default BucLmsBuilderColumnItem;
