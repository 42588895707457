import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import getUniqueEnoughId from "utils/getUniqueEnoughId";
import FactBlock from "components/FactBlock/FactBlock";

function BucLmsBuilderRepetitionQuestion(props) {
  const { block } = props;
  const { attributes, innerBlocks } = block;

  const { t } = useTranslation();

  const [textInput, setTextInput] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const inputEl = useRef(getUniqueEnoughId());

  const handleButtonClick = (e) => {
    setShowAnswer(true);
  };
  useEffect(() => {
    if(showAnswer && !textInput.length) {
      setShowAnswer(false);
    }
  }, [textInput, showAnswer]);
  
  return (
    <div className="Fact-block--BucLmsBuilderRepetitionQuestion">
      <div className="Fact-block--BucLmsBuilderRepetitionQuestion__question-text">
        { attributes.questionText}
      </div>
      <div className="Fact-block--BucLmsBuilderRepetitionQuestion__wrap">
        <div 
          className="Fact-block--BucLmsBuilderRepetitionQuestion__input-area"
          >
          <label
            className="Fact-block--BucLmsBuilderRepetitionQuestion__label"
            htmlFor={inputEl}
          >
            {t("YOUR_ANSWER")}
          </label>
          <textarea
            id={inputEl}
            type="text"
            value={textInput}
            placeholder={t("TYPE_YOUR_ANSWER_HERE")}
            className={
              `Fact-block--BucLmsBuilderRepetitionQuestion__input ${textInput.length > 0 ?
              'Fact-block--BucLmsBuilderRepetitionQuestion__input--active' : ''}`
            }
            onChange={(e) => setTextInput(e.target.value)}
          />
        </div>
        <div className={`Fact-block--BucLmsBuilderRepetitionQuestion__result 
          ${showAnswer ? 'Fact-block--BucLmsBuilderRepetitionQuestion__result--visible' : ''}`}
        >
          {showAnswer ? (
            <>
              <h3
                className="Fact-block--BucLmsBuilderRepetitionQuestion__label"
              >
                {t("CORRECT_ANSWER")}
              </h3>
              <div
                className="Fact-block--BucLmsBuilderRepetitionQuestion__result__text"
                >
                  {innerBlocks.map((innerBlock, i) => {
                    return <FactBlock key={i} block={innerBlock} />;
                  })}
              </div>
            </>
          ) : null }
        </div>
       
      </div>
      <div className="Fact-block--BucLmsBuilderRepetitionQuestion__show-result">
      {!showAnswer ? (

        <button
          type="button"
          disabled={!textInput.length}
          className="Fact-block--BucLmsBuilderRepetitionQuestion__show-result__btn"
          onClick={(e) => handleButtonClick(e)}
          >
          {t("ANSWER_AND_MOVE_ON")}
        </button>
      ) : 
        attributes.closingMessage ? (
          <p className="Fact-block--BucLmsBuilderRepetitionQuestion__show-result__text">
            {attributes.closingMessage}
          </p>
        ) : null
      }
      </div>
    </div>
  );
}
export default BucLmsBuilderRepetitionQuestion;