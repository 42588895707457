import React from "react";

import Carousel from "components/Carousel/Carousel";
function BucLmsBuilderSlider(props) {
  return (
    <div className="Fact-block--BucLmsBuilderSlider">
      <Carousel block={props.block} />
    </div>
  );
}
export default BucLmsBuilderSlider;
