import React from "react";
import { useTranslation } from "react-i18next";

import "./OverlayCloseButton.scss";

function OverlayCloseButton(props) {
  const { t } = useTranslation();
  const text = props.text || t("CLOSE");
  const cls =
    "Overlay-close-button" +
    (props.transparent ? " Overlay-close-button--transparent" : "") +
    (props.absolutePos ? " Overlay-close-button--absolutePos" : "");
  return (
    <button type="button" className={cls} onClick={props.fn}>
      {text}
      {props.hideIcon ? null : (
        <span className="Overlay-close-button__icon"></span>
      )}
    </button>
  );
}

export default OverlayCloseButton;
