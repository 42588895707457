import React from "react";

import FactBlock from "./FactBlock";

function BucLmsBuilderColumns(props) {
  const { block } = props;
  const noOfColumns = block.innerBlocks.length;
  if (!noOfColumns) return null;

  const cls =
    "Fact-block--BucLmsBuilderColumns" +
    ` Fact-block--BucLmsBuilderColumns--${noOfColumns}`;
  return (
    <div className={cls}>
      {block.innerBlocks.map((innerBlock, i) => {
        return (
          <FactBlock
            key={i}
            block={innerBlock}
            allowedBlocks={["BucLmsBuilderColumnItem"]}
          />
        );
      })}
    </div>
  );
}

export default BucLmsBuilderColumns;
