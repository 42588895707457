import _ from "lodash";

import envConfig from "environment.json";

import isTaskSuccess from "utils/isTaskSuccess";
import getTaskResults from "utils/getTaskResults";

import fetchErrorHandler from "utils/fetchErrorHandler";
import getTrackerActorFromUser from "utils/getTrackerActorFromUser";
import getCourseVersionFromUser from "utils/getCourseVersionFromUser";
import getTrackerReqHeaders from "utils/getTrackerReqHeaders";
import getPlaceholderTaskgroupId from "utils/getPlaceholderTaskgroupId";

import { IS_SCORM, IS_DEMO } from "consts";

import { OBJECTS, VERBS, EXTENSIONS } from "./types";
import { setCourseState as setOfflineCourseState } from "offline-tracker/api";

export const postCourseInitialized = async (
  courseData,
  eduadminId,
  courseVersion,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id: courseData.blbCourseId,
      type: OBJECTS.COURSE,
      title: courseData.titleRendered,
      extensions: {
        [EXTENSIONS.COURSESTRUCTURE]: {
          sections: courseData.blbGroupCourseSections.blbCourseSections.map(
            _section => {
              return {
                id: _section.blbCourseSectionId,
                title: _section.blbCourseSectionTitle,
                chapters: _section.blbCourseSectionChapterPosts
                  ? _section.blbCourseSectionChapterPosts.map(_chapter => {
                      return {
                        id: _chapter.blbChapterId,
                        title: _chapter.titleRendered,
                        taskGroups: !_.isEmpty(
                          _chapter.blbGroupChapterTasks.blbChapterTasks
                        )
                          ? _chapter.blbGroupChapterTasks.blbChapterTasks.map(
                              _task => {
                                return {
                                  id: _task.blbChapterTaskGroupId
                                };
                              }
                            )
                          : [
                              {
                                // If a chapter is empty, we add a placeholder task group
                                id: getPlaceholderTaskgroupId(
                                  _chapter.blbChapterId
                                ),
                                isPlaceholder: true
                              }
                            ]
                      };
                    })
                  : []
              };
            }
          )
        }
      }
    },
    verb: VERBS.INITIALIZED,

    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postCourseInitialized"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postSectionStarted = async (id, eduadminId, user, sco = null) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      type: OBJECTS.SECTION
    },
    verb: VERBS.STARTED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postSectionStarted"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postSectionAnswered = async (id, eduadminId, user, sco = null) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      type: OBJECTS.SECTION
    },
    verb: VERBS.ANSWERED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postSectionAnswered"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postSectionRestarted = async (
  id,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      type: OBJECTS.SECTION
    },
    verb: VERBS.RESTARTED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postSectionRestarted"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postChapterStarted = async (id, eduadminId, user, sco = null) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      type: OBJECTS.CHAPTER
    },
    verb: VERBS.STARTED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postChapterStarted"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postChapterAnswered = async (id, eduadminId, user, sco = null) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      type: OBJECTS.CHAPTER
    },
    verb: VERBS.ANSWERED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };
  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);
  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postChapterAnswered"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postTaskStarted = async (
  id,
  taskGroupId,
  taskMainContent,
  taskType,
  chapterId,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const input = {
    actor: getTrackerActorFromUser(user),
    object: {
      id,
      title: taskMainContent,
      taskGroupId,
      type: OBJECTS.TASK,
      taskType
    },
    verb: VERBS.STARTED,
    context: {
      chapter: chapterId,
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postTaskStarted"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postTaskAnswered = async (
  currentlySelectedTaskInGroup,
  taskGroupId,
  selectedValues,
  steps,
  chapterId,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";

  const input = {
    actor: getTrackerActorFromUser(user),

    object: {
      id: currentlySelectedTaskInGroup.blbTaskId,
      taskGroupId,
      type: OBJECTS.TASK
    },
    result: {
      ...getTaskResults(currentlySelectedTaskInGroup, selectedValues, steps),
      success: isTaskSuccess(currentlySelectedTaskInGroup, selectedValues),
      completion: true
    },
    verb: VERBS.ANSWERED,
    context: {
      chapter: chapterId,
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postTaskAnswered"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postPlaceholderTaskAnswered = async (
  chapterId,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";

  const taskGroupId = getPlaceholderTaskgroupId(chapterId);
  const taskId = taskGroupId + "_placeholdert";

  const input = {
    actor: getTrackerActorFromUser(user),

    object: {
      id: taskId,
      taskGroupId,
      type: OBJECTS.TASK
    },
    verb: VERBS.ANSWERED,
    context: {
      chapter: chapterId,
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = await fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postPlaceholderTaskAnswered"))
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw new Error(err);
    });
  return res;
};

export const postSectionVerified = async (
  sectionProgressObj,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";
  const id = sectionProgressObj.id;

  const input = {
    actor: getTrackerActorFromUser(user),

    object: {
      id,
      type: OBJECTS.SECTION
    },
    verb: VERBS.VERIFIED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postSectionVerified"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });

  return res;
};

export const postCourseCompleted = async (
  courseData,
  eduadminId,
  user,
  sco = null
) => {
  const url = envConfig.TRACKER_REST_API_URI + "/statements";

  const input = {
    actor: getTrackerActorFromUser(user),

    object: {
      id: courseData.blbCourseId,
      type: OBJECTS.COURSE,
      title: courseData.titleRendered
    },
    verb: VERBS.COMPLETED,
    context: {
      eduAdmin: {
        educationNumber: eduadminId
      },
      courseVersion: getCourseVersionFromUser(user)
    }
  };

  if (IS_SCORM || IS_DEMO) return await setOfflineCourseState(input, sco);

  const res = fetch(url, {
    method: "POST",
    headers: getTrackerReqHeaders(user),
    body: JSON.stringify(input)
  })
    .then(_res => fetchErrorHandler(_res, input, "postCourseCompleted"))
    .then(_res => {
      return _res.json();
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });

  return res;
};
