import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./SliderInput.scss";

import { MIN_SLIDER_DEFAULT_VALUE, MAX_SLIDER_DEFAULT_VALUE } from "consts";

import getUniqueEnoughId from "utils/getUniqueEnoughId";

function SliderInput(props) {
  const { min, max, value, onChange, step, labelLeft, labelRight } = props;

  const [uniqueDatalistId, setUniqueDatalistId] = useState(null);

  useEffect(() => {
    const uniqid = getUniqueEnoughId();
    setUniqueDatalistId(uniqid);
  }, []);

  // Specific checking to allow 0
  const _min =
    typeof min !== "undefined" && min !== null ? min : MIN_SLIDER_DEFAULT_VALUE;
  const _max =
    typeof max !== "undefined" && max !== null ? max : MAX_SLIDER_DEFAULT_VALUE;

  const ticksLength = (_max - _min) / step;
  let datalist = [];
  for (let i = 0; i <= ticksLength; i++) {
    datalist = [...datalist, _min + step * i];
  }

  return (
    <div className="Slider-input">
      <input
        className="Slider-input__element"
        type="range"
        min={_min}
        max={_max}
        value={value}
        onChange={onChange}
        step={step}
        aria-describedby={step && uniqueDatalistId ? uniqueDatalistId : null}
      />

      {/* Only render ticks if an explicit step is set */}
      {step ? (
        <ul className="Slider-input__datalist" id={uniqueDatalistId}>
          {datalist.map((tick, i) => {
            return <li className="Slider-input__datalistitem" key={tick}>{tick}</li>;
          })}
        </ul>
      ) : null}

      {labelLeft && labelRight ? (
        <div className="Slider-input__labels">
          <span className="Slider-input__mintext">{labelLeft}</span>

          <span className="Slider-input__maxtext">{labelRight}</span>
        </div>
      ) : null}
    </div>
  );
}

SliderInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string
};

export default SliderInput;
