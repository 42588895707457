import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import TaskOrderCard from "components/TaskOrderCard/TaskOrderCard";

import "./TaskInteractionOrder.scss";

import { IS_DEBUG } from "consts";

function TaskInteractionOrder(props) {
  const { t } = useTranslation();
  const { taskData, selectFn, showAnswer } = props;
  const [options, setOptions] = useState(
    taskData.blbGroupTaskOptions.blbTaskOptionTypeOrderOptions
  );

  useEffect(() => {
    selectFn(
      options.map(item => {
        return {
          optionId: item.blbTaskTypeOrderOptionId,
          extraData: item.blbTaskTypeOrderOptionCorrectPosition
        };
      })
    );
  }, []);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    if (dragIndex !== undefined) {
      setOptions(prevOptions => {
        var temp = prevOptions[dragIndex];
        prevOptions[dragIndex] = prevOptions[hoverIndex];
        prevOptions[hoverIndex] = temp;

        return [...prevOptions];
      });
    }
  }, []);
  const onDrop = () => {
    selectFn(
      options.map(item => {
        return {
          optionId: item.blbTaskTypeOrderOptionId,
          extraData: item.blbTaskTypeOrderOptionCorrectPosition
        };
      })
    );
  };

  const renderOptions = (option, index) => {
    const correctObj = options.find(
      o => parseInt(o.blbTaskTypeOrderOptionCorrectPosition) === index + 1
    );
    return (
      <TaskOrderCard
        key={option.blbTaskTypeOrderOptionId}
        baseClass="Task-interaction-order"
        taskOption={option}
        correctAnswer={correctObj.blbTaskTypeOrderOptionLabel}
        position={index}
        showAnswer={showAnswer}
        IS_DEBUG={IS_DEBUG}
        moveCard={moveCard}
        onDrop={onDrop}
      />
    );
  };

  return (
    <div className="Task-interaction-order">
      <ol className="Task-interaction-order__list">
        {options.map((item, i) => {
          return (
            <li key={i} className="Task-interaction-order__order-listitem">
              {i + 1}
            </li>
          );
        })}
      </ol>
      <DndProvider options={HTML5toTouch}>
        <ul className="Task-interaction-order__list">
          {options.map((option, i) => renderOptions(option, i))}
        </ul>
      </DndProvider>
    </div>
  );
}

export default TaskInteractionOrder;
