// This stores user/session-related data
// that is not part of oidc

const session = (
  state = {
    participantId: null,
    accessStatus: null,
    accessStatusRefreshKey: 0,
    userLogOut: false,
    referrer: ""
  },
  action
) => {
  switch (action.type) {
    case "SET_ACCESS_STATUS":
      return {
        ...state,
        accessStatus: action.data,
      };
    case "NOTIFY_ACCESS_STATUS_CHANGE":
      return {
        ...state,
        accessStatusRefreshKey: state.accessStatusRefreshKey + 1,
      };
    case "SET_USER_LOGOUT":
      return {
        ...state,
        userLogOut: action.data,
      };
    case "SET_REFERRER":
      return {
        ...state,
        referrer: action.data,
      };

    default:
      return state;
  }
};

export default session;
