import { useState, useEffect } from "react";

import fetchErrorHandler from "utils/fetchErrorHandler";
import debugLog from "utils/debugLog";

import { IS_SCORM, IS_DEMO } from "consts";

const POLL_SECONDS = 60;

const getMaintenanceMode = async () => {
  const res = await fetch(`/maintenance.json?nocache=${Date.now()}`)
    .then(_res => fetchErrorHandler(_res, {}, "getMaintenanceMode"))
    .then(_res => {
      return _res.json();
    })
    .then(_res => {
      return _res ? _res.IS_MAINTENANCE_MODE : null;
    })
    .catch(() => {
      return null;
    });

  return res;
};

function useIsMaintenanceMode() {
  const [maintenanceModeResult, setMaintenanceModeResult] = useState(null);

  // First do an immediate check by getting
  // maintenance.json. Then set up polling
  // to see if it changes while session is active.
  // This is so that maintenance flag can be changed
  // without building/deploying via git.
  useEffect(() => {
    if (IS_SCORM || IS_DEMO) return;
    getMaintenanceMode().then(_res => {
      debugLog(`Initial maintenance flag get was ${_res}`);
      setMaintenanceModeResult(_res);
    });

    let interval = setInterval(() => {
      getMaintenanceMode().then(_res => {
        setMaintenanceModeResult(_res);
      });
    }, POLL_SECONDS * 1000);

    // Clear interval when effect is about to re-run,
    // or be deactivated/unmounted.
    return () => clearInterval(interval);
  }, []);

  return IS_SCORM || IS_DEMO ? false : maintenanceModeResult;
}

export default useIsMaintenanceMode;
