import React from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setFactContentModalToView } from "actions";

import FactBlock from "components/FactBlock/FactBlock";

import "./FactContentModalOverlay.scss";

function FactContentModalOverlay() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const factContentModalToView = useSelector(
    state => state.layout.factContentModalToView
  );

  const close = (e) => {
    e.preventDefault();
    dispatch(setFactContentModalToView(null));
  };

  return (
    <div className="Fact-content-modal-overlay">
      {_.isArray(factContentModalToView) ? (
        <div className="Fact-content-modal-overlay__blocks">
          {factContentModalToView.map((innerBlock, i) => {
            return <FactBlock key={i} block={innerBlock} />;
          })}
        </div>
      ) : <p>{factContentModalToView}</p>}

      <a
        className="Fact-content-modal-overlay__closelink"
        href="#"
        onClick={close}
      >
        {t("CLOSE")}
      </a>
    </div>
  );
}

export default FactContentModalOverlay;
