import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import {
  setSectionToResultView,
  setSectionDataToAnalyticsResultView
} from "actions";

import "./SectionResultViewOverlay.scss";

import useSectionProgress from "hooks/useSectionProgress";
import useCurrentCourse from "hooks/useCurrentCourse";
import { GQL_SECTIONS_NEST } from "consts";

import getCompletedTaskProgressesFromChapterProgress from "utils/getCompletedTaskProgressesFromChapterProgress";

import OverlayCloseButton from "components/OverlayCloseButton/OverlayCloseButton";
import ChapterResult from "components/ChapterResult/ChapterResult";

function SectionResultViewOverlay() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sectionToResultView = useSelector(
    state => state.course.sectionToResultView
  );
  const analyticsSectionProgress = useSelector(
    state => state.analytics.sectionProgress
  );
  const { data } = useCurrentCourse();
  const allSections = data ? _.get(data, GQL_SECTIONS_NEST) : null;

  const section = allSections
    ? _.find(allSections, section => {
        return section.blbCourseSectionId === sectionToResultView;
      })
    : null;
  const sectionProgress = useSectionProgress(section);

  const close = () => {
    dispatch(setSectionToResultView(null));
    dispatch(setSectionDataToAnalyticsResultView(null));
  };

  const isInAnalytics = !!analyticsSectionProgress;

  const sectionProgressToUse = isInAnalytics
    ? analyticsSectionProgress
    : sectionProgress;

  if (!sectionProgressToUse) return null;

  // TODO will there ever be an instance
  // were we could end up with incomplete
  // chapters here?
  const completedChapters = sectionProgressToUse.chapters.filter(chapter => {
    return chapter.completed;
  });
  const completedChaptersCount = completedChapters.length;

  // 'tasks' array in each task group
  // will contain every attempt the
  // use made
  const completedTaskProgresses = _(completedChapters)
    .map(chapter => {
      return getCompletedTaskProgressesFromChapterProgress(chapter);
    })
    .flatten()
    .value();

  const completedTaskProgressesCount = completedTaskProgresses.length;

  const successTasks = completedTaskProgresses.filter(task => {
    return task.success;
  });
  const failTasks = completedTaskProgresses.filter(task => {
    return !task.success;
  });
  return (
    <div className="Section-result-view-overlay">
      <OverlayCloseButton fn={close} />
      <h2 className="Section-result-view-overlay__heading">
        {t("X_COMPLETED_TASKS", {
          sectionTitle: sectionProgressToUse.title
        })}
      </h2>
      {!isInAnalytics ? (
        <p className="Section-result-view-overlay__text">
          <strong className="Bold">{t("SECTION_RESULT_VIEW_1_TEXT")}</strong>{" "}
          {t("SECTION_RESULT_VIEW_2_TEXT")}
        </p>
      ) : null}

      <p className="Section-result-view-overlay__text">
        {/* Weird Trans component is weird */}
        <Trans
          i18nKey="YOU_COMPLETED_X_TASKS_ON_Y_CHAPTERS"
          completedChaptersCount={completedChaptersCount}
          completedTaskProgressesCount={completedTaskProgressesCount}
        >
          <strong className="Bold">{{ completedTaskProgressesCount }}</strong>
          <span>{{ completedChaptersCount }}</span>
        </Trans>
      </p>
      <div className="Section-result-view-overlay__summary">
        <div className="Section-result-view-overlay__summarypart Section-result-view-overlay__summarypart--success">
          {t("X_SUCCESS_ANSWERS", { successCount: successTasks.length })}
        </div>
        <div className="Section-result-view-overlay__summarypart Section-result-view-overlay__summarypart--fail">
          {t("X_FAIL_ANSWERS", { failCount: failTasks.length })}
        </div>
      </div>
      <div className="Section-result-view-overlay__chapters">
        {completedChapters.map((chapterProgress, i) => {
          return (
            <ChapterResult
              key={chapterProgress.id}
              chapterProgress={chapterProgress}
              chapterNumber={i + 1}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SectionResultViewOverlay;
