import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCS from "./lang/cs.json";
import translationDE from "./lang/de.json";
import translationEN from "./lang/en.json";
import translationET from "./lang/et.json";
import translationFI from "./lang/fi.json";
import translationLT from "./lang/lt.json";
import translationPL from "./lang/pl.json";
import translationRU from "./lang/ru.json";
import translationSK from "./lang/sk.json";
import translationSV from "./lang/sv.json";
import { IS_DEBUG } from "consts";

const knownCourseSlugs = {
  "safe-construction-training-slovak": "sk",
  "safe-construction-training-russian": "ru",
  "safe-construction-training-lithuanian": "lt",
  "safe-construction-training-german": "de",
  "safe-construction-training-finnish": "fi",
  "safe-construction-training-polish": "pl",
  "safe-construction-training-estonian": "et",
  "safe-construction-training-english": "en",
  "safe-construction-training-czech": "cs"
};

// Try to find and match the course
// slug from URL to set the initial
// language, so we don't have to
// be dependent on fetching it from WP
let initialLang = "sv";
const slugPrefix = "/course/";
const slugIndex = document.URL.indexOf(slugPrefix);
let slug =
  slugIndex !== -1 ? document.URL.substring(slugIndex + slugPrefix.length) : "";

if (slug) {
  slug =
    slug.indexOf("/") !== -1
      ? slug.substring(0, slug.indexOf("/"))
      : slug.indexOf("?") !== -1
      ? slug.substring(0, slug.indexOf("?"))
      : slug;

  if (knownCourseSlugs[slug]) initialLang = knownCourseSlugs[slug];
}

i18n
  .use(initReactI18next)
  .on("languageChanged", lng => {
    // Set 'lang' attribute on <html>
    if (document) {
      document.documentElement.lang = lng;
    }
  })
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: initialLang,
    fallbackLng: "sv",
    debug: IS_DEBUG,
    resources: {
      cs: {
        translation: translationCS
      },
      de: {
        translation: translationDE
      },
      en: {
        translation: translationEN
      },
      et: {
        translation: translationET
      },
      fi: {
        translation: translationFI
      },
      lt: {
        translation: translationLT
      },
      pl: {
        translation: translationPL
      },
      ru: {
        translation: translationRU
      },
      sk: {
        translation: translationSK
      },
      sv: {
        translation: translationSV
      }
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
