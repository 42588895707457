import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useQuery as useApolloQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "./ResourcePage.scss";

import { IS_SCORM, IS_DEMO, BLOCK_GROUP_NAME, MY_PAGES_URL } from "consts";

import { setTheme } from "actions";

import { GET_PAGE } from "apollo/queries";
import debugLog from "utils/debugLog";
import getLargeAcfImgUrl from "utils/getLargeAcfImgUrl";

import CourseNotFound from "components/CourseNotFound/CourseNotFound";
import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";
import FactsSection from "components/FactsSection/FactsSection";
import FactBlock from "components/FactBlock/FactBlock";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import bucLogo from "images/logos/BUCStand_Tag_loggo_white.svg";

function ResourcePage() {
  const { pageSlug } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const didSetTheme = useSelector(state => state.layout.didSetTheme);

  const { data, loading, error } = useApolloQuery(GET_PAGE, {
    variables: { pageSlug },
    skip: IS_SCORM || !pageSlug // Not supported in SCORM versions
  });

  useEffect(() => {
    if (document && document.body) {
      document.body.classList.add("is-resource-page");
    }

    return () => {
      if (document && document.body) {
        document.body.classList.remove("is-resource-page");
      }
    };
  }, []);

  useEffect(() => {
    if (
      data &&
      data.page &&
      data.page.blbGroupCourseTheme &&
      data.page.blbGroupCourseTheme.blbCourseTheme &&
      !didSetTheme
    ) {
      const themeToSet = data.page.blbGroupCourseTheme.blbCourseTheme;
      debugLog("Setting theme", themeToSet);
      dispatch(setTheme(themeToSet));
    }
  }, [data, dispatch, didSetTheme]);

  if (loading) {
    if (loading) return <Loading isFullPage={true} />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (IS_SCORM || IS_DEMO || !data || (data && !data.page)) return <CourseNotFound />;

  const { page } = data;

  const hasHeroImg = !!page.blbGroupPageHero.blbPageHeroImage;

  const cls =
    "Resource-page" + (hasHeroImg ? " Resource-page--withHeroImg" : "");

  return (
    <article className={cls}>
      <header className="Resource-page__hero">
        {hasHeroImg ? (
          <div
            className="Resource-page__herobg"
            style={{
              backgroundImage: `url(${getLargeAcfImgUrl(
                page.blbGroupPageHero.blbPageHeroImage
              )})`
            }}
          />
        ) : null}
        <div className="Resource-page__herotext">
          <h1 className="Resource-page__heroheading">{page.title}</h1>
          {page.blbGroupPageHero.blbPageHeroSubtitle ? (
            <p className="Resource-page__herosubheading">
              {page.blbGroupPageHero.blbPageHeroSubtitle}
            </p>
          ) : null}
        </div>
        <div className="Resource-page__herologo">
          {/* For the Resource pages, a different variant BUC logo
          is used, regardless of theme. */}
          <img
            src={bucLogo}
            alt="BUC logo"
          />
        </div>
      </header>
      <section className="Resource-page__content">
        <div className="Resource-page__blocks">
          {page.blocks.map((block, i) => {
            if (block.name === BLOCK_GROUP_NAME) {
              return (
                <div key={i} className="Resource-page__section">
                  <FactsSection
                    block={block}
                    isFirst={i === 0}
                    isLast={i === page.blocks.length - 1}
                    largeOnSmallerScreen={true}
                  />
                </div>
              );
            } else {
              return <FactBlock key={i} block={block} />;
            }
          })}
        </div>
      </section>
      <footer className="Resource-page__footer">
        <div className="Resource-page__footerinner">
          <a
            className="Resource-page__footerlink"
            target="_blank"
            rel="noopener noreferrer"
            href={MY_PAGES_URL}
          >
            <BackIcon />
            {t("BACK_TO_MY_PAGES_ON_BUC")}
          </a>
        </div>
      </footer>
    </article>
  );
}

export default ResourcePage;
