import useQuery from "hooks/useQuery";
import { useParams } from "react-router-dom";
import { useQuery as useApolloQuery } from "@apollo/client";

import { GET_COURSE_METADATA } from "apollo/queries";

import { IS_FORCE_PREVIEW, IS_SCORM } from "consts";

import randomIntFromInterval from "utils/randomIntFromInterval";
import randomLetters from "utils/randomLetters";

import debugLog from "utils/debugLog";

// If we're in preview mode, we'll fake a eduadminId with some
// random letters, numbers and timestamp to get a unique session.
// Declared outside of hook to persist it until reload.
const randomPreviewId = `preview-${randomLetters(4)}${randomIntFromInterval(
  1,
  9999999
)}${Date.now()}`;
// randomPreviewId för att under debug göra att progressen inte försviner
/* const randomPreviewId = "preview-349293492349"; */
function useCurrentEduadminId() {
  let r = "";
  const query = useQuery();
  const { courseSlug } = useParams();

  const { data } = useApolloQuery(GET_COURSE_METADATA, {
    variables: { courseSlug },
    skip: IS_SCORM
  });

  if ((!data || (data && !data.course)) && !IS_SCORM) return "";

  const previewQueryParam = query.get("preview");

  if (previewQueryParam === "true" || IS_FORCE_PREVIEW || IS_SCORM) {
    r = randomPreviewId;
  } else {
    r =
      data.course.blbGroupCourseEduadminId &&
      data.course.blbGroupCourseEduadminId.blbCourseEduadminId
        ? data.course.blbGroupCourseEduadminId.blbCourseEduadminId
        : "";
  }

  debugLog("useCurrentEduadminId", r);

  return r;
}

export default useCurrentEduadminId;
