import React, { forwardRef } from "react";

const CoreTable = forwardRef(function CoreTable(props, ref) {
  const { block } = props;
  return (
    <div ref={ref} className="Fact-block--CoreTable" dangerouslySetInnerHTML={{ __html: block.originalContent }} />
  );
})

export default CoreTable;
