import { TASK_OPTION_TYPES } from "consts";
import getTaskType from "utils/getTaskType";

function taskHasMultipleRightAnswers(taskData) {
  const type = getTaskType(taskData);
  if (!(type === TASK_OPTION_TYPES.TEXT || type === TASK_OPTION_TYPES.IMAGE))
    return false;
  const key = type;
  const optionsKey = `blbTaskOptionType${key}Options`;
  const correctKey = `blbTaskType${key}OptionCorrect`;
  const taskOptions = taskData.blbGroupTaskOptions[optionsKey];
  const rightAnswers = taskOptions.filter(opt => {
    return !!opt[correctKey];
  });
  // If there are more than 1 right answer
  return rightAnswers.length > 1;
}

export default taskHasMultipleRightAnswers;
