import React from "react";
import { useTranslation } from "react-i18next";

import { MY_PAGES_URL, IS_SCORM, IS_DEMO, SUPPORT_EMAIL } from "consts";

import "./CourseNotFound.scss";

import Error from "components/Error/Error";

function CourseNotFound(props) {
  const { t } = useTranslation();

  return (
    props.isFasttrack ? (
      <Error
        error={t("SCT_COURSE_NOT_FOUND_ERROR_TEXT")}
        linkEmail={SUPPORT_EMAIL}
      />
    ) : (
      <Error
        error={t("COURSE_NOT_FOUND_ERROR_TEXT")}
        linkUrl={IS_SCORM || IS_DEMO ? null : MY_PAGES_URL}
        linkLabel={IS_SCORM || IS_DEMO ? null : t("BACK_TO_MY_PAGES_ON_BUC")}
      />
    )
  );
}

export default CourseNotFound;
