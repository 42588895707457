import React, { useMemo, useEffect, useRef } from "react";
import getUserManager from "userManager";

import "./FactBlock.scss";
import { useDispatch } from "react-redux";
import { setFactContentModalToView } from "actions";
import titleCase from "utils/titleCase";
import sendRollbar from "utils/sendRollbar";
import useCurrentChapter from "hooks/useCurrentChapter";

let FactBlocks = {};

FactBlocks.AcfTask = require("./AcfTask").default;
FactBlocks.BucLmsBuilderColumnItem =
  require("./BucLmsBuilderColumnItem").default;
FactBlocks.BucLmsBuilderColumns = require("./BucLmsBuilderColumns").default;
FactBlocks.BucLmsBuilderImageAreaMap =
  require("./BucLmsBuilderImageAreaMap").default;
FactBlocks.BucLmsBuilderImagePointMap =
  require("./BucLmsBuilderImagePointMap").default;
FactBlocks.BucLmsBuilderModal = require("./BucLmsBuilderModal").default;
FactBlocks.BucLmsBuilderRichList = require("./BucLmsBuilderRichList").default;
FactBlocks.BucLmsBuilderRichListItem =
  require("./BucLmsBuilderRichListItem").default;
FactBlocks.BucLmsBuilderSlider = require("./BucLmsBuilderSlider").default;
FactBlocks.BucLmsBuilderSliderItem =
  require("./BucLmsBuilderSliderItem").default;
FactBlocks.BucLmsBuilderRepetitionQuestion =
  require("./BucLmsBuilderRepetitionQuestion").default;
FactBlocks.CoreEmbed = require("./CoreEmbed").default;
FactBlocks.CoreHeading = require("./CoreHeading").default;
FactBlocks.CoreImage = require("./CoreImage").default;
FactBlocks.CoreList = require("./CoreList").default;
FactBlocks.CoreParagraph = require("./CoreParagraph").default;
FactBlocks.CoreTable = require("./CoreTable").default;

const camelCaseIt = blockName => {
  const noHyphOrSlash = blockName.replace(/-/g, " ").replace(/\//g, " ");
  return titleCase(noHyphOrSlash).split(" ").join("");
};

function FactBlock(props) {
  const { block, allowedBlocks } = props;

  const dispatch = useDispatch();
  const buttonRef = useRef(null);


  const blockName = block?.name || "";
  const userManager = useMemo(() => getUserManager(), []);
  const currentChapter = useCurrentChapter();
  const currentChapterId = useMemo(
    () => currentChapter?.data?.blbChapterId || null,
    [currentChapter]
  );

  const componentName = blockName ? camelCaseIt(blockName) : "";
  const ComponentToRender = componentName ? FactBlocks[componentName] : null;

  useEffect(() => {
    // handle text explanation - hide with css, replace with modalbtn and display text in modal
    const textExplanationElement = buttonRef.current?.querySelectorAll('.buc-text-explanation');
    if (textExplanationElement) {
      textExplanationElement.forEach(el => {
        // Create a modalbtn
        const explanationModalBtn = document.createElement('button');
        explanationModalBtn.classList.add('text-explain-button');
        explanationModalBtn.innerHTML = '<span>?</span>';
        explanationModalBtn.addEventListener('click', function handleClick(event) {
          openModal();
        });
        const openModal = () => {
          // set modal text
          dispatch(setFactContentModalToView(el.innerText));
        };
        // replace text explanation with modalbtn
        return el && el.replaceWith(explanationModalBtn);
      })
    }
}, [dispatch, buttonRef]);

  useEffect(() => {
    if (!componentName) {
      // If block has no name for whatever reason,
      // report to Rollbar
      let blockJson = "";
      try {
        blockJson = JSON.stringify(block);
      } catch (e) {
        //
      }
      sendRollbar(
        "Block name missing!",
        {
          blockJson,
          chapterId: currentChapterId
        },
        userManager
      );
      return null;
    } else if (componentName && !ComponentToRender) {
      // If unknown fact block (could hypothetically occur on
      // courseversion/code version mismatch), report to Rollbar
      sendRollbar(`No such fact block component: ${componentName}`, {
        chapterId: currentChapterId
      }, userManager);
    }
  }, [componentName, ComponentToRender, block, currentChapterId, userManager]);

  if (
    !ComponentToRender ||
    (allowedBlocks &&
      allowedBlocks.length &&
      !allowedBlocks.includes(componentName))
  )
    return null;
  return <ComponentToRender block={block} ref={buttonRef} />;
}

export default FactBlock;
