import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "./CourseHeader.scss";

import { toggleChapterNav, setPrefaceExpanded } from "actions";
import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentSection from "hooks/useCurrentSection";
import useWindowSize from "hooks/useWindowSize";

import CourseSectionBreadcrumb from "components/CourseSectionBreadcrumb/CourseSectionBreadcrumb";
import Error from "components/Error/Error";
import HomeLink from "components/HomeLink/HomeLink";
import Loading from "components/Loading/Loading";
import OverlayCloseButton from "components/OverlayCloseButton/OverlayCloseButton";

import { ReactComponent as ByggforetagenLogo } from "images/logos/byggforetagen.svg";
import { ReactComponent as ArrangorsutbildningarLogo } from "images/logos/arrangorsutbildningar.svg";
import { ReactComponent as BucLogo } from "images/logos/buc.svg";
import { ReactComponent as EyeIcon } from "images/icons/eye.svg";
import { ReactComponent as BackIcon } from "images/icons/back.svg";

const themeLogos = {
  byggforetagen: ByggforetagenLogo,
  buc: BucLogo,
  arrangorsutbildningar: ArrangorsutbildningarLogo
};

function CourseHeader(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSection = useSelector(state => state.course.selectedSection);
  const theme = useSelector(state => state.layout.theme);
  const prefaceExpanded = useSelector(state => state.layout.prefaceExpanded);
  const { isInNav, subPageName, isFasttrack } = props;

  const courseObj = useCurrentCourse();
  const sectionObj = useCurrentSection();

  const windowSize = useWindowSize();

  if (courseObj.loading || sectionObj.loading) return <Loading />;
  if (courseObj.error) return <Error error={courseObj.error} />;
  if (sectionObj.error) return <Error error={sectionObj.error} />;

  if (isInNav && windowSize.isDesktop) return null;

  const switchToBreadcrumb = selectedSection || subPageName;

  const cls =
    "Course-header" +
    (isInNav ? " Course-header--isInNav" : "") +
    (switchToBreadcrumb ? " Course-header--withBreadcrumb" : "") +
    (!subPageName && sectionObj.data
      ? ` Course-header--sectionstatus${sectionObj.data.progress.status}`
      : "");

  const shouldHideNavtoggle =
    !switchToBreadcrumb || windowSize.isDesktop || subPageName;

  const Logo = themeLogos[theme];

  return (
    <header className={cls}>
      {shouldHideNavtoggle ? null : (
        <button
          aria-label={t("CONTENT")}
          className="Course-header__navtoggle"
          onClick={() => dispatch(toggleChapterNav())}
        >
          <EyeIcon />
        </button>
      )}
      {!shouldHideNavtoggle && isInNav ? (
        <OverlayCloseButton
          fn={() => dispatch(toggleChapterNav())}
          text={isInNav ? t("CLOSE") : t("CONTENT")}
          hideIcon={!isInNav}
          transparent={!isInNav}
          absolutePos={true}
        />
      ) : null}
      {switchToBreadcrumb ? (
        <CourseSectionBreadcrumb isInNav={isInNav} subPageName={subPageName} />
      ) : (
        <div>
          <div className="Course-header__initial">
            <HomeLink
              className="Course-header__heading"
              linkText={courseObj.data.course.titleRendered}
            />
            {!isFasttrack && Logo ? (
              <div className="Course-header__logo">
                <Logo />
              </div>
            ) : null}
          </div>
          <div
            className={
              "Course-header__prefacelink" +
              (prefaceExpanded ? " Course-header__prefacelink--expanded" : "")
            }
          >
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                dispatch(setPrefaceExpanded(!prefaceExpanded));
              }}
            >
              <BackIcon />
              {t("ABOUT_THIS_COURSE")}
            </a>
          </div>
        </div>
      )}
    </header>
  );
}

export default CourseHeader;
