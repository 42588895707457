import React from "react";
import { useDispatch } from "react-redux";

import ParagraphFromHtml from "components/ParagraphFromHtml/ParagraphFromHtml";

import { setFactContentModalToView } from "actions";

function BucLmsBuilderModal(props) {
  const { block } = props;
  const { attributes } = block;
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(setFactContentModalToView(block.innerBlocks));
  };
  const isSvg = attributes?.triggerImage.endsWith('.svg');

  const mainCls = "Fact-block--BucLmsBuilderModal";
  const cls =
    `${mainCls} ${mainCls}--size${attributes.size} ${mainCls}--align${attributes.alignment}` +
    (attributes.triggerType === "image" ? ` ${mainCls}--isimg` : "") + 
    (isSvg ? ` ${mainCls}--isSvg` : "");

  return (
    <div className={cls}>
      {attributes.triggerType === "image" ? (
        <a
          className="Fact-block--BucLmsBuilderModal__trigger-imgwrap"
          href="#"
          onClick={e => {
            e.preventDefault();
            openModal();
          }}
        >
          <img
            className="Fact-block--BucLmsBuilderModal__trigger-img"
            src={attributes.triggerImage}
            alt={attributes.triggerImageAlt}
          />
        </a>
      ) : attributes.triggerType === "text-and-button" ? (
        <div className="Fact-block--BucLmsBuilderModal__txtbtntrigger">
          <ParagraphFromHtml
            content={block.originalContent}
            className="Fact-block--BucLmsBuilderModal__trigger-text"
          />
          <button
            className="Fact-block--BucLmsBuilderModal__trigger-btn"
            type="button"
            onClick={openModal}
          >
            {attributes.triggerButtonLabel}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default BucLmsBuilderModal;
