import React from "react";

import "./TaskContent.scss";

import VimeoVideo from "components/VimeoVideo/VimeoVideo";

function TaskContent(props) {
  const { taskContent } = props;
  return (
    <div className="Task-content">
      {taskContent.map((content, i) => {
        if (content.blbHeading) {
          return (
            <h2 key={i} className="Task-content__heading">
              {content.blbHeading}
            </h2>
          );
        } else if (content.blbRichText) {
          return (
            <div
              key={i}
              className="Task-content__rich-text"
              dangerouslySetInnerHTML={{ __html: content.blbRichText }}
            />
          );
        } else if (content.blbImage) {
          return (
            <div key={i} className="Task-content__image">
              <img
                src={content.blbImage.sourceUrl}
                alt={content.blbImage.altText}
              />
            </div>
          );
        } else if (content.blbVimeo) {
          return (
            <div key={i} className="Task-content__vimeo-video">
              <VimeoVideo vimeoUrl={content.blbVimeo} />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default TaskContent;
