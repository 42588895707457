import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as IconCheck } from "images/icons/check.svg";

import "./CourseSectionChapterNavItem.scss";

import { ENTITY_STATUSES } from "consts";

import { collapseChapterNav, setSelectedChapter } from "actions";

import useChapterProgress from "hooks/useChapterProgress";
import useWindowSize from "hooks/useWindowSize";

function CourseSectionChapterNavItem(props) {
  const { chapter, isActive, chapterNumber, statusFilter, onClick } = props;
  const dispatch = useDispatch();

  // Currently, progress should always be available
  // for all context this comp is used in, but is
  // is checked for below anyway just to be sure
  const progress = useChapterProgress(chapter);
  
  const chapterNavExpanded = useSelector(
    state => state.layout.chapterNavExpanded
  );
  const { isDesktop } = useWindowSize();

  const _onClick = onClick
    ? e => {
        e.preventDefault();
        onClick();
      }
    : e => {
        e.preventDefault();
        dispatch(collapseChapterNav());
        dispatch(setSelectedChapter(chapter.blbChapterId));
      };

  if (
    statusFilter &&
    ((progress.status && !statusFilter.includes(progress.status)) || !progress)
  )
    return null;

  const className =
    "Course-section-chapter-nav-item" +
    (isActive ? " Course-section-chapter-nav-item--active" : "") +
    (progress
      ? ` Course-section-chapter-nav-item--status${progress.status}`
      : "");

  return (
    <li className={className}>
      <a
        tabIndex={chapterNavExpanded || isDesktop ? "0" : "-1"}
        className="Course-section-chapter-nav-item__link"
        href="#"
        onClick={_onClick}
      >
        <span className="Course-section-chapter-nav-item__chapternumber">
          #{chapterNumber}
        </span>
        <span className="Course-section-chapter-nav-item__chaptertitle">
          {chapter.titleRendered}
        </span>
        {progress && progress.status === ENTITY_STATUSES.COMPLETE ? (
          <span className="Course-section-chapter-nav-item__icon">
            <IconCheck />
          </span>
        ) : null}
        {progress && progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS ? (
          <span className="Course-section-chapter-nav-item__doubleicon">
            <IconCheck />
            <IconCheck />
          </span>
        ) : null}
      </a>
    </li>
  );
}

export default CourseSectionChapterNavItem;
