import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";

import {
  setFullCourseState,
  setSectionToVerify,
  setSelectedSection,
  notifyAccessStatusChange
} from "actions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCourseProgress from "hooks/useCourseProgress";
import useCurrentSection from "hooks/useCurrentSection";
import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import { ENTITY_STATUSES, IS_SCORM } from "consts";

import {
  postSectionVerified,
  postSectionAnswered,
  postCourseCompleted
} from "tracker-api/statements";

import { getCourseState } from "tracker-api/sessions";

import "./SectionVerifierOverlay.scss";

import ButtonLoading from "components/ButtonLoading/ButtonLoading";
import ChaptersRetryList from "components/ChaptersRetryList/ChaptersRetryList";

function SectionVerifierOverlay(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSectionAnswered, setDidSectionAnswered] = useState(false);
  const [isVerifyError, setIsVerifyError] = useState(false);
  const user = useSelector(getUserWithAccessStatus);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const { data } = useCurrentCourse();

  // We can use useCurrentSection here since
  // it also checks for sectionToVerify
  const sectionObj = useCurrentSection();
  
  const eduadminId = useCurrentEduadminId();

  const section = sectionObj.data;

  const progress = section ? section.progress : null;
  const courseProgress = useCourseProgress();
  const isCourseVerifiedSuccess =
    courseProgress &&
    courseProgress.status === ENTITY_STATUSES.VERIFIED_SUCCESS;

  const close = useCallback(() => {
    dispatch(setSectionToVerify(null));
    dispatch(setSelectedSection(null));
  }, [dispatch]);

  // Immediatley send section answered event
  // to tracker when user reaches this component.
  // It's an informative event and is not crucial
  // for progress in tracker.
  useEffect(() => {
    const _postSectionAnswered = async () => {
      await postSectionAnswered(
        section.blbCourseSectionId,
        eduadminId,
        user,
        sco
      );
      const getRes = await getCourseState(eduadminId, user, true, sco);
      if (getRes) dispatch(setFullCourseState(getRes));
      setDidSectionAnswered(true);
    };
    if (!didSectionAnswered) {
      _postSectionAnswered();
    }
  }, [
    dispatch,
    didSectionAnswered,
    setDidSectionAnswered,
    eduadminId,
    user,
    section,
    sco
  ]);

  useEffect(() => {
    if (isCourseVerifiedSuccess) {
      close();
    }
  }, [isCourseVerifiedSuccess, close]);

  const verify = useCallback(async () => {
    async function _verify() {
      setIsSubmitting(true);
      setIsVerifyError(false);

      try {
        await postSectionVerified(progress, eduadminId, user, sco);
      } catch (e) {
        setIsVerifyError(true);
      }

      let getRes;
      try {
        getRes = await getCourseState(eduadminId, user, false, sco);
      } catch (e) {
        setIsVerifyError(true);
      }

      if (getRes) dispatch(setFullCourseState(getRes));

      const allSectionsSuccess =
        getRes &&
        getRes.sections.filter(section => {
          return !section.success;
        }).length === 0;

      // IMPORTANT: this triggers the grade in eduadmin
      // TODO make a retry thing?
      // TODO create combined listener hook
      // in Course.js that handles this, started statements etc
      if (allSectionsSuccess) {
        try {
          await postCourseCompleted(data.course, eduadminId, user, sco);
          
        } catch (e) {
          setIsVerifyError(true);
        }

        // Tell Course.js that it's time
        // to refresh access status, since
        // tracker will set hasActiveCertificate/hasCompletedCourse
        // to true
        dispatch(notifyAccessStatusChange());

        // We don't need to reload state here,
        // because neither tracker or scorm data
        // changes from this call
      }

      setIsSubmitting(false);
    }
    _verify();
  }, [dispatch, setIsSubmitting, data, eduadminId, progress, user, sco]);

  if (!section || isCourseVerifiedSuccess) return null;

  const chaptersCount = section.blbCourseSectionChapterPosts.length;

  const heading = progress.verified
    ? t("RESULTS_FOR_X", { title: section.blbCourseSectionTitle })
    : t("VERIFY_X", {
        title: section.blbCourseSectionTitle
      });

  const successChapters = progress.chapters.filter(chapter => {
    return chapter.success;
  });
  const successAndVerifiedChapters = progress.chapters.filter(chapter => {
    return chapter.success && chapter.verified;
  });

  const successChaptersCount = successChapters.length;
  const successAndVerifiedChaptersCount = successAndVerifiedChapters.length;

  const failChaptersCount = progress.chapters.length - successChaptersCount;

  const texts = progress.verified
    ? progress.success
      ? [
          t("CONGRATS_YOU_KNOW_X_AND_ARE_READY_FOR_NEXT_SECTION_IN_Y", {
            title: section.blbCourseSectionTitle,
            courseTitle: data.course.titleRendered
          })
        ]
      : [`${t("PRETTY_GOOD_YOU_HAVE_TO_TRY_AGAIN_WITH_CHAPTERS_BELOW")}:`]
    : [
        t("ALL_TASKS_FOR_SECTION_X_OF_Y_ARE_COMPLETED", {
          title: section.blbCourseSectionTitle,
          courseTitle: data.course.titleRendered
        }),
        t(
          successAndVerifiedChaptersCount
            ? "LETS_VERIFY_REMAINING_CHAPTERS_ARE_YOUR_READY"
            : "LETS_VERIFY_ALL_CHAPTERS_ARE_YOUR_READY"
        )
      ];

  const cls =
    "Section-verifier-overlay" +
    (progress.verified ? " Section-verifier-overlay--verified" : "") +
    (progress.success
      ? " Section-verifier-overlay--success"
      : " Section-verifier-overlay--fail");

  return (
    <div className={cls}>
      <h2 className="Section-verifier-overlay__heading">{heading}</h2>
      {texts.map(text => (
        <p key={text} className="Section-verifier-overlay__text">
          {text}
        </p>
      ))}

      <div className="Section-verifier-overlay__footer">
        {progress.verified ? (
          <h3 className="Section-verifier-overlay__footerheading">
            {t("SUM_X_CHAPTERS", { chaptersCount })}:
          </h3>
        ) : null}
        {progress.verified && progress.success ? (
          <p className="Section-verifier-overlay__footertext">
            <span className="Bold">{t("DONE")}!</span>{" "}
            <span>
              {t("ALL_X_CHAPTERS_ARE_VERIFIED_CONTINUE_WITH_REST_OF_Y", {
                chapterCount: chaptersCount,
                courseTitle: data.course.titleRendered
              })}
            </span>
          </p>
        ) : null}

        {progress.verified && !progress.success ? (
          <div>
            <p className="Section-verifier-overlay__footertext">
              {t("X_CHAPTERS_ARE_SUCCESS", {
                chaptersCount: successChaptersCount
              })}
            </p>
            <p className="Section-verifier-overlay__footertext">
              {t("X_CHAPTERS_NEED_A_NEW_TRY", {
                chaptersCount: failChaptersCount
              })}
            </p>
          </div>
        ) : null}
        {isVerifyError ? (
          <p className="Section-verifier-overlay__footertext Section-verifier-overlay__footertext--error">
            {t("VERIFICATION_FAILED_PLEASE_TRY_AGAIN")}
          </p>
        ) : null}
        {progress.verified && progress.success ? (
          <button
            type="button"
            className="Section-verifier-overlay__btn"
            onClick={close}
          >
            {t("PICK_NEW_SECTION")}
          </button>
        ) : progress.verified && !progress.success ? null : (
          <button
            disabled={isSubmitting}
            type="button"
            className="Section-verifier-overlay__btn"
            onClick={verify}
          >
            {t("VERIFY_X_CHAPTERS", {
              chaptersCount: chaptersCount - successAndVerifiedChaptersCount
            })}
            {isSubmitting ? <ButtonLoading /> : null}
          </button>
        )}
      </div>
      {progress.verified && !progress.success ? (
        <div className="Section-verifier-overlay__retry">
          <ChaptersRetryList section={section} />
        </div>
      ) : null}

      {isSubmitting ? <span>{t("VERIFYING")}...</span> : null}
    </div>
  );
}

const Comp = IS_SCORM
  ? withScorm()(SectionVerifierOverlay)
  : SectionVerifierOverlay;

export default Comp;
