import React from "react";

import stripHtml from "utils/stripHtml";

function CoreHeading(props) {
  const { block } = props;
  const { attributes } = block;
  const mainCls = "Fact-block--CoreHeading";
  const cls =
  `${mainCls} ${mainCls}--align${attributes.align ? attributes.align : 'none'}`;
  return (
    <div className={cls}>
      <h3>{stripHtml(block.originalContent)}</h3>
    </div>
  );
}

export default CoreHeading;
