import React from "react";
import { Link } from "react-router-dom";

import "./ParticipantSearchResult.scss";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import { IS_DEBUG } from "consts";

function ParticipantSearchResult(props) {
  const { name, email, pn, orgNo, courses } = props.result;

  const identifierForUrl = pn ? pn : email;

  return (
    <div className="Participant-search-result">
      <h2 className="Participant-search-result__name">{name}</h2>
      <div className="Participant-search-result__details">
        <p className="Participant-search-result__detail">{email}</p>
        <p className="Participant-search-result__detail">{pn}</p>
        <p className="Participant-search-result__detail">{orgNo}</p>
      </div>
      <div className="Participant-search-result__courses">
        {courses.map(course => {
          return (
            <Link
              to={{
                pathname: `/participantsearch/${identifierForUrl}/${course.courseId}`
              }}
              key={course.courseId}
              className="Participant-search-result__courselink"
            >
              {course.courseTitle
                ? course.courseTitle + (IS_DEBUG ? ` | ${course.courseId}` : "")
                : course.courseId}
              <BackIcon />
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default ParticipantSearchResult;
