import React from "react";

import FactBlock from "./FactBlock";

function BucLmsBuilderRichList(props) {
  const { block } = props;
  return (
    <ul className="Fact-block--BucLmsBuilderRichList">
      {block.innerBlocks.map((innerBlock, i) => {
        return (
          <FactBlock
            key={i}
            block={innerBlock}
            allowedBlocks={["BucLmsBuilderRichListItem"]}
          />
        );
      })}
    </ul>
  );
}

export default BucLmsBuilderRichList;
