import React from "react";

import "./TaskGradingOption.scss";

import SliderInput from "components/SliderInput/SliderInput";

import {
  MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE,
  MAX_STEP_GRADING_SLIDER_DEFAULT_VALUE
} from "consts";

function TaskGradingOption(props) {
  const { onChange, id, label, value } = props;
  const _onChange = e => {
    const val = Number(e.target.value);
    onChange(val, id);
  };

  return (
    <div className="Task-grading-option">
      <p className="Task-grading-option__label">{label}</p>
      <SliderInput
        min={MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE}
        max={MAX_STEP_GRADING_SLIDER_DEFAULT_VALUE}
        step={MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE}
        value={value}
        onChange={_onChange}
      />
    </div>
  );
}

export default TaskGradingOption;
