import React from "react";
import { useTranslation } from "react-i18next";

import "./LogoutBridge.scss";

function LogoutBridge() {
  const { t } = useTranslation();
  
  return (
    <div className="Login-bridge">
      <h1 className="Login-bridge__heading">{t("LOGOUT_TEXT")}</h1>
    </div>
  );
}

export default LogoutBridge;

