import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as IconLogout } from "images/icons/logout.svg";
import { ReactComponent as IconMenuExpanded } from "images/icons/menu-cross.svg";
import { ReactComponent as IconMenu } from "images/icons/menu.svg";
import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";
import { useTranslation } from "react-i18next";

import getUserManager from "userManager";
import UserMenu from "components/UserMenu/UserMenu";
import { setUserLogout } from "actions";


import "./UserHeader.scss";

function UserHeader() {
  
  const user = useSelector(getUserWithAccessStatus);
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const userName = `${user?.profile?.given_name} ${user?.profile?.family_name}`;
  
  const logout = () => {
    dispatch(setUserLogout(true));
    const userManager = getUserManager();
    userManager.signoutRedirect();
  };
  
  const userMenuItems = user?.accessStatus && [
    {
      key: "certificate",
      title: t("SHOW_CERTIFICATE"),
      isActive: user.accessStatus.hasActiveCertificate
    },
    {
      key: "course",
      title: t("REDO_THE_COURSE"),
      isActive: (!user.accessStatus.hasActiveCourse && user.accessStatus.hasActiveCertificate) || user.accessStatus.hasCompleteCourse || user.accessStatus.isPreview
    },
    {
      key: "language",
      title: t("USER_MENU_LANG_BTN_LABEL"),
      isActive: user.accessStatus.hasActiveCourse || (!user.accessStatus.hasActiveCourse && !user.accessStatus.hasActiveCertificate) || user.accessStatus.isPreview
    },
    {
      key: "support",
      title: t("SUPPORT"),
      isActive: true
    },
  ]

  return (
    <div className="User-header">
      <div className="User-header__name">{t("LOGGED_IN_AS")} <span>{userName}</span></div>
      <div className="User-header__navigation">
        <button className="User-header__navigation__menu-toggle" type="button" onClick={() => setIsExpanded(!isExpanded)}>
          {t("MENU")}
          {isExpanded ? (
            <IconMenuExpanded />
            ) :
            <IconMenu />
          }
        </button>
        <button className="User-header__navigation__logout" type="button" onClick={logout}>
          {t("LOGOUT")}
          <IconLogout />
        </button>
      </div>
      { isExpanded && (
        <UserMenu menuItems={userMenuItems} />
      )}
    </div>
  );
}

export default UserHeader;
