import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { DEFAULT_TRANSITION_MS, OVERLAY_OPEN_BODY_CLASSNAME } from "consts";

import { setCurrentSoundIdPlaying } from "actions";

import "./Overlay.scss";

function Overlay(props) {
  const { onExited, onOutsideClick, show, children, isSimple } = props;
  const dispatch = useDispatch();
  const el = useRef(null);
  const innerEl = useRef(null);
  const _onExited = onExited ? onExited : () => {};
  const _onOutsideClick = onOutsideClick ? onOutsideClick : () => {};

  // When overlay opens, pause any audio
  // that may be playing in components
  // underneath the Overlay
  useEffect(() => {
    if (show) {
      dispatch(setCurrentSoundIdPlaying(null));
    }
  }, [show, dispatch]);

  // Try to focus overlay when it shows up
  useEffect(() => {
    if (show && el.current) {
      el.current.focus();
      document.body.classList.add(OVERLAY_OPEN_BODY_CLASSNAME);
    } else {
      document.body.classList.remove(OVERLAY_OPEN_BODY_CLASSNAME);
    }
    return () => {
      document.body.classList.remove(OVERLAY_OPEN_BODY_CLASSNAME);
    };
  }, [show, el]);

  const cls = "Overlay" + (isSimple ? " Overlay--isSimple" : "");

  return (
    <CSSTransition
      classNames="overlayanim"
      timeout={DEFAULT_TRANSITION_MS}
      appear={true}
      in={show}
      unmountOnExit
      onExited={_onExited}
    >
      {/* TODO accessibility stuff, test that focus works etc */}
      <div
        className={cls}
        ref={el}
        onClick={e => {
          if (e.target === el.current || e.target === innerEl.current)
            _onOutsideClick();
        }}
      >
        <div ref={innerEl} className="Overlay__inner">
          <div data-innerinner className="Overlay__innerinner">
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

Overlay.propTypes = {
  show: PropTypes.bool.isRequired,
  onExited: PropTypes.func
};

export default Overlay;
