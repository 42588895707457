import { useSelector } from "react-redux";
import useCurrentSection from "hooks/useCurrentSection";
import getChapterStatus from "utils/getChapterStatus";

function useChapterProgress(chapter) {
  const courseState = useSelector(state => state.course.courseState);
  const section = useCurrentSection();

  if (!courseState || !chapter || !section.data) return null;

  const sectionState = courseState.sections.find(_section => {
    return _section.id === section.data.blbCourseSectionId;
  });

  if (!sectionState) return null;

  const chapterState = sectionState.chapters.find(_chapter => {
    return _chapter.id === chapter.blbChapterId;
  });

  if (!chapterState) return null;

  return { ...chapterState, status: getChapterStatus(chapterState) };
}

export default useChapterProgress;
