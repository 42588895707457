import React from "react";

import CarouselItem from "components/CarouselItem/CarouselItem";

function BucLmsBuilderSliderItem(props) {
  const { block } = props;
  return (
    <div className="Fact-block--BucLmsBuilderSliderItem">
      <CarouselItem
        innerBlocks={block.innerBlocks}
        image={block.attributes.image}
      />
    </div>
  );
}

export default BucLmsBuilderSliderItem;
