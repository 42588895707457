import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as IconLogout } from "images/icons/logout.svg";
import { ReactComponent as ByggforetagenLogo } from "images/logos/byggforetagen-full.svg";
import { ReactComponent as ToggleIcon } from "images/icons/chevron-down.svg";
import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";
import { useTranslation, Trans } from "react-i18next";
import { FASTTRACK_DIPLOMA_URL, SUPPORT_EMAIL } from "consts";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";
import getUserManager from "userManager";
import { deleteAccessStatusWithRestart } from "tracker-api/sessions";
import envConfig from "environment.json";
import { setUserLogout } from "actions";

import "./UserMenu.scss";

function UserMenu({menuItems = []}) {
  const { t } = useTranslation();
  const userInitialLanguage = useSelector(state => state.layout.currentLanguage);
  const availableLanguages = useSelector(state => state.layout.availableLanguages);

  const [toggleItem, setToggleItem] = useState('');
  const [currentLanguageKey, setCurrentLanguageKey] = useState(userInitialLanguage);
  const [openCofirmLanguage, setOpenConfirmLanguage] = useState(false);
  const eduadminId = useCurrentEduadminId();
  const user = useSelector(getUserWithAccessStatus);
  const dispatch = useDispatch();

  const handleMenuItemClick = (key) => {
    switch (key) {
      case "certificate":
        return showCertificate;
      case "course":
        return restartCourse;
      case "language":
        return () => onToggle(key);
      case "support":
        return () => onToggle(key);
      default:
        return;
    }
  };

  const logout = () => {
    dispatch(setUserLogout(true));
    const userManager = getUserManager();
    userManager.signoutRedirect();
  };

  const resetCourseStatus = useCallback(() => {
    async function _resetCourse() {
      const res = await deleteAccessStatusWithRestart(eduadminId, user);
      const slug = availableLanguages?.find(lang => lang.code === currentLanguageKey);
      if (res) {
        window.location.assign(`${envConfig.URL}/course/${slug.course_slug}`);
      }
    }
    _resetCourse()
  }, [eduadminId, user, availableLanguages, currentLanguageKey]);

  const showCertificate = () => {
    window.open(FASTTRACK_DIPLOMA_URL, "_blank");
  };
  const restartCourse = () => {
    resetCourseStatus();
  };

  const onToggle = (key) => {
    setOpenConfirmLanguage(false);
    if (toggleItem === key) {
      setToggleItem('');
    } else {
      setToggleItem(key);
    }
  };

  const handleLanguageClick = (key) => {
    if (currentLanguageKey === key) setToggleItem('');
    setOpenConfirmLanguage(true);
    setCurrentLanguageKey(key);
  };

  const onCancelLanguage = () => {
    setOpenConfirmLanguage(false);
    setCurrentLanguageKey(userInitialLanguage);
  };

  const onConfirmLanguage = () => {
    setOpenConfirmLanguage(false);
    resetCourseStatus();
  };

  const getLanguageName = (code) => {
    const languageCode = code.toUpperCase();
    return t(languageCode).toUpperCase();
  };

  const menuItemExpanded = (key) => {
    if (key === "language") {
      return (
        <div className="User-menu__expanded">
          { openCofirmLanguage ? (
            <div className="User-menu__content">
              <div className="User-menu__confirm">
                <p>{t("CONFIRM_LANGUAGE_SUBMIT_TEXT")}</p>
                <div className="User-menu__confirm__button-row">
                  <button type="button" className="User-menu__confirm__cancel-button" onClick={onCancelLanguage}>
                    {t("CANCEL")}
                  </button>
                  <button type="button" className="User-menu__confirm__confirm-button" onClick={onConfirmLanguage}>
                    {t("CHANGE_LANGUAGE")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <ul className="User-menu__content">
              {availableLanguages.map(language => {
                const languageCls = "User-menu__language" +
                (currentLanguageKey === language.code ? " User-menu__language--selected" : "");
                return (
                  <li key={language.code} className="User-menu__item">
                    <button type="button" className={languageCls} onClick={() => handleLanguageClick(language.code)}>
                      {getLanguageName(language.code)}
                    </button>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )
    } else if (key === "support") {
      const support = SUPPORT_EMAIL;
      return (
        <div className="User-menu__expanded">
          <div className="User-menu__support">
            <Trans
              i18nKey="CONTACT_SUPPORT"
              support={support}
            >
              <a
                href={`mailto:${support}`}
                className="User-menu__support__link"
                >{{support}}
              </a>
            </Trans>
          </div>
        </div>
      )
    }
    return;
  }

  const toggleCls = (key) => {
    return (
      "User-menu__list__toggle-icon" +
      (toggleItem === key ? " User-menu__list__toggle-icon--toggled" : "")
    );
  }

  const listItemCls = (key) => {
    return (
      "User-menu__list__item" +
      (toggleItem && toggleItem !== key ? " User-menu__list__item--inactive" : "")
    );
  }
    
  return (
    <div className="User-menu">
      <ul className="User-menu__list">
        { menuItems && menuItems.map(item => (
          item.isActive && (
            <li key={item.key} className={listItemCls(item.key)}>
              <button className="User-menu__list__item-btn" onClick={handleMenuItemClick(item.key)}>
                {item.title}
                {(item.key === "language" || item.key === "support") && (
                  <div className={toggleCls(item.key)}>
                    <ToggleIcon />
                  </div>
                )}
              </button>
              {toggleItem === item.key && menuItemExpanded(item.key)}
            </li>
          )
        ))}
      </ul>
      <div className="User-menu__logout">
        <button className="User-menu__logout-btn" type="button" disabled={toggleItem !== ''} onClick={logout}>
          {t("LOGOUT")}
          <div className="User-menu__logout-btn-icon">
            <IconLogout />
          </div>
        </button>
        <ByggforetagenLogo />
      </div>
    </div>
  )
}

export default UserMenu;
