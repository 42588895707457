import { useSelector } from "react-redux";

import useCurrentCourse from "hooks/useCurrentCourse";
import useSectionProgress from "hooks/useSectionProgress";
import _ from "lodash";
import { GQL_SECTIONS_NEST } from "consts";

function useCurrentSection() {
  const selectedSection = useSelector(state => state.course.selectedSection);
  const sectionToVerify = useSelector(state => state.course.sectionToVerify);

  // Verifying is a different flow where section is not always selected.
  const sectionIdToLookFor = sectionToVerify ? sectionToVerify : selectedSection;

  const { loading, error, data } = useCurrentCourse();

  const allSections = data ? _.get(data, GQL_SECTIONS_NEST) : null;

  const section = allSections
    ? _.find(allSections, section => {
        return section.blbCourseSectionId === sectionIdToLookFor;
      })
    : null;

  const progress = useSectionProgress(section);

  return {
    loading,
    error,
    data: section && progress ? { ...section, progress } : null
  };
}

export default useCurrentSection;
