import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import useNextChapter from "hooks/useNextChapter";
import {
  setSelectedChapter,
  setSelectedSection,
  closeChapterCompletedOverlay
} from "actions";

import Error from "components/Error/Error";

import debugLog from "utils/debugLog";

function ChapterRedirect(props) {
  const { forceSectionSelect } = props;
  const dispatch = useDispatch();
  const [didChapterRedirect, setDidChapterRedirect] = useState(false);
  const { loading, error, data } = useNextChapter();

  useEffect(() => {
    if (loading || didChapterRedirect || error) return;
    dispatch(closeChapterCompletedOverlay());

    if (data && data.blbChapterId && !forceSectionSelect) {
      // Make sure it's only done only done
      // once if this component doesn't unmount
      // due to transitions etc
      debugLog("Redirecting to chapter", data.blbChapterId);
      setDidChapterRedirect(true);
      dispatch(setSelectedChapter(data.blbChapterId));
    } else {
      // If there is no next chapter,
      // send the user back to section
      // selection screen
      debugLog("Redirecting back to section select");
      dispatch(setSelectedSection(null));
    }
  }, [
    dispatch,
    setDidChapterRedirect,
    loading,
    didChapterRedirect,
    data,
    error,
    forceSectionSelect
  ]);

  if (error) return <Error error={error} />;

  return null;
}

export default ChapterRedirect;
