import React from "react";
import { useTranslation } from "react-i18next";
import { ENTITY_STATUSES } from "consts";

import useCurrentCourse from "hooks/useCurrentCourse";

import "./CourseSectionProgressText.scss";

function CourseSectionProgressText(props) {
  const { t } = useTranslation();
  const { data } = useCurrentCourse();
  const { completedChaptersCount, chaptersCount, status } = props;

  if (!data || (data && !data.course)) return null;
  const cls =
    "Course-section-progress-text" +
    ` Course-section-progress-text--status${status}`;

  if (status === ENTITY_STATUSES.VERIFIED_SUCCESS) {
    return (
      <div className={cls}>
        <p>
          <span className="Bold">{t("DONE")}!</span>{" "}
          <span>
            {t("ALL_X_CHAPTERS_ARE_VERIFIED_CONTINUE_WITH_REST_OF_Y", {
              chapterCount: chaptersCount,
              courseTitle: data.course.titleRendered,
            })}
          </span>
        </p>
      </div>
    );
  }
  return (
    <div className={cls}>
      <p className="Course-section-progress-text__completed-chapters-count">
        {t("X_CHAPTERS_ARE_ANSWERED", { completedChaptersCount })}
      </p>
      <p className="Course-section-progress-text__chapters-count">
        {status === ENTITY_STATUSES.VERIFIED_FAILED
          ? t("X_CHAPTERS_NEED_A_NEW_TRY", { chaptersCount })
          : t("X_CHAPTERS_LEFT", { chaptersCount })}
      </p>
    </div>
  );
}

export default CourseSectionProgressText;
