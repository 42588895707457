import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./fonts/CircularXXWeb-Black.woff";
import "./fonts/CircularXXWeb-Book.woff";
import "./fonts/CircularXXWeb-BookItalic.woff";
import "./fonts/CircularXXWeb-ExtraBlack.woff";
import "./fonts/CircularXXWeb-Medium.woff";

import "./index.scss"; // Global styles
import "./i18n"; // Translations
import App from "./App";

import debugLog from "utils/debugLog";

import { IS_BYPASS_SSO, IS_SCORM } from "consts";

const Wrapper = () => {
  const [didThirdPartyCookieCheck, setDidThirdPartyCookieCheck] = useState(
    false
  );

  useEffect(() => {
    // Handler for message from iframe
    const receive3rdPartyCookieMessage = evt => {
      if (evt.data === "3PCunsupported") {
        debugLog("3rd party cookies are not supported");
        setDidThirdPartyCookieCheck(true);
      } else if (evt.data === "3PCsupported") {
        debugLog("3rd party cookies are supported");
        window.thirdPartyCookiesAreSupported = true;
        setDidThirdPartyCookieCheck(true);
      }
    };

    window.addEventListener("message", receive3rdPartyCookieMessage, false);

    if (!IS_BYPASS_SSO && !IS_SCORM) {
      // Have a frame try to set a third party cookie,
      // then redirect within frame to 2nd page that
      // checks for the cookie and sends message back here.
      const iframe = document.createElement("iframe");
      iframe.style = "width:1px;height:1px;position:absolute;left:-999em";
      document.body.appendChild(iframe);

      // TODO have the builder or tracker serve this instead
      iframe.src = "https://andreasbohman.github.io/thirdpartycookietest.html";
    } else {
      debugLog("Not doing 3rd party cookie check");
      setDidThirdPartyCookieCheck(true);
    }

    return () =>
      window.removeEventListener("message", receive3rdPartyCookieMessage);
  }, []);

  // If there was no message from iframe after 2 sec,
  // just move on.
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!didThirdPartyCookieCheck) {
        debugLog("Couldn't resolve 3rd party cookie status, continuing");
        setDidThirdPartyCookieCheck(true);
      }
    }, 1500);

    return () => clearTimeout(timeout);
  }, [didThirdPartyCookieCheck]);

  if (!didThirdPartyCookieCheck) return null;

  return <App />;
};

ReactDOM.render(<Wrapper />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

//
//
//
//
//
