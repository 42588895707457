import _ from "lodash";
import { TASK_OPTION_TYPES } from "consts";
import getTaskType from "utils/getTaskType";
import {
  MIN_SLIDER_DEFAULT_VALUE,
  MAX_SLIDER_DEFAULT_VALUE,
  MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE,
  MAX_STEP_GRADING_SLIDER_DEFAULT_VALUE
} from "consts";

import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";

// TODO would be great to have unit tests for these

const getKeys = type => {
  const arrayKey = `blbTaskOptionType${type}Options`;
  const idKey = `blbTaskType${type}OptionId`;
  const labelKey = `blbTaskType${type}OptionLabel`;
  const correctKey = `blbTaskType${type}OptionCorrect`;
  return {
    arrayKey,
    idKey,
    labelKey,
    correctKey
  };
};

const getSliderTaskResults = (task, selectedValues) => {
  if (
    !selectedValues[0] ||
    (selectedValues[0] && isNaN(selectedValues[0].extraData))
  )
    return { options: [] }; // This shouldn't happen if callees work as they should

  const min = MIN_SLIDER_DEFAULT_VALUE;
  const max = MAX_SLIDER_DEFAULT_VALUE;
  const grade = parseInt(selectedValues[0].extraData);

  const { arrayKey, idKey, labelKey } = getKeys(TASK_OPTION_TYPES.SLIDER);

  const option = task.blbGroupTaskOptions[arrayKey][0];
  const id = option[idKey];
  const content = option[labelKey];
  const answer = { min, max, grade };
  const correct = true; // Slider options always correct

  return {
    options: [{ id, content, answer, correct }]
  };
};

const getOrderTaskResults = (task, selectedValues) => {
  const { arrayKey, idKey, labelKey } = getKeys(TASK_OPTION_TYPES.ORDER);

  const options = task.blbGroupTaskOptions[arrayKey].map(option => {
    const id = option[idKey];
    const content = option[labelKey];

    // Indexes start at 1 in this context
    const answeredArrayIndex = _.findIndex(selectedValues, selectedValue => {
      return selectedValue.optionId === id;
    });
    const answeredIndex =
      answeredArrayIndex === -1 ? null : answeredArrayIndex + 1;

    const correctIndex = parseInt(option.blbTaskTypeOrderOptionCorrectPosition);

    const answer = {
      correctIndex,
      index: answeredIndex
    };

    // Yes, correct is a little weird here... All options
    // can be seen as correct, it's their order that's relevant..
    // Note that this is not used to tell the tracker if user answered
    // correctly, it's just metadata about the option.
    const correct = true;

    return {
      id,
      content,
      answer,
      correct
    };
  });

  return { options };
};
const getMatchTaskResults = (task, selectedValues) => {
  
  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);
  const { arrayKey, idKey, labelKey } = getKeys(TASK_OPTION_TYPES.MATCH);

  const taskOptions = task.blbGroupTaskOptions[arrayKey];
  const options = taskOptions.map((option, i) => {
    const id = option[idKey];
    const content = option[labelKey];
    const answer = { match: selectedValuesIds[i], correctMatch: option.blbTaskTypeMatchOptionCorrectMatchoptionId };
    const correct = selectedValuesIds[i] === option.blbTaskTypeMatchOptionCorrectMatchoptionId;
       
    return {
      id,
      content,
      answer,
      correct
    };
  });
  const matches = selectedValues.map(selectedValue => {
    return {
      id: selectedValue.optionId,
      content: selectedValue.optionLabel
    }
  })
  return { options, matches };
};
const getImageOrTextTaskResults = (task, selectedValues, steps, isImage) => {
  const type = isImage ? TASK_OPTION_TYPES.IMAGE : TASK_OPTION_TYPES.TEXT;

  const { arrayKey, idKey, labelKey, correctKey } = getKeys(type);

  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);

  const taskOptions = task.blbGroupTaskOptions[arrayKey];

  const options = taskOptions.map(option => {
    const id = option[idKey];
    const content = option[labelKey];
    const answer = { selected: selectedValuesIds.includes(id) };
    const correct = !!option[correctKey];

    return {
      id,
      content,
      answer,
      correct
    };
  });

  // Possible TODO in future - a limitation here is that
  // no matter what step, the originally selected
  // options in the main task is assumed to be
  // the available options of the step. As of now,
  // only 1 sequent step is allowed in WP anyways.
  const inheritedOption = options.filter(option => {
    return option.answer.selected;
  });

  // steps is in array of arrays with selected values for each step
  const sequentSteps = steps.map(obj => {
    const { stepTitle, selectedStepValues } = obj;

    return {
      title: stepTitle,
      options: inheritedOption.map(option => {
        const { id, content, correct } = option;

        const selectedValue = _.find(selectedStepValues, _selectedValue => {
          return _selectedValue.optionId === id;
        });

        // Extradata from sequent steps will always refer
        // to a grade from a TaskGradingOption.
        //
        // 0 is hypothetically an allowed extraData value
        const answer =
          selectedValue &&
          (selectedValue.extraData || selectedValue.extraData === 0)
            ? {
                grade: selectedValue.extraData,
                min: MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE,
                max: MAX_STEP_GRADING_SLIDER_DEFAULT_VALUE
              }
            : { selected: !!selectedValue };

        return {
          id,
          content,
          answer,
          correct
        };
      })
    };
  });

  return sequentSteps.length ? { options, sequentSteps } : { options };
};

const getTaskResults = (task, selectedValues, steps) => {
  let r = {};
  if (getTaskType(task) === TASK_OPTION_TYPES.TEXT) {
    r = getImageOrTextTaskResults(task, selectedValues, steps, false);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.SLIDER) {
    r = getSliderTaskResults(task, selectedValues);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.IMAGE) {
    r = getImageOrTextTaskResults(task, selectedValues, steps, true);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.ORDER) {
    r = getOrderTaskResults(task, selectedValues);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.MATCH) {
    r = getMatchTaskResults(task, selectedValues);
  }
  return r;
};

export default getTaskResults;
