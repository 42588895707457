export const OBJECTS = {
  COURSE: "http://buc.se/xapi/object/course",
  SECTION: "http://buc.se/xapi/object/section",
  CHAPTER: "http://buc.se/xapi/object/chapter",
  TASK: "http://buc.se/xapi/object/task"
};

export const VERBS = {
  INITIALIZED: "http://buc.se/xapi/verb/initialized",
  STARTED: "http://buc.se/xapi/verb/started",
  ANSWERED: "http://buc.se/xapi/verb/answered",
  RESTARTED: "http://buc.se/xapi/verb/restarted",
  VERIFIED: "http://buc.se/xapi/verb/verified",
  COMPLETED: "http://buc.se/xapi/verb/completed"
};

export const EXTENSIONS = {
  COURSESTRUCTURE: "http://buc.se/xapi/extensions/coursestructure"
};
