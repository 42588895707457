import React from "react";
import { useRef } from "react";
import { useDrop } from "react-dnd";

function TaskMatchOptionDroptarget({ moveCard, targetIndex }) {
  const refDrop = useRef(null);
  const ItemTypes = {
    CARD: "card"
  };

  // drop
  const [{ hovered }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        hovered: monitor.isOver()
      };
    },

    drop(item, monitor) {
      // runs when card is dropped
      if (!refDrop.current) {
        return;
      }

      // current index of the dragging card
      const originalIndex = item.index;

      moveCard(originalIndex, targetIndex, item);
    }
  });

  const cardCls = `
    Task-interaction-match-option-card__slot
   
    ${hovered ? "Task-interaction-match-option-card__slot--hovered" : ""}

  `;

  drop(refDrop);
  return (
    <div ref={refDrop} className={cardCls}>
      <span className="Task-match-option-card__text">Dra svaret hit</span>
    </div>
  );
}

export default TaskMatchOptionDroptarget;
