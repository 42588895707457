import { ENTITY_STATUSES } from "consts";

function getSectionStatus(object) {
  if (object.completed && object.verified && object.success) return ENTITY_STATUSES.VERIFIED_SUCCESS;
  if (object.completed && object.verified && !object.success) return ENTITY_STATUSES.VERIFIED_FAILED;
  if (object.completed) return ENTITY_STATUSES.COMPLETE;
  if (!object.completed && object.completedChaptersCount > 0) return ENTITY_STATUSES.INCOMPLETE;
  return ENTITY_STATUSES.UNSTARTED;
}

export default getSectionStatus;
