import React from "react";

function CoreImage(props) {
  const { block } = props;
  const { attributes } = block;

  const isSvg = attributes.url.endsWith('.svg');
  const mainCls = "Fact-block--CoreImage";
  const cls =
    `${mainCls} ${mainCls}--align-${attributes.align ? attributes.align : 'left'}` + 
    (isSvg ? ` ${mainCls}--isSvg` : "");

  return (
    <div
      className={cls}
      dangerouslySetInnerHTML={{ __html: block.originalContent }}
    />
  );
}

export default CoreImage;
