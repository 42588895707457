import React from "react";
import { useTranslation } from "react-i18next";

import "./MainLoading.scss";

function MainLoading(props) {
  const { t } = useTranslation();

  return (
    <div className="Main-loading">
      <div className="Main-loading__inner">
        <div className="book">
          <div className="innerbook">
            <div className="left"></div>
            <div className="middle"></div>
            <div className="right"></div>
          </div>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

      <p className="Main-loading__text">{t("PREPPING_THE_COURSE")}</p>
    </div>
  );
}

export default MainLoading;
