import React from "react";

import "./TaskInteractionText.scss";

import TaskOption from "components/TaskOption/TaskOption";

import { IS_DEBUG } from "consts";

import isReflectiveTask from "utils/isReflectiveTask";
import taskHasMultipleRightAnswers from "utils/taskHasMultipleRightAnswers";
import getTaskSelectFn from "utils/getTaskSelectFn";
import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";

function TaskInteractionText(props) {
  const { taskData, isInFacts, showAnswer, isDisabled } = props;
  const options = taskData.blbGroupTaskOptions.blbTaskOptionTypeTextOptions;

  // If it's a reflective task, or if multiple right answers exists,
  // we assume it's a multichoice task.
  const isMultiAnswerTask =
    isReflectiveTask(taskData) || taskHasMultipleRightAnswers(taskData);

  const allowDeselectOfSingleAnswer = isInFacts;
  const selectFn = getTaskSelectFn(
    props.selectedValues,
    props.selectFn,
    allowDeselectOfSingleAnswer,
    isMultiAnswerTask
  );

  const selectedValuesIds = getTaskSelectedValuesIds(props.selectedValues);

  return (
    <div className="Task-interaction-text">
      <ul>
        {options.map((option, i) => {
          const isLast = i === options.length - 1;
          const value = option.blbTaskTypeTextOptionId;
          const label = option.blbTaskTypeTextOptionLabel;
          const isCorrect = !!option.blbTaskTypeTextOptionCorrect;
          const isSelected = selectedValuesIds.includes(value);

          return (
            <li key={value + i} className="Task-interaction-text__item">
              <TaskOption
                value={value}
                label={label}
                selectFn={selectFn}
                allowDeselectOfSingleAnswer={allowDeselectOfSingleAnswer}
                isSelected={isSelected}
                isMultiple={isMultiAnswerTask}
                dc={isCorrect && IS_DEBUG}
                isCorrect={isCorrect}
                showAnswer={showAnswer}
                isDisabled={isDisabled}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TaskInteractionText;
