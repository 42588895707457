import React from "react";
import { useTranslation } from "react-i18next";

import "./Preface.scss";

import useCurrentCourse from "hooks/useCurrentCourse";

import VimeoVideo from "components/VimeoVideo/VimeoVideo";

function Preface() {
  const { t } = useTranslation();
  const { data } = useCurrentCourse();

  if (!data || (data && !data.course)) return null;

  const sectionsCount =
    data.course.blbGroupCourseSections.blbCourseSections.length;

  const introMediaType =
    data.course.blbGroupCourseIntroduction.blbCourseIntroductionMediaType;
  const vimeoUrl =
    data.course.blbGroupCourseIntroduction.blbCourseIntroductionVideo;
  const heading =
    data.course.blbGroupCourseIntroduction.blbCourseIntroductionTitle;
  const image =
    data.course.blbGroupCourseIntroduction.blbCourseIntroductionImage;
  const text = data.course.blbGroupCourseIntroduction.blbCourseIntroductionText;

  return (
    <div className="Preface">
      {vimeoUrl && introMediaType === "video" ? (
        <div className="Preface__video">
          <VimeoVideo vimeoUrl={vimeoUrl} />
        </div>
      ) : null}
      {image && introMediaType === "image" ? (
        <div className="Preface__image">
          <img src={image.sourceUrl} alt={image.altText} />
        </div>
      ) : null}

      <div className="Preface__inner">
        {heading ? <h1 className="Preface__heading">{heading}</h1> : null}
        {text ? (
          <div
            className="Preface__text"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        ) : (
          <>
            <h2 className="Preface__subheading">
              {t("THIS_IS_HOW_IT_WORKS")}:
            </h2>
            <ol className="Preface__list">
              <li className="Preface__listitem">
                {t("PREFACE_LIST_ITEM_1_TEXT", { sectionsCount })}
              </li>
              <li className="Preface__listitem">
                {t("PREFACE_LIST_ITEM_2_TEXT")}
              </li>
              <li className="Preface__listitem">
                {t("PREFACE_LIST_ITEM_3_TEXT")}
              </li>
            </ol>
            <h2 className="Preface__subheading">{t("HOW_DO_I_PASS")}</h2>
            <p className="Preface__paragraph">
              {t("PREFACE_PARAGRAPH_1_TEXT")}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Preface;
