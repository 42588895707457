import React from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import "./TaskMatchOptionCard.scss";

function TaskMatchOptionCard({
  id,
  text,
  index,
  moveCard,
  puzzled,
  correct,
  showAnswer,
  origin
}) {
  const ref = useRef(null);
  const ItemTypes = {
    CARD: "card"
  };

  // drop
  const [{ highlighted, hovered, handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        hovered: monitor.isOver(),
        highlighted: monitor.canDrop()
      };
    },

    // runs when card is dropped
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }

      // current index of the dragging card
      const originalIndex = item.index;

      // target index of where card has been dropped
      const targetIndex = index;
      moveCard(originalIndex, targetIndex, item);
    }
  });

  // drag
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, origin };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const isActive = highlighted && hovered;

  const cardCls = `
    Task-interaction-match-option-card
    ${puzzled ? "Task-interaction-match-option-card--puzzled" : ""}
    ${isDragging ? "Task-interaction-match-option-card--dragging" : ""}
    ${isActive ? "Task-interaction-match-option-card--active" : ""}
    ${highlighted ? "Task-interaction-match-option-card--drop" : ""}
    ${hovered ? "Task-interaction-match-option-card--hovered" : ""}
    ${
      showAnswer
        ? correct
          ? "Task-interaction-match-option-card--correct"
          : "Task-interaction-match-option-card--incorrect"
        : ""
    }
  `;

  if (origin === "option") {
    drag(ref);
  } else {
    drag(drop(ref));
  }
  return (
    <div ref={ref} data-handler-id={handlerId} className={cardCls}>
      <span className="Task-match-option-card__text">{text}</span>
    </div>
  );
}

export default TaskMatchOptionCard;
