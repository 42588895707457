import Rollbar from "rollbar";
import rollbarConfig from "rollbarConfig";
import getUserConfigForRollbar from "utils/getUserConfigForRollbar";
import debugLog from "utils/debugLog";

// Custom rollbar event for logging
// various errors that arise in communication
// with the Tracker API.
export async function sendTrackerResponseRollbar(response, reqInput, heading, userManager) {
  let errorText;
  try {
    errorText = await response.text();
  } catch (e) {
    //
  }

  const meta = {
    url: response.url,
    status: response.status,
    errorText,
    reqInput
  };

  sendRollbar(heading, meta, userManager);
}


async function sendRollbar(heading, meta, userManager) {
  const rollbar = new Rollbar(rollbarConfig);

  let user;
  try {
    user = await userManager.getUser();
  } catch (e) {
    //
  }

  // TODO would be nice to re-use the rollbar
  // instance from index.js here... Question
  // is where to put it
  rollbar.configure(getUserConfigForRollbar(user));

  debugLog(heading, meta);
  
  rollbar.error(heading, meta);
}

export default sendRollbar;
