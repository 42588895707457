import _ from "lodash";

function getChapterByIdFromSection(chapterId, section) {
  // Find what the index is of the chapter we
  // want by url param
  const chapterIndex = section
    ? _.findIndex(section.blbCourseSectionChapterPosts, (chapter) => {
        return chapter.blbChapterId + "" === chapterId + "";
      })
    : -1;

  // Get the chapter data by index
  const chapter =
    chapterIndex !== -1
      ? section.blbCourseSectionChapterPosts[chapterIndex]
      : null;

  return {
    chapter,
    chapterIndex,
  };
}

export default getChapterByIdFromSection;
