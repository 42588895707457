import React from "react";
import { useTranslation } from "react-i18next";

import "./TaskSliderResult.scss";

import debugLog from "utils/debugLog";

function TaskSliderResult(props) {
  const { taskProgress, isEven } = props;
  const { t } = useTranslation();

  debugLog("taskProgress", taskProgress);

  const cls =
    "Task-slider-result" +
    (isEven ? " Task-slider-result--isEven" : " Task-slider-result--isOdd") +
    (taskProgress.success
      ? " Task-slider-result--isSuccess"
      : " Task-slider-result--isFail");

  if (!taskProgress.options.length) return null;

  const option = taskProgress.options[0];

  const percent =
    (option.answer.grade / (option.answer.max - option.answer.min)) * 100;

  // not so important TODO, maybe the title
  // should be returned by the tracker if it
  // changes in WP?

  return (
    <div className={cls}>
      <h3 className="Task-slider-result__title">
        #{" "}
        {taskProgress.title}
      </h3>
      <h4 className="Task-slider-result__subheading">{t("YOUR_ANSWER")}:</h4>
      {/* TODO would be nice to have the min/max labels as well */}
      <div className="Task-slider-result__gauge">
        <div
          className="Task-slider-result__indicator"
          style={{ left: percent + "%" }}
          aria-label={percent + "%"}
        ></div>
      </div>
    </div>
  );
}

export default TaskSliderResult;
