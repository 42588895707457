import React from "react";
import { useTranslation } from "react-i18next";

import "./PancakeLoader.scss";

function PancakeLoader(props) {
  const { t } = useTranslation();

  return (
      <div className="Pancake-loader">
      <div className="Pancake-loader__cooking">
        
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
      
        <div className="Pancake-loader__area">
            <div className="Pancake-loader__sides">
              <div className="Pancake-loader__pan"></div>
              <div className="Pancake-loader__handle"></div>
            </div>
          <div className="Pancake-loader__pancake">
            <div className="Pancake-loader__pastry"></div>
          </div>
        </div>
      </div>
      <div className="Pancake-loader__text">
        <p>{t("DIPLOMA_LOADER_TEXT")}</p>
      </div>
    </div>
  );
}

export default PancakeLoader;
