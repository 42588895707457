import { ENTITY_STATUSES } from "consts";

function getChapterStatus(object) {
  if (object.completed && object.verified && object.success) return ENTITY_STATUSES.VERIFIED_SUCCESS;
  if (object.completed && object.verified && !object.success) return ENTITY_STATUSES.VERIFIED_FAILED;
  if (object.completed) return ENTITY_STATUSES.COMPLETE;

  const completedTasksInChapter = object.taskGroups.filter(_task => {
    return _task.completed;
  });
  if (!object.completed && completedTasksInChapter.length > 0) return ENTITY_STATUSES.INCOMPLETE;
  return ENTITY_STATUSES.UNSTARTED;
}

export default getChapterStatus;
