import React from "react";

import "./ButtonLoading.scss";

import Loading from "components/Loading/Loading";

function ButtonLoading() {
  return (
    <div className="Button-loading">
      <Loading size={0.5} />
    </div>
  );
}

export default ButtonLoading;
