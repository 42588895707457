import { IS_BYPASS_SSO } from "consts";

const MOCK_USR = {
  name: "Fisk Fisksson",
  eduAdminParticipantId: "1",
  account: {
    ssoId: "123"
  }
};

function getTrackerActorFromUser(user) {
  if (IS_BYPASS_SSO) return MOCK_USR;
  if (!user) return {};
  return {
    name: user.profile.name,
    account: {
      ssoId: user.profile.sub
    }
  };
}

export default getTrackerActorFromUser;
