import React from "react";
import _ from "lodash";

import "./TaskInitialContent.scss";

function TaskInitialContent(props) {
  const { taskData } = props;

  // blbTaskOptionInitialOption is an array but
  // is only allowed 1 position
  const initialContent = !_.isEmpty(
    taskData.blbGroupTaskOptions.blbTaskOptionInitialOption
  )
    ? taskData.blbGroupTaskOptions.blbTaskOptionInitialOption[0]
    : null;
  if (!initialContent) return null;

  return (
    <div className="Task-initial-content">
      {initialContent.blbImage ? (
        <div className="Task-initial-content__image">
          <img
            src={initialContent.blbImage.sourceUrl}
            alt={initialContent.blbImage.altText}
          />
        </div>
      ) : null}
      {initialContent.blbRichText ? (
        <div
          className="Task-initial-content__rich-text"
          dangerouslySetInnerHTML={{ __html: initialContent.blbRichText }}
        />
      ) : null}
    </div>
  );
}

export default TaskInitialContent;
