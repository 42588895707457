import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./CourseSectionChapterNav.scss";

import useWindowSize from "hooks/useWindowSize";
import useCurrentSection from "hooks/useCurrentSection";

import CourseHeader from "components/CourseHeader/CourseHeader";
import CourseSectionChapterNavItem from "components/CourseSectionChapterNavItem/CourseSectionChapterNavItem";
import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";

import { OVERLAY_OPEN_BODY_CLASSNAME } from "consts";

function CourseSectionChapterNav() {
  const chapterNavExpanded = useSelector(
    state => state.layout.chapterNavExpanded
  );
  const selectedChapter = useSelector(state => state.course.selectedChapter);

  const { loading, error, data } = useCurrentSection();

  const windowSize = useWindowSize();

  const _chapterNavShouldBeExpanded =
    chapterNavExpanded || windowSize.isDesktop;

  useEffect(() => {
    if (chapterNavExpanded && (windowSize.isMobile || windowSize.isTablet)) {
      document.body.classList.add(OVERLAY_OPEN_BODY_CLASSNAME);
    } else {
      document.body.classList.remove(OVERLAY_OPEN_BODY_CLASSNAME);
    }
    return () => {
      document.body.classList.remove(OVERLAY_OPEN_BODY_CLASSNAME);
    };
  }, [chapterNavExpanded, windowSize]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const chapters = data.blbCourseSectionChapterPosts;

  const cls =
    "Course-section-chapter-nav" +
    (_chapterNavShouldBeExpanded
      ? " Course-section-chapter-nav--expanded"
      : " Course-section-chapter-nav--collapsed");

  return (
    <nav
      className={cls}
      aria-hidden={!_chapterNavShouldBeExpanded}
      id="CourseSectionChapterNav"
    >
      <CourseHeader isInNav={true} />
      <ul>
        {chapters.map((chapter, i) => {
          const isActive = selectedChapter === chapter.blbChapterId;

          return (
            <CourseSectionChapterNavItem
              key={chapter.blbChapterId}
              isActive={isActive}
              chapter={chapter}
              chapterNumber={i + 1}
            />
          );
        })}
      </ul>
    </nav>
  );
}

export default CourseSectionChapterNav;
