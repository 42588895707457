import { useSelector } from "react-redux";
import getCourseStatus from "utils/getCourseStatus";

function useCourseProgress() {
  const courseState = useSelector((state) => state.course.courseState);

  if (!courseState)
    return null;

  const hasIncomplete = courseState.sections.find((_section) => {
    return !_section.completed;
  });

  const hasFail = courseState.sections.find((_section) => {
    return !_section.success;
  });

  const hasUnverified = courseState.sections.find((_section) => {
    return !_section.verified;
  });

  const properties = {
    completed: !hasIncomplete,
    success: !hasFail,
    verified: !hasUnverified,
  };

  return {
    ...properties,
    status: getCourseStatus(properties),
  };
}

export default useCourseProgress;
