import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./FactsSection.scss";

import { BLOCK_GROUP_NAME } from "consts";

import useWindowSize from "hooks/useWindowSize";

import FactBlock from "components/FactBlock/FactBlock";
import SoundPlayer from "components/SoundPlayer/SoundPlayer";

// Maybe TODO - this could be refactored
// to be part of FactBlock,
// it was just made earlier before the concept
// expanded to be more flexible.
function FactsSection(props) {
  const { t } = useTranslation();
  const { block, isLast, isFirst, largeOnSmallerScreen } = props;
  const { isDesktop } = useWindowSize();
  const [isExpanded, setIsExpanded] = useState(isDesktop);

  if (block.name !== BLOCK_GROUP_NAME) return null;

  const cls =
    "Facts-section" +
    (isExpanded
      ? " Facts-section--isExpanded"
      : " Facts-section--isCollapsed") +
    (isLast ? " Facts-section--isLast" : "") +
    (isFirst ? " Facts-section--isFirst" : "") +
    (largeOnSmallerScreen ? " Facts-section--largeOnSmallerScreen" : "");

  // TODO accessability
  return (
    <section className={cls}>
      <header
        onClick={() => setIsExpanded(!isExpanded)}
        className="Facts-section__header"
      >
        <h1 className="Facts-section__heading">{block.attributes.title}</h1>
        <button
          type="button"
          className="Facts-section__toggler"
          aria-label={isExpanded ? t("COLLAPSE_SECTION") : t("EXPAND_SECTION")}
        >
          {" "}
        </button>
      </header>
      {isExpanded ? (
        <div className="Facts-section__innerblocks">
          {block.attributes.audioUrl ? (
            <div className="Facts-section__listen">
              <SoundPlayer sourceUrl={block.attributes.audioUrl} />
            </div>
          ) : null}
          {block.innerBlocks.map((innerBlock, i) => {
            return <FactBlock key={i} block={innerBlock} />;
          })}
        </div>
      ) : null}
    </section>
  );
}

export default FactsSection;
