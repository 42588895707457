import _ from "lodash";

import envConfig from "environment.json";

import fetchErrorHandler from "utils/fetchErrorHandler";

import getTrackerActorFromUser from "utils/getTrackerActorFromUser";
import getTrackerReqHeaders from "utils/getTrackerReqHeaders";

export const searchParticipantByEmail = async (email, user) => {
  const url = envConfig.TRACKER_REST_API_URI + "/analytics/searchuser";

  const actor = getTrackerActorFromUser(user);

  if (_.isEmpty(actor)) throw Error("No user");

  const res = await fetch(`${url}?email=${email}`, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res =>
      fetchErrorHandler(_res, { email }, "analytics/searchParticipantByEmail")
    )
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json.users;
    })
    .catch(err => {
      console.error(err);
      if (err && err.name === "ExpectedHttpError") {
        throw err;
      }
    });
  return res;
};

export const searchParticipantByPn = async (pn, user) => {
  const url = envConfig.TRACKER_REST_API_URI + "/analytics/searchuser";

  const actor = getTrackerActorFromUser(user);

  if (_.isEmpty(actor)) throw Error("No user");

  const res = await fetch(`${url}?persno=${pn}`, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res =>
      fetchErrorHandler(_res, { pn }, "analytics/searchParticipantByPn")
    )
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json.users;
    })
    .catch(err => {
      console.error(err);
      if (err && err.name === "ExpectedHttpError") {
        throw err;
      }
    });
  return res;
};

export const getCourseState = async (ssoId, courseId, user) => {
  const url = `${envConfig.TRACKER_REST_API_URI}/analytics/coursestate/user/${ssoId}/course/${courseId}`;

  const actor = getTrackerActorFromUser(user);

  if (_.isEmpty(actor)) throw Error("No user");

  const res = await fetch(url, {
    method: "GET",
    headers: getTrackerReqHeaders(user)
  })
    .then(_res =>
      fetchErrorHandler(_res, { ssoId, courseId }, "analytics/getCourseState")
    )
    .then(_res => {
      return _res.json();
    })
    .then(json => {
      return json;
    })
    .catch(err => {
      console.error(err);
      // throw err;
    });
  return res;
};
