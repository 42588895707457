import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

import introspectionQueryResultData from "../schema.json";

import envConfig from "environment.json";
const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData
});

const uri = envConfig.COURSES_GRAPHQL_URI;

const client = new ApolloClient({
  uri,
  cache
});

export default client;
