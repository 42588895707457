import React, { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withScorm } from "react-scorm-provider";

import {
  setSelectedSection,
  setSelectedChapter,
  setFullCourseState,
  resetNavigation
} from "actions";

import "./ChaptersRetryList.scss";

import CourseSectionChapterNavItem from "components/CourseSectionChapterNavItem/CourseSectionChapterNavItem";
import Loading from "components/Loading/Loading";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import { ENTITY_STATUSES, IS_SCORM } from "consts";

import { postSectionRestarted } from "tracker-api/statements";
import { getCourseState } from "tracker-api/sessions";

import { ReactComponent as MovenextIcon } from "images/icons/movenext.svg";

function ChaptersRetryList(props) {
  const { section } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const eduadminId = useCurrentEduadminId();
  const user = useSelector(getUserWithAccessStatus);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const chapters = section.blbCourseSectionChapterPosts;

  const onClick = useCallback(
    chapterId => {
      async function _onclick() {
        setIsSubmitLoading(true);
        await postSectionRestarted(
          section.blbCourseSectionId,
          eduadminId,
          user,
          sco
        );
        const getRes = await getCourseState(eduadminId, user, true, sco);
        setIsSubmitLoading(false);
        dispatch(setFullCourseState(getRes));
        dispatch(resetNavigation());
        dispatch(setSelectedSection(section.blbCourseSectionId));
        dispatch(setSelectedChapter(chapterId));
      }
      _onclick();
    },
    [dispatch, setIsSubmitLoading, eduadminId, section, user, sco]
  );

  const cls =
    "Chapters-retry-list" +
    (isSubmitLoading ? " Chapters-retry-list--isSubmitLoading" : "");
  return (
    <div className={cls}>
      <ul className="Chapters-retry-list__chapters">
        {chapters.map((chapter, i) => {
          return (
            <CourseSectionChapterNavItem
              key={chapter.blbChapterId}
              isActive={false}
              chapter={chapter}
              chapterNumber={i + 1}
              statusFilter={[ENTITY_STATUSES.VERIFIED_FAILED]}
              onClick={onClick.bind(null, chapter.blbChapterId)}
            />
          );
        })}
      </ul>
      <div className="Chapters-retry-list__nextchapter">
        <button
          type="button"
          className="Chapters-retry-list__nextchapterbtn"
          onClick={onClick.bind(null, chapters[0].blbChapterId)}
        >
          {t("SUPPLEMENT_CHAPTERS")}
          <div className="Chapters-retry-list__nextchapterbtnicon">
            <MovenextIcon />
          </div>
        </button>
      </div>
      {isSubmitLoading ? (
        <div className="Chapters-retry-list__loading">
          <Loading />
        </div>
      ) : null}
    </div>
  );
}

const Comp = IS_SCORM ? withScorm()(ChaptersRetryList) : ChaptersRetryList;

export default Comp;
