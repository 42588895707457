import React from "react";

import "./ChapterFacts.scss";

import { BLOCK_GROUP_NAME } from "consts";

import useCurrentChapter from "hooks/useCurrentChapter";

import FactsSection from "components/FactsSection/FactsSection";
import FactBlock from "components/FactBlock/FactBlock";

function ChapterFacts() {
  const { data } = useCurrentChapter();
  if (!data) return null;

  return (
    <div className="Chapter-facts">
      {data.blocks.map((block, i) => {
        if (block.name === BLOCK_GROUP_NAME) {
          return (
            <div key={i} className="Chapter-facts__section">
              <FactsSection
                block={block}
                isFirst={i === 0}
                isLast={i === data.blocks.length - 1}
              />
            </div>
          );
        } else {
          return <FactBlock key={i} block={block} />;
        }
      })}
    </div>
  );
}

export default ChapterFacts;
