import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useParams, useLocation } from "react-router-dom";
import { withScorm } from "react-scorm-provider";

import "./CourseSummary.scss";

import { ENTITY_STATUSES, IS_BYPASS_SSO, IS_SCORM } from "consts";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCourseProgress from "hooks/useCourseProgress";
import useCurrentCourse from "hooks/useCurrentCourse";
import useSetupCourse from "hooks/useSetupCourse";

import CourseHeader from "components/CourseHeader/CourseHeader";
import CourseNotFound from "components/CourseNotFound/CourseNotFound";
import CourseSummarySection from "components/CourseSummarySection/CourseSummarySection";
import Loading from "components/Loading/Loading";
import Error from "components/Error/Error";
import SummaryHeader from "components/SummaryHeader/SummaryHeader";

function CourseSummary(props) {
  const { t } = useTranslation();
  const { courseSlug } = useParams();
  const { search } = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const {
    isLoading: isLoadingSetupCourse,
    isDone: isDoneSetupCourse,
    initError,
    isParticipant
  } = useSetupCourse(sco);

  const { data, loading } = useCurrentCourse();
  const courseProgress = useCourseProgress();

  const courseState = useSelector(state => state.course.courseState);
  const user = useSelector(getUserWithAccessStatus);

  if (loading || isLoadingSetupCourse) return <Loading isFullPage={true} />;

  const wrapcls = "Course-summary";

  if (initError) {
    return <Error error={initError} />;
  }

  if (!isDoneSetupCourse) return null;

  if (!isParticipant || !data || (data && !data.course)) {
    return <CourseNotFound />;
  }

  // If course is not done yet, or there is no user, just let Course handle it
  if (
    (courseProgress &&
      courseProgress.status < ENTITY_STATUSES.VERIFIED_SUCCESS) ||
    (!user && !IS_BYPASS_SSO)
  ) {
    return <Redirect to={{ pathname: `/course/${courseSlug}`, search }} />;
  }

  return (
    <div className={wrapcls}>
      <CourseHeader subPageName={t("COMPLETED_TASKS")} />
      <SummaryHeader
        preText={t("SUMMARY_OF_COMPLETED_TASKS")}
        headingText={data.course.titleRendered}
        isSuccess={true}
      />

      {courseState ? (
        <div className="Course-summary__sections">
          {courseState.sections.map(_section => {
            const sectionContent =
              data.course.blbGroupCourseSections.blbCourseSections.find(
                _sectionContent => {
                  return _sectionContent.blbCourseSectionId === _section.id;
                }
              );
            return (
              <CourseSummarySection
                key={_section.id}
                sectionProgress={_section}
                sectionTitle={
                  sectionContent ? sectionContent.blbCourseSectionTitle : ""
                }
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

const Comp = IS_SCORM ? withScorm()(CourseSummary) : CourseSummary;

export default Comp;
