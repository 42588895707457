import _ from "lodash";

function getLargeAcfImgUrl(acfImgObj) {
  let largest;
  let large;

  if (acfImgObj.mediaDetails && acfImgObj.mediaDetails.sizes) {
    largest = _.find(
      acfImgObj.mediaDetails.sizes,
      size => size.name === "2048x2048"
    );
    large = _.find(acfImgObj.mediaDetails.sizes, size => size.name === "large");
  }
  return largest
    ? largest.sourceUrl
    : large
    ? large.sourceUrl
    : acfImgObj.sourceUrl
    ? acfImgObj.sourceUrl
    : "";
}

export default getLargeAcfImgUrl;
