const course = (
  state = {
    courseState: null,
    selectedSection: null,
    selectedChapter: null,
    selectedTaskGroup: null,
    sectionToVerify: null,
    sectionToResultView: null,
    courseDataCache: null,
    courseAcfTasksDataCache: null,
    courseDataVersion: null,
    courseDataLoading: false
  },
  action
) => {
  switch (action.type) {
    case "SET_FULL_COURSE_STATE":
      return {
        ...state,
        courseState: action.data
      };
    case "SET_SELECTED_SECTION":
      return {
        ...state,
        selectedSection: action.data,
        selectedChapter: null,
        selectedTaskGroup: null
      };
    case "SET_SELECTED_CHAPTER":
      return {
        ...state,
        selectedChapter: action.data,
        selectedTaskGroup: null
      };
    case "SET_SELECTED_TASK_GROUP":
      return {
        ...state,
        selectedTaskGroup: action.data
      };
    case "SET_SECTION_TO_VERIFY":
      return {
        ...state,
        sectionToVerify: action.data
      };
    case "SET_SECTION_TO_RESULT_VIEW":
      return {
        ...state,
        sectionToResultView: action.data
      };
    case "SET_COURSE_DATA_CACHE":
      return {
        ...state,
        courseDataCache: action.data
      };
    case "SET_COURSE_ACF_TASKS_DATA_CACHE":
      return {
        ...state,
        courseAcfTasksDataCache: action.data
      };
    case "SET_COURSE_DATA_VERSION":
      return {
        ...state,
        courseDataVersion: action.data
      };
    case "SET_COURSE_DATA_LOADING":
      return {
        ...state,
        courseDataLoading: action.data
      };
    case "RESET_NAVIGATION":
      return {
        ...state,
        selectedSection: null,
        selectedChapter: null,
        selectedTaskGroup: null,
        sectionToVerify: null,
        sectionToResultView: null
      };
    default:
      return state;
  }
};

export default course;
