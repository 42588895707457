import _ from "lodash";
export default function getMaybeRandomTaskFromGroup(taskGroupData) {
  const contentArray = taskGroupData.blbChapterTaskPost;
  const progressArray = taskGroupData.progress.tasks;

  // If only one, just use that
  if (contentArray.length === 1) return contentArray[0];

  const progressArrayIds = progressArray.map((_task) => {
    return _task.id;
  });

  // If the task exists in the progress array,
  // it means the user has seen it before (but
  // not neccessarily answered it). TODO maybe completed only?
  // Try to exclude it from the randomization,
  // but if there are no more unanswered, just
  // do regular random
  const filteredContentArray = contentArray.filter((_task) => {
    return !progressArrayIds.includes(_task.blbTaskId);
  });

  return filteredContentArray.length
    ? filteredContentArray[_.random(0, filteredContentArray.length - 1)]
    : contentArray[_.random(0, contentArray.length - 1)];
}
