import React, { useEffect } from "react";
import _ from "lodash";

import "./TaskInteractionGradingStep.scss";

import getTaskType from "utils/getTaskType";

import TaskGradingOption from "components/TaskGradingOption/TaskGradingOption";

const INITIAL_SLIDER_VALUE = 3;

function TaskInteractionGradingStep(props) {
  const { taskData, selectedValues, selectFn } = props;
  const type = getTaskType(taskData);
  const options =
    taskData.blbGroupTaskOptions[`blbTaskOptionType${type}Options`];

  // If there are no selectedValues, it's first render.
  // Populate with default values. Values have a special
  // formatting: {optionid}_{slidervalue}, in order
  // to retain and reuse conventions once this
  // is sent to tracker. // TODO we'll se if this holds up
  // after Mickes implementation.
  useEffect(() => {
    if (selectedValues.length !== options.length) {
      const emptyInitialValues = options.map(option => {
        return {
          optionId: option.blbTaskTypeTextOptionId,
          extraData: INITIAL_SLIDER_VALUE
        };
      });
      selectFn(emptyInitialValues);
    }
  }, [selectedValues, options, selectFn]);

  // On change, modify the affected array index with
  // the new slider value.
  const onChange = (val, optionId) => {
    const newArr = selectedValues.map((selectedValue, _index) => {
      if (selectedValue.optionId === optionId) return { ...selectedValue, extraData: val };
      return selectedValue;
    });
    selectFn(newArr);
  };

  // Don't bother rendering until we're ready
  if (_.isEmpty(selectedValues)) return null;

  return (
    <div className="Task-interaction-grading-step">
      <ul>
        {options.map((option, i) => {
          const id = option.blbTaskTypeTextOptionId;
          const label = option.blbTaskTypeTextOptionLabel;
          const value = selectedValues[i].extraData;
          return (
            <li className="Task-interaction-grading-step__option" key={id}>
              <TaskGradingOption
                id={id}
                label={label}
                onChange={onChange}
                value={value}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TaskInteractionGradingStep;
