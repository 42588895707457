import { useEffect, useState } from "react";
import { withScorm } from "react-scorm-provider";
import { IS_SCORM } from "consts";
import Error from "components/Error/Error"
import { useTranslation } from "react-i18next";;

// Halt high-level render if we're in Scorm
// and API can't load. Wait 1 sec before
// showing error.

const ScormApiChecker = ({ sco, children }) => {
  const [waited, setWaited] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWaited(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (!IS_SCORM || (IS_SCORM && sco.apiConnected)) return children;

  if (!waited) return null;

  // TODO lang strings
  return <Error heading={t("SCORM_DISCONNECTED_HEADING")} error={t("SCORM_DISCONNECTED_TEXT")} />;
};

const Comp = IS_SCORM ? withScorm()(ScormApiChecker) : ScormApiChecker;

export default Comp;
