import { TASK_OPTION_TYPES } from "consts";
import getTaskType from "utils/getTaskType";

function isReflectiveTask(taskData) {
  const type = getTaskType(taskData);
  // WP doesnt support order
  // tasks to be reflective at this time
  if (type === TASK_OPTION_TYPES.ORDER) return false;

  const key = type;
  const optionsKey = `blbTaskOptionType${key}Options`;
  const correctKey = `blbTaskType${key}OptionCorrect`;
  const taskOptions = taskData.blbGroupTaskOptions[optionsKey];
  const rightAnswers = taskOptions.filter(opt => {
    return !!opt[correctKey];
  });
  // If there are no right answers, we
  // consider it to be a "reflective" task

  // TODO also account for when editor  set all to correct
  // combined with "allow incorrect answers"

  return type !== "Match" ? !rightAnswers.length : false;
}

export default isReflectiveTask;
