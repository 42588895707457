import { gql } from "@apollo/client";

const BOTTOM_LEVEL_BLOCKS_FRAGMENT = gql`
  # Declare block-type specific stuff
  # that we want to use on top level
  # blocks, inner blocks in groups,
  # and inner blocks in columns
  fragment bottomLevelBlocks on Block {
    ... on CoreParagraphBlock {
      attributes {
        ... on CoreParagraphBlockAttributes {
          align
        }
      }
    }
    ... on CoreImageBlock {
      attributes {
        ... on CoreImageBlockAttributes {
          caption
          url
          alt
          align
        }
      }
    }
    ... on CoreHeadingBlock {
      attributes {
        ... on CoreHeadingBlockAttributes {
          content
          align
        }
      }
    }
    ... on CoreTableBlock {
      dynamicContent
      originalContent
    }

    ... on BucLmsBuilderRichListBlock {
      innerBlocks {
        name
        originalContent
        ... on BucLmsBuilderRichListItemBlock {
          attributes {
            body
            image
            imageAlt
            imageSize
          }
        }
      }
    }
    ... on AcfTaskBlock {
      dynamicContent
    }
    ... on BucLmsBuilderImageAreaMapBlock {
      attributes {
        image
        imageAlt
        polygons
        clues
        size
        alignment
      }
    }
    ... on BucLmsBuilderImagePointMapBlock {
      attributes {
        image
        imageAlt
        points
        size
        alignment
      }
    }
  }
`;

const SLIDER_FIELDS_FRAGMENT = gql`
  ${BOTTOM_LEVEL_BLOCKS_FRAGMENT}
  fragment sliderFields on Block {
    ... on BucLmsBuilderSliderBlock {
      innerBlocks {
        name
        originalContent
        ... on BucLmsBuilderSliderItemBlock {
          attributes {
            image
          }
          innerBlocks {
            name
            originalContent
            ...bottomLevelBlocks
          }
        }
      }
    }
  }
`;

const MODAL_FIELDS_FRAGMENT = gql`
  ${BOTTOM_LEVEL_BLOCKS_FRAGMENT}
  ${SLIDER_FIELDS_FRAGMENT}
  fragment modalFields on Block {
    ... on BucLmsBuilderModalBlock {
      name
      attributes {
        triggerType
        triggerText
        triggerButtonLabel
        triggerImage
        triggerImageAlt
        size
        alignment
      }

      innerBlocks {
        name
        originalContent
        ...bottomLevelBlocks
        ...sliderFields
      }
    }
  }
`;

const BLOCK_FIELDS_FRAGMENT = gql`
  ${BOTTOM_LEVEL_BLOCKS_FRAGMENT}
  ${MODAL_FIELDS_FRAGMENT}
  ${SLIDER_FIELDS_FRAGMENT}
  fragment blockFields on Block {
    name
    originalContent
    ...bottomLevelBlocks
    ... on BucLmsBuilderColumnsBlock {
      innerBlocks {
        name
        originalContent
        ... on BucLmsBuilderColumnItemBlock {
          innerBlocks {
            name
            originalContent
            ...bottomLevelBlocks
            ...modalFields
          }
        }
      }
    }
    ... on BucLmsBuilderRepetitionQuestionBlock {
      attributes {
        questionText
        closingMessage
      }
      innerBlocks {
        name
        originalContent
        ...bottomLevelBlocks
      }
    }
    ...sliderFields
    ...modalFields
  }
`;

const TASK_FRAGMENT = gql`
  fragment taskFragment on Task {
    # id
    blbTaskId
    titleRendered
    blbGroupTaskContent {
      blbTaskMainContent
      blbTaskMainSoundFile {
        mediaItemUrl
      }
      blbTaskContent {
        ... on Task_Blbgrouptaskcontent_BlbTaskContent_Heading {
          blbHeading
        }
        ... on Task_Blbgrouptaskcontent_BlbTaskContent_RichText {
          blbRichText
        }
        ... on Task_Blbgrouptaskcontent_BlbTaskContent_Image {
          blbImage {
            sourceUrl
            altText
          }
        }
        ... on Task_Blbgrouptaskcontent_BlbTaskContent_Vimeo {
          blbVimeo
        }
      }
    }
    blbGroupTaskOptions {
      blbTaskOptionsTitle
      blbTaskOptionType
      blbTaskOptionTypeImageOptions {
        blbTaskTypeImageOptionDatabaseId
        blbTaskTypeImageOptionId
        blbTaskTypeImageOptionCorrect
        blbTaskTypeImageOptionLabel
        blbTaskTypeImageOptionImage {
          id
          databaseId
          sourceUrl
        }
        blbTaskTypeImageOptionFeedback {
          ... on Task_Blbgrouptaskoptions_blbTaskOptionTypeImageOptions_BlbTaskTypeImageOptionFeedback_RichText {
            blbRichText
          }
          ... on Task_Blbgrouptaskoptions_blbTaskOptionTypeImageOptions_BlbTaskTypeImageOptionFeedback_Image {
            blbImage {
              altText
              sourceUrl
            }
          }
        }
      }
      blbTaskOptionTypeTextOptions {
        blbTaskTypeTextOptionDatabaseId
        blbTaskTypeTextOptionId
        blbTaskTypeTextOptionCorrect
        blbTaskTypeTextOptionLabel
        blbTaskTypeTextOptionFeedback {
          ... on Task_Blbgrouptaskoptions_blbTaskOptionTypeTextOptions_BlbTaskTypeTextOptionFeedback_RichText {
            blbRichText
          }
          ... on Task_Blbgrouptaskoptions_blbTaskOptionTypeTextOptions_BlbTaskTypeTextOptionFeedback_Image {
            blbImage {
              # altText # TODO For some reason, Apollo spins out when including this. Maybe put back if it's resolved in later versions of apollo
              sourceUrl
            }
          }
        }
      }
      blbTaskOptionTypeOrderOptions {
        blbTaskTypeOrderOptionDatabaseId
        blbTaskTypeOrderOptionId
        blbTaskTypeOrderOptionCorrectPosition
        blbTaskTypeOrderOptionLabel
      }
      blbTaskOptionTypeSliderOptions {
        blbTaskTypeSliderOptionDatabaseId
        blbTaskTypeSliderOptionId
        blbTaskTypeSliderSettings {
          blbTaskTypeSliderSettingLabelLeft
          blbTaskTypeSliderSettingLabelRight
        }
        blbTaskTypeSliderOptionLabel
        blbTaskTypeSliderOptionImage {
          sourceUrl
          altText
          id
          databaseId
        }
      }
      blbTaskOptionTypeMatchOptions {
        blbTaskTypeMatchOptionId
        blbTaskTypeMatchOptionLabel
        blbTaskTypeMatchOptionCorrectMatchoptionId
      }
      blbTaskMatchoptions {
        blbTaskMatchoptionId
        blbTaskMatchoptionLabel
      }
      blbTaskFeedback {
        blbTaskFeedbackBody
        blbTaskFeedbackSoundFile {
          mediaItemUrl
        }
        blbTaskFeedbackTitle
      }
      blbTaskOptionInitialOption {
        ... on Task_Blbgrouptaskoptions_BlbTaskOptionInitialOption_RichText {
          blbRichText
        }
        ... on Task_Blbgrouptaskoptions_BlbTaskOptionInitialOption_Image {
          blbImage {
            altText
            sourceUrl
          }
        }
      }
      blbTaskOptionAllowIncorrect
    }
    blbGroupTaskSteps {
      blbTaskSteps {
        blbTaskStepOptionsTitle
        blbTaskStepType
        blbTaskStepShowGrade
        blbTaskStepMainContent
        blbTaskStepSoundFile {
          mediaItemUrl
        }
        blbTaskStepContent {
          ... on Task_Blbgrouptasksteps_blbTaskSteps_BlbTaskStepContent_Heading {
            blbHeading
          }
          ... on Task_Blbgrouptasksteps_blbTaskSteps_BlbTaskStepContent_RichText {
            blbRichText
          }
          ... on Task_Blbgrouptasksteps_blbTaskSteps_BlbTaskStepContent_Image {
            blbImage {
              sourceUrl
              altText
            }
          }
          ... on Task_Blbgrouptasksteps_blbTaskSteps_BlbTaskStepContent_Vimeo {
            blbVimeo
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_TASK = gql`
  ${TASK_FRAGMENT}
  query GetTask($id: ID!) {
    task(id: $id, idType: ID) {
      ...taskFragment
    }
  }
`;

export const GET_PAGE = gql`
  ${BLOCK_FIELDS_FRAGMENT}

  query GetPage($pageSlug: ID!) {
    page(id: $pageSlug, idType: URI) {
      id
      title
      blbGroupCourseTheme {
        blbCourseTheme
      }
      blbGroupPageHero {
        blbPageHeroImage {
          altText
          sourceUrl
          mediaDetails {
            sizes {
              width
              name
              height
              sourceUrl
            }
          }
        }
        blbPageHeroSubtitle
      }
      blocks {
        ...blockFields
        ... on BucLmsBuilderGroupBlock {
          name
          attributes {
            title
            audioUrl
          }
          innerBlocks {
            ...blockFields
          }
        }
      }
    }
  }
`;

export const GET_COURSE_METADATA = gql`
  query GetCourseMetadata($courseSlug: ID!) {
    course(id: $courseSlug, idType: SLUG) {
      blbCourseId
      title
      titleRendered
      blbGroupCourseEduadminId {
        blbCourseEduadminId
        blbCourseIsFasttrack
      }
    }
  }
`;

export const GET_COURSE = gql`
  ${BLOCK_FIELDS_FRAGMENT}
  ${TASK_FRAGMENT}
  query GetCourse($courseSlug: ID!) {
    course(id: $courseSlug, idType: SLUG) {
      # id
      blbCourseId
      title
      titleRendered
      blbGroupCourseEduadminId {
        blbCourseEduadminId
        blbCourseIsFasttrack
      }
      blbGroupCourseId06 {
        blbCourseId06DisableLink
      }
      blbGroupCourseIntroduction {
        blbCourseIntroductionVideo
        blbCourseIntroductionTitle
        blbCourseIntroductionMediaType
        blbCourseIntroductionText
        blbCourseIntroductionImage {
          altText
          sourceUrl
        }
      }
      blbGroupCourseSurvey {
        blbCourseSurveyLink
      }
      blbGroupCourseTheme {
        blbCourseTheme
      }
      languages {
        nodes {
          slug
        }
      }
      blbGroupCourseSections {
        blbCourseSections {
          blbCourseSectionId
          blbCourseSectionTitle
          blbCourseSectionDescription
          blbCourseSectionChapterPosts
          blbCourseSectionInstantFeedbackToggle {
            ... on Chapter {
              # id
              blbChapterId
              title
              titleRendered
              databaseId
              blbGroupChapterIntro {
                blbChapterIntroIcon {
                  id
                  sourceUrl
                  altText
                }
                blbChapterIntroContent
              }
              blocks {
                ...blockFields
                ... on BucLmsBuilderGroupBlock {
                  name
                  attributes {
                    title
                    audioUrl
                  }
                  innerBlocks {
                    ...blockFields
                  }
                }
              }
              blbGroupChapterTasks {
                blbChapterTasks {
                  blbChapterTaskGroupId
                  blbChapterTaskPost {
                    ...taskFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COURSE_SHALLOW = gql`
  query GetCourseShallow($courseSlug: ID!) {
    course(id: $courseSlug, idType: SLUG) {
      id
      title
    }
  }
`;
