import React from "react"; //eslint-disable-line
import { useSelector } from "react-redux";
import _ from "lodash";
import useCurrentSection from "hooks/useCurrentSection";
import useChapterProgress from "hooks/useChapterProgress";

import { ENTITY_STATUSES } from "consts";

function useNextChapter() {
  const selectedChapter = useSelector(state => state.course.selectedChapter);

  const { loading, error, data } = useCurrentSection();

  // Check the progress result for the first chapter
  // that's not already completed
  const firstIncompleteChapterProgress = data
    ? _.find(data.progress.chapters, _chapter => {
        // If a chapter is currently selected,
        // we don't want that to be the next chapter
        // since the next chapter may be resolved before
        // the current is marked as completed

        return selectedChapter
          ? !_chapter.completed && selectedChapter !== _chapter.id
          : !_chapter.completed;
      })
    : null;

  // Get the index of the "content" for the
  // chapter, by using the id we got from progress.
  // Index should be the same but you never know.
  // If there is no firstIncompleteChapterProgress, it
  // probably means we're on the last chapter.
  const firstIncompleteChapterIndex = firstIncompleteChapterProgress
    ? _.findIndex(data.blbCourseSectionChapterPosts, _chapter => {
        return _chapter.blbChapterId === firstIncompleteChapterProgress.id;
      })
    : -1;

  // Get the chapter by index
  const firstIncompleteChapter =
    firstIncompleteChapterIndex !== -1
      ? data.blbCourseSectionChapterPosts[firstIncompleteChapterIndex]
      : null;

  // TODO maybe make this more sofisticated so that
  // we can enable stepping between success chapters
  // via ChapterIntersection
  const firstWhateverChapter = data && data.blbCourseSectionChapterPosts[0];

  const sectionIsSuccess =
    data && data.progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS;

  const chapterProgress = useChapterProgress(
    sectionIsSuccess && firstWhateverChapter
      ? firstWhateverChapter
      : firstIncompleteChapter
  );

  // If we found a chapter, return that. If not, just return null,
  // which consuming components should interpret as moving away
  // from that section. If section is success, it's assumed
  // that the user want repeat facts again, so just return the
  // first chapter of the section.
  return {
    loading,
    error,
    data:
      sectionIsSuccess && firstWhateverChapter && chapterProgress
        ? {
            ...firstWhateverChapter,
            chapterNumber: 1,
            progress: chapterProgress
          }
        : firstIncompleteChapter && chapterProgress
        ? {
            ...firstIncompleteChapter,
            chapterNumber: firstIncompleteChapterIndex + 1,
            progress: chapterProgress
          }
        : null
  };
}

export default useNextChapter;
