import { useSelector } from "react-redux";
import _ from "lodash";
import { GQL_SECTIONS_NEST } from "consts";
import useCurrentCourse from "hooks/useCurrentCourse";
import useChapterProgress from "hooks/useChapterProgress";

import getChapterByIdFromSection from "utils/getChapterByIdFromSection";

function useCurrentChapter() {
  const selectedSection = useSelector((state) => state.course.selectedSection);
  const selectedChapter = useSelector((state) => state.course.selectedChapter);

  const { loading, error, data } = useCurrentCourse();

  // Get all sections
  const allSections = data ? _.get(data, GQL_SECTIONS_NEST) : null;

  // Find which section we're in using url param
  const section = allSections
    ? _.find(allSections, (section) => {
        return section.blbCourseSectionId === selectedSection;
      })
    : null;

  const { chapter, chapterIndex } = getChapterByIdFromSection(selectedChapter, section);

  const progress = useChapterProgress(chapter);

  // Extend the data with the "index" of the chapter.
  return {
    loading,
    error,
    data: chapter && progress
      ? { ...chapter, chapterNumber: chapterIndex + 1, progress }
      : null,
  };
}

export default useCurrentChapter;
