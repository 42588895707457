import { TASK_OPTION_TYPES } from "consts";
import getTaskType from "utils/getTaskType";
import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";
import getTaskSelectedValuesExtradatas from "utils/getTaskSelectedValuesExtradatas";

const isImageOrTextTaskSuccess = (task, selectedValues, isImage) => {
  let r = false;

  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);

  const key = isImage ? TASK_OPTION_TYPES.IMAGE : TASK_OPTION_TYPES.TEXT;
  const arrayKey = `blbTaskOptionType${key}Options`;
  const idKey = `blbTaskType${key}OptionId`;
  const successKey = `blbTaskType${key}OptionCorrect`;

  const successAnswers = task.blbGroupTaskOptions[arrayKey]
    .filter((opt) => {
      return opt[successKey];
    })
    .map((opt) => {
      return opt[idKey];
    })
    .sort();

  // If no answers are marked as success,
  // we assume all of them are
  if (!successAnswers.length) return true;

  // If at least one of the selectedValues
  // is success
  [...selectedValuesIds].sort().forEach((selectedValue) => {
    if (successAnswers.includes(selectedValue)) {
      r = true;
    }
  });

  // If we're not supposed to allow incorrect answers, we
  // need to eval two things:
  // - That user hasn't picked at least 1 wrong answer
  // - That user has picked all the right answers
  // We do this by sorting both selected and success
  // arrays and comparing them as strings
  if (!task.blbGroupTaskOptions.blbTaskOptionAllowIncorrect) {
    r =
      [...selectedValuesIds].sort().join("") ===
      [...successAnswers].sort().join("");
  }

  return r;
};

const isSliderTaskSuccess = (task, selectedValues) => {
  // Slider questions are always true for now.
  // Should it be possible to answer wrong?
  return true;
};

const isOrderTaskSuccess = (task, selectedValues) => {
  const selectedValuesExtradatas = getTaskSelectedValuesExtradatas(selectedValues);
  // This will always be 1,2,3,4 etc
  const successOrder = task.blbGroupTaskOptions.blbTaskOptionTypeOrderOptions
    .map((opt) => {
      return opt.blbTaskTypeOrderOptionCorrectPosition;
    })
    .sort();

  // Therefore, the success use input should always be
  // 1,2,3,4 etc, since selectedValues.extraData are blbTaskTypeOrderOptionCorrectPosition
  return successOrder.join("") === selectedValuesExtradatas.join("");
};

const isMatchTaskSuccess = (task, selectedValues) => {
  const selectedValuesExtradatas = getTaskSelectedValuesExtradatas(selectedValues);
  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);

  return selectedValuesExtradatas.join("") === selectedValuesIds.join("")
};

const isTaskSuccess = (task, selectedValues) => {
  let r = false;
  if (getTaskType(task) === TASK_OPTION_TYPES.TEXT) {
    r = isImageOrTextTaskSuccess(task, selectedValues, false);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.SLIDER) {
    r = isSliderTaskSuccess(task, selectedValues);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.IMAGE) {
    r = isImageOrTextTaskSuccess(task, selectedValues, true);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.ORDER) {
    r = isOrderTaskSuccess(task, selectedValues);
  } else if (getTaskType(task) === TASK_OPTION_TYPES.MATCH) {
    r = isMatchTaskSuccess(task, selectedValues);
  }
  return r;
};

export default isTaskSuccess;
