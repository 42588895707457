import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setSectionToResultView } from "actions";

import "./CourseSummarySection.scss";

function CourseSummarySection(props) {
  const { t } = useTranslation();
  const { sectionProgress, sectionTitle } = props;
  const dispatch = useDispatch();

  return (
    <div className="Course-summary-section">
      <header className="Course-summary-section__header">
        <h2 className="Course-summary-section__heading">
          {t("SECTION")}:{" "}
          <span className="Course-summary-section__headinghighlight">
            {sectionTitle}
          </span>
        </h2>
        <button
          type="button"
          className="Course-summary-section__headerlink"
          onClick={() => dispatch(setSectionToResultView(sectionProgress.id))}
        >
          {t("YOUR_ANSWERS")}
        </button>
      </header>
      <div className="Course-summary-section__results">
        <div className="Course-summary-section__result Course-summary-section__result--success">
          {t("X_SUCCESS_ANSWERS", {
            successCount: sectionProgress.noOfCorrectAnswers,
          })}
        </div>
        <div className="Course-summary-section__result Course-summary-section__result--fail">
          {t("X_FAIL_ANSWERS", {
            failCount: sectionProgress.noOfIncorrectAnswers,
          })}
        </div>
      </div>
      <p className="Course-summary-section__total">
        {t("TOTAL")}{" "}
        <strong className="Bold">
          {t("X_TASKS", {
            tasksCount:
              sectionProgress.noOfCorrectAnswers +
              sectionProgress.noOfIncorrectAnswers,
          })}
        </strong>
      </p>
      {/* TODO reflective answers */}
    </div>
  );
}

export default CourseSummarySection;
