import React, { forwardRef } from "react";

const CoreList = forwardRef(function CoreList(props, ref) {
  const { block } = props;
  return (
    <div ref={ref} className="Fact-block--CoreList" dangerouslySetInnerHTML={{ __html: block.originalContent }} />
  );
})

export default CoreList;
