const layout = (
  state = {
    chapterNavExpanded: false,
    taskOverlayOpen: false,
    chapterCompletedOverlayOpen: false,
    currentSoundIdPlaying: null,
    theme: "buc", // TODO could we resolve theme earlier to avoid this? Maybe map the slug to something?
    didSetTheme: false,
    factContentModalToView: null,
    prefaceExpanded: true,
    // This is just to show off the book animation
    // if loading goes too fast :)
    waitedLongEnough: false,
    languageWasSetFromCourse: false,
    availableLanguages: [],
    currentLanguage: 'sv'
  },
  action
) => {
  switch (action.type) {
    case "TOGGLE_CHAPTER_NAV":
      return {
        ...state,
        chapterNavExpanded: !state.chapterNavExpanded
      };
    case "EXPAND_CHAPTER_NAV":
      return {
        ...state,
        chapterNavExpanded: true
      };
    case "COLLAPSE_CHAPTER_NAV":
      return {
        ...state,
        chapterNavExpanded: false
      };
    case "OPEN_TASK_OVERLAY":
      return {
        ...state,
        taskOverlayOpen: true
      };
    case "CLOSE_TASK_OVERLAY":
      return {
        ...state,
        taskOverlayOpen: false
      };
    case "OPEN_CHAPTER_COMPLETED_OVERLAY":
      return {
        ...state,
        chapterCompletedOverlayOpen: true
      };
    case "CLOSE_CHAPTER_COMPLETED_OVERLAY":
      return {
        ...state,
        chapterCompletedOverlayOpen: false
      };
    case "SET_WAITED_LONG_ENOUGH":
      return {
        ...state,
        waitedLongEnough: true
      };
    case "SET_CURRENT_SOUND_ID_PLAYING":
      return {
        ...state,
        currentSoundIdPlaying: action.data
      };
    case "SET_THEME":
      return {
        ...state,
        theme: action.data,
        didSetTheme: true
      };
    case "SET_FACT_CONTENT_MODAL_TO_VIEW":
      return {
        ...state,
        factContentModalToView: action.data
      };
    case "SET_PREFACE_EXPANDED":
      return {
        ...state,
        prefaceExpanded: action.data
      };
    case "SET_LANGUAGE_WAS_SET_FROM_COURSE":
      return {
        ...state,
        languageWasSetFromCourse: true
      };
    case "SET_AVAILABLE_LANGUAGES":
      return {
        ...state,
        availableLanguages: action.data
      };
    case "SET_CURRENT_LANGUAGE":
      return {
        ...state,
        currentLanguage: action.data
      };
    default:
      return state;
  }
};

export default layout;
