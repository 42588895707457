import React from "react";

import "./CarouselItem.scss";

import FactBlock from "components/FactBlock/FactBlock";

function CarouselItem(props) {
  const { image, innerBlocks } = props;
  return (
    <div className="Carousel-item">
      {image ? (
        <div className="Carousel-item__image">
          <img src={image} alt="" />
        </div>
      ) : null}
      <div className="Carousel-item__content">
        {innerBlocks.map((innerBlock, i) => {
          return (
            <FactBlock
              key={i}
              block={innerBlock}
              allowedBlocks={[
                "CoreParagraph",
                "CoreHeading",
                "CoreList",
                "BucLmsBuilderImageAreaMap",
                "BucLmsBuilderImagePointMap"
              ]}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CarouselItem;
