import React, { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import envConfig from "environment.json";

import "./LoginBridge.scss";

import { REDIRECT_TO_COOKIE_NAME } from "consts";

import getUserManager from "userManager";

import debugLog from "utils/debugLog";

function LoginBridge({ isFasttrack }) {
  const userManager = getUserManager();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([REDIRECT_TO_COOKIE_NAME]);
  const referrer = useSelector(state => state.session.referrer);

  debugLog('referrer',referrer);

  const { courseSlug } = useParams();

  // Remember where we are so the callback
  // can redirect back later after login
  useEffect(() => {
    if (courseSlug) {
      setCookie(REDIRECT_TO_COOKIE_NAME, courseSlug, {
        path: "/"
      });
    }
  }, [courseSlug, setCookie]);

  // If we're on a fasttrack course,
  // send user back to fasttrack url
  // if they were not redirected here directly
  // from the fasttrack app.
  const login = useCallback(() => {
    if (isFasttrack && referrer !== "fasttrack") {
      window.location.assign(`${envConfig.FASTTRACK_LOGOUT_URL}`); // redirect to SCT-snabbspår login page
    } else {
      // Handles both non-fasttrack cases and cases where
      // user is properly logged in, but session was not
      // kept due to 3rdparty cookie settings etc
      userManager.signinRedirect();
    }
  }, [isFasttrack, referrer]);

  return (
    <div className="Login-bridge">
      <h1 className="Login-bridge__heading">{t("LOGIN_REQUIRED")}</h1>
      <p className="Login-bridge__text">
        {isFasttrack ? t("SCT_LOGIN_REQUIRED_TEXT") : t("LOGIN_REQUIRED_TEXT")}
      </p>
      {!window.thirdPartyCookiesAreSupported ? (
        <p className="Login-bridge__text Login-bridge__text--warning">
          {t("3RD_PARTY_COOKIE_WARNING", {
            loginBtnText: t("LOG_IN")
          })}
        </p>
      ) : null}

      <button className="Login-bridge__button" type="button" onClick={login}>
        {t("LOG_IN")}
      </button>
    </div>
  );
}

export default LoginBridge;
