import _ from "lodash";

function getTaskGroupByIdFromChapter(taskGroupId, chapter) {
  const taskGroupIndex =
    chapter && chapter.blbGroupChapterTasks.blbChapterTasks
      ? _.findIndex(
          chapter.blbGroupChapterTasks.blbChapterTasks,
          (taskGroup) => {
            return taskGroup.blbChapterTaskGroupId === taskGroupId;
          }
        )
      : -1;

  const taskGroup =
    taskGroupIndex !== -1
      ? chapter.blbGroupChapterTasks.blbChapterTasks[taskGroupIndex]
      : null;

  return { taskGroup, taskGroupIndex };
}

export default getTaskGroupByIdFromChapter;
