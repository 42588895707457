import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";

import { resetNavigation, collapseChapterNav } from "actions";

function HomeLink(props) {
  const { search } = useLocation();
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const { className, linkText, iconComponent } = props;
  const IconComponentToRender = iconComponent ? iconComponent : null;
  return (
    <Link
      to={{ pathname: `/course/${courseSlug}`, search }}
      className={className}
      onClick={e => {
        dispatch(collapseChapterNav());
        dispatch(resetNavigation());
      }}
    >
      {IconComponentToRender ? <IconComponentToRender /> : null}
      {linkText}
    </Link>
  );
}

export default HomeLink;
