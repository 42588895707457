import { createUserManager } from "redux-oidc";
import envConfig from "environment.json";

import debugLog from "utils/debugLog";

let userManager;

const getUserManager = () => {
  if(userManager) return userManager;

  debugLog("No userManager initiated, creating where 3rd party cookies are", window.thirdPartyCookiesAreSupported);

  const shouldMonitorSession = window.thirdPartyCookiesAreSupported;
  
  const config = {
    authority: envConfig.SSO_AUTHORITY,
    client_id: "buc_lms_viewer",
    redirect_uri: `${envConfig.URL}/callback`,
    response_type: "code",
    scope:
      "openid profile user_information buclms_api kopplingsbox_api company_information account_type kpbx_id06_kompdb_api",
    post_logout_redirect_uri: envConfig.FASTTRACK_LOGOUT_URL,
    silent_redirect_uri: `${envConfig.URL}/silentrenew`,
  
    automaticSilentRenew: shouldMonitorSession,
    monitorSession: shouldMonitorSession
  };
  userManager = createUserManager(config);
  return userManager;
}

export default getUserManager;