import React from "react";

import "./TaskFinalFeedback.scss";
import SoundPlayer from "components/SoundPlayer/SoundPlayer";
import { t } from "i18next";

function TaskFinalFeedback(props) {
  const { feedback, isOnFinalFeedback } = props;

  const soundUrl = feedback.blbTaskFeedbackSoundFile
    ? feedback.blbTaskFeedbackSoundFile.mediaItemUrl
    : null;

  return (
    <div className="Task-final-feedback">
      {!isOnFinalFeedback && soundUrl ? (
        <div key={soundUrl} className="Task-header__sound">
          <SoundPlayer sourceUrl={soundUrl} playText={t("LISTEN_TO_FEEDBACK")} />
        </div>
      ) : null}
      {feedback.blbTaskFeedbackTitle ? (
        <h2 className="Task-final-feedback__heading">
          {feedback.blbTaskFeedbackTitle}
        </h2>
      ) : null}
      <p className="Task-final-feedback__body">{feedback.blbTaskFeedbackBody}</p>
    </div>
  );
}

export default TaskFinalFeedback;
