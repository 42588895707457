// TODO success states
// Status 3 = completed
// Status 2 = not yet completed
// Status 1 = not yet started
function getTaskGroupStatus(object) {
  if(object.completed) return 3;
  return 1; 
}

export default getTaskGroupStatus;
