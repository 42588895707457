import React from "react";
import { useTranslation } from "react-i18next";

import "./Chapter.scss";

import useCurrentChapter from "hooks/useCurrentChapter";
import useCurrentSection from "hooks/useCurrentSection";

import ChapterFacts from "components/ChapterFacts/ChapterFacts";
import ChapterIntersection from "components/ChapterIntersection/ChapterIntersection";

import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";

import { ENTITY_STATUSES } from "consts";

function Chapter() {
  const { t } = useTranslation();
  const sectionObj = useCurrentSection();
  const chapterObj = useCurrentChapter();

  if (chapterObj.loading) return <Loading />;
  if (chapterObj.error || !chapterObj.data)
    return <Error error={chapterObj.error} />;

  const cls =
    "Chapter" +
    (sectionObj.data &&
    sectionObj.data.progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS
      ? " Chapter--isSectionSuccess"
      : "") +
    (chapterObj.data &&
    chapterObj.data.progress.status === ENTITY_STATUSES.COMPLETE
      ? " Chapter--isChapterCompleted"
      : "");
  return (
    <article className={cls}>
      <div className="Chapter__inner">
        <header className="Chapter__intro">
          <p className="Chapter__chapternumber">
            {t("CHAPTER_X", { chapterNumber: chapterObj.data.chapterNumber })}
          </p>
          <h1 className="Chapter__title">{chapterObj.data.titleRendered}</h1>
          {chapterObj.data.blbGroupChapterIntro.blbChapterIntroIcon ? (
            <div className="Chapter__icon">
              <img
                src={
                  chapterObj.data.blbGroupChapterIntro.blbChapterIntroIcon
                    .sourceUrl
                }
                alt={
                  chapterObj.data.blbGroupChapterIntro.blbChapterIntroIcon
                    .altText
                }
              />
            </div>
          ) : null}
          <p className="Chapter__description">
            {chapterObj.data.blbGroupChapterIntro.blbChapterIntroContent}
          </p>
        </header>
        <h2 className="Chapter__subheading">{t("FACTS_AND_READING")}</h2>

        <div className="Chapter__facts">
          <ChapterFacts />
        </div>
        <h2 className="Chapter__subheading Chapter__subheading--tasks">
          {t("TASKS")}
        </h2>
        <div className="Chapter__intersection">
          <ChapterIntersection />
        </div>
      </div>
    </article>
  );
}

export default Chapter;
