import { useEffect } from "react";
import { processSilentRenew } from "redux-oidc";

function SilentCallback() {
  useEffect(() => {
    processSilentRenew();
  }, []);

  return null;
}

export default SilentCallback;
