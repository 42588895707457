import React from "react";
import { useTranslation, Trans } from "react-i18next";

import "./Error.scss";

// TODO more elaborate error messages?
function Error(props) {
  const { error, linkUrl, linkLabel, heading, linkEmail } = props;
  const { t } = useTranslation();
  console.error(error);

  const isPlainText = typeof error === "string";
  const supportEmail = linkEmail;

  return (
    <div className="Error">
      <h1 className="Error__heading">
        {heading ? heading : t("SOMETHING_WENT_WRONG")}
      </h1>
      {error ? (
        <div>
          {isPlainText ? (
            <p className="Error__text">{error}</p>
          ) : (
            <pre>{`${JSON.stringify(error.message)}`}</pre>
          )}
        </div>
      ) : null}
      {linkEmail ? (
        <Trans
          i18nKey="CONTACT_SUPPORT_ERROR"
          support={supportEmail}
        >
          <a
            href={`mailto:${supportEmail}`}
            className="User-menu__support__link"
            >{{supportEmail}}
          </a>
        </Trans>
      ) : null }
      {linkUrl && linkLabel ? (
        <p className="Error__link">
          <a href={linkUrl}>{linkLabel}</a>
        </p>
      ) : null}
    </div>
  );
}

export default Error;
