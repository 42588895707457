import { useSelector } from "react-redux";
import useCurrentChapter from "hooks/useCurrentChapter";
import useTaskGroupProgress from "hooks/useTaskGroupProgress";

import getTaskGroupByIdFromChapter from "utils/getTaskGroupByIdFromChapter";

function useCurrentTaskGroup() {
  const selectedTaskGroup = useSelector(
    (state) => state.course.selectedTaskGroup
  );

  const { loading, error, data } = useCurrentChapter();

  const { taskGroup, taskGroupIndex } = getTaskGroupByIdFromChapter(
    selectedTaskGroup,
    data
  );

  const progress = useTaskGroupProgress(taskGroup);

  return {
    loading,
    error,
    data: taskGroup && progress
      ? { ...taskGroup, taskNumber: taskGroupIndex + 1, progress }
      : null,
  };
}

export default useCurrentTaskGroup;
