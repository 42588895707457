import React from "react";
import { useTranslation } from "react-i18next";

import "./Maintenance.scss";

function Maintenance() {
  const { t } = useTranslation();
  return (
    <div className="Maintenance">
      <h1 className="Maintenance__heading">{t("MAINTENANCE_HEADING")}</h1>
      <p className="Maintenance__text">{t("MAINTENANCE_TEXT")}</p>
    </div>
  );
}

export default Maintenance;
