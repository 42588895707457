const getUserConfigForRollbar = user => {
  return {
    payload: {
      person: user
        ? {
            id:
              (user &&
                user.profile &&
                user.profile[
                  "https://sverigesbyggindustrier.se/civic_registration_number"
                ]) ||
              "",
            email: (user && user.profile && user.profile.name) || ""
          }
        : { id: null }
    }
  };
};

export default getUserConfigForRollbar;
