import React, { useEffect } from "react";
import _ from "lodash";

import PropTypes from "prop-types";

import { TASK_OPTION_TYPES } from "consts";

import "./TaskOptionFeedback.scss";

import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";

import debugLog from "utils/debugLog";

function TaskOptionFeedback(props) {
  const { taskData, selectedValues, type } = props;

  const key = type;
  const optionsKey = `blbTaskOptionType${key}Options`;
  const idKey = `blbTaskType${key}OptionId`;
  const feedbackKey = `blbTaskType${key}OptionFeedback`;

  const optionsArray = taskData.blbGroupTaskOptions[optionsKey];

  useEffect(() => {
    debugLog("Image preload");
    optionsArray.forEach(option => {
      const feedbackArray = !_.isEmpty(option[feedbackKey])
        ? option[feedbackKey]
        : null;

      if (!feedbackArray) return;

      // Only 1 feedback per option is allowed
      const feedback = feedbackArray[0];

      if (feedback.blbImage) {
        const img = new Image();
        img.src = feedback.blbImage.sourceUrl;
      }
    });
  }, [optionsArray, feedbackKey]);

  if (
    !(type === TASK_OPTION_TYPES.IMAGE || type === TASK_OPTION_TYPES.TEXT) ||
    _.isEmpty(selectedValues)
  )
    return null;

  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);

  return (
    <div className="Task-option-feedback">
      {optionsArray.map((option, i) => {
        const feedbackArray = !_.isEmpty(option[feedbackKey])
          ? option[feedbackKey]
          : null;
        const id = option[idKey];
        const isSelected = selectedValuesIds.includes(id);
        if (!feedbackArray || !isSelected) return null;

        // Only 1 feedback per option is allowed
        const feedback = feedbackArray[0];

        return (
          <div key={id} className="Task-option-feedback__inner">
            {feedback.blbRichText ? (
              <div
                className="Task-option-feedback__rich-text"
                dangerouslySetInnerHTML={{ __html: feedback.blbRichText }}
              />
            ) : feedback.blbImage ? (
              <div className="Task-option-feedback__image">
                <img
                  src={feedback.blbImage.sourceUrl}
                  alt={feedback.blbImage.altText}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

TaskOptionFeedback.propTypes = {
  taskData: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  selectedValues: PropTypes.array.isRequired
};

export default TaskOptionFeedback;
