import { createSelector } from "reselect";

const getUser = state => state.oidc.user;
const getAccessStatus = state => state.session.accessStatus;

// This selector combines the user data from
// oidc with extra custom data that is not
// included in the oidc user object.
const getUserWithAccessStatus = createSelector(
  [getUser, getAccessStatus],
  (user, accessStatus) => {
    return user ? { ...user, accessStatus } : null;
  }
);

export default getUserWithAccessStatus;
