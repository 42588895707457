import getUserManager from "userManager";

import debugLog from "utils/debugLog";
import { sendTrackerResponseRollbar } from "utils/sendRollbar";

import {
  GENERAL_UNAUTHORIZED_ERROR_KEY,
  GENERAL_UNAUTHENTICATED_ERROR_KEY
} from "consts";

class ExpectedHttpError extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ExpectedHttpError);
    }

    this.name = "ExpectedHttpError";
  }
}

function fetchErrorHandler(response, reqInput, calleeName, ignore404 = false) {
  const userManager = getUserManager();
  const is401 = response && response.status === 401;
  const is403 = response && response.status === 403;
  const is404 = response && response.status === 404;

  if (!response.ok && !is401 && !is403 && (!is404 || (is404 && !ignore404))) {
    sendTrackerResponseRollbar(
      response,
      reqInput,
      `${calleeName}: Unexpected tracker HTTP error`,
      userManager
    );
  }

  if (is401 && !userManager.settings.monitorSession) {
    // Here, we assume that the request was made
    // with an invalid Authorization Bearer token.
    //
    // Removing the user from the userManager
    // will clear it from the oidc store, then
    // the pages take care of showing the login
    // prompt
    userManager.removeUser().then(() => {
      debugLog("Removed local user because 401 from tracker");
    });
  }

  // This 401 message will probably never be seen if everything
  // works correctly, since the oidc client should remove
  // the user data forcing the login prompt
  if (is401) {
    throw new ExpectedHttpError(GENERAL_UNAUTHENTICATED_ERROR_KEY);
  }

  if (is403) {
    throw new ExpectedHttpError(GENERAL_UNAUTHORIZED_ERROR_KEY);
  }

  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export default fetchErrorHandler;
