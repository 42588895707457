import React from "react";

import "./CourseSectionBreadcrumb.scss";

import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentSection from "hooks/useCurrentSection";
import useWindowSize from "hooks/useWindowSize";

import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";

import CourseSectionProgress from "components/CourseSectionProgress/CourseSectionProgress";
import CourseSectionProgressText from "components/CourseSectionProgressText/CourseSectionProgressText";
import HomeLink from "components/HomeLink/HomeLink";
import { ReactComponent as BackIcon } from "images/icons/back.svg";
import sectionHasTasks from "utils/sectionHasTasks";

function HomeLinkIconComponent() {
  return (
    <div className="Course-section-breadcrumb__backicon">
      <BackIcon />
    </div>
  );
}

function CourseSectionBreadcrumb(props) {
  const { isInNav, subPageName } = props;

  const courseObj = useCurrentCourse();
  const sectionObj = useCurrentSection();

  const windowSize = useWindowSize();

  if (courseObj.loading || sectionObj.loading) return <Loading />;
  if (courseObj.error) return <Error error={courseObj.error} />;
  if (sectionObj.error) return <Error error={sectionObj.error} />;

  const progress = sectionObj.data ? sectionObj.data.progress : null;

  const cls =
    "Course-section-breadcrumb" +
    (isInNav ? " Course-section-breadcrumb--isInNav" : "") +
    (progress ? ` Course-section-breadcrumb--status${progress.status}` : "");

  // Truncate the coursename if we're in small screen and it's too long
  const courseName =
    windowSize.isMobile && courseObj.data.course.titleRendered.length > 31
      ? courseObj.data.course.titleRendered.substring(0, 31) + "..."
      : courseObj.data.course.titleRendered;

  const shouldHideProgressText =
    sectionObj.data && !sectionHasTasks(sectionObj.data);

  return (
    <div className={cls}>
      <HomeLink
        className="Course-section-breadcrumb__coursename"
        linkText={courseName}
        iconComponent={HomeLinkIconComponent}
      />
      <p className="Course-section-breadcrumb__sectionname">
        {subPageName
          ? subPageName
          : sectionObj.data
          ? sectionObj.data.blbCourseSectionTitle
          : null}
      </p>
      {!subPageName && progress ? (
        <div className="Course-section-breadcrumb__progresswrap Course-section-breadcrumb__progresswrap--bar">
          <CourseSectionProgress
            chaptersCount={progress.chaptersCount}
            completedChaptersCount={progress.completedChaptersCount}
            status={progress.status}
            inBreadcrumb={true}
            hideText={shouldHideProgressText}
          />
        </div>
      ) : null}
      {!subPageName && progress && !shouldHideProgressText ? (
        <div className="Course-section-breadcrumb__progresswrap Course-section-breadcrumb__progresswrap--text">
          <CourseSectionProgressText
            chaptersCount={progress.chaptersCount}
            completedChaptersCount={progress.completedChaptersCount}
            status={progress.status}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CourseSectionBreadcrumb;
