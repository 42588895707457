import React from "react";
import { useTranslation } from "react-i18next";

import "./Loading.scss";

function Loading(props) {
  const { t } = useTranslation();
  const { size, isFullPage } = props;
  const _size = size || 1;
  const px = _size * 40 + "px";

  const cls = "Loading" + (isFullPage ? " Loading--isFullPage" : "");
  return (
    <div className={cls} style={{ width: px, height: px }}>
      {t("LOADING")}...
    </div>
  );
}

export default Loading;
