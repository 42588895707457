import React from "react";

import "./ImageMapTooltip.scss";

import stripHtml from "utils/stripHtml";

import { useTranslation } from "react-i18next";


function ImageMapTooltip({ x, y, heading, text, closeFn, canvasWidth }) {
  const { t } = useTranslation();
  const isBig = canvasWidth > 480;
  const isFromTheRight = x / canvasWidth > 0.5;

  const commonStyle = { top: y + "px", /*maxWidth: canvasWidth * 0.45 + "px"*/ };
  const style = isBig
    ? isFromTheRight
      ? { ...commonStyle, right: canvasWidth - x + "px" }
      : { ...commonStyle, left: x + "px" }
    : commonStyle;

  const arrowStyle = !isBig ? { left: x + "px" } : {};

  const cls =
    "Image-map-tooltip" +
    (isFromTheRight ? " Image-map-tooltip--fromRight" : "") +
    (isBig ? " Image-map-tooltip--isBig" : "");
  return (
    <div className={cls} style={style}>
      <div className="Image-map-tooltip__arrow" style={arrowStyle}></div>
      {heading ? (
        <h3 className="Image-map-tooltip__heading">{heading}</h3>
      ) : null}
      <p
        className="Image-map-tooltip__text"
        dangerouslySetInnerHTML={{
          __html: stripHtml(text).replace(/\n/g, "<br />")
        }}
      />{" "}
      <a
        href="#"
        className="Image-map-tooltip__close"
        onClick={e => {
          e.preventDefault();
          closeFn();
        }}
      >
        {t("CLOSE")}
      </a>
    </div>
  );
}

export default ImageMapTooltip;
