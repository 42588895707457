import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "./ChapterResult.scss";

import getCompletedTaskProgressesFromChapterProgress from "utils/getCompletedTaskProgressesFromChapterProgress";

import TaskResult from "components/TaskResult/TaskResult";
import TaskSliderResult from "components/TaskSliderResult/TaskSliderResult";

import { TASK_OPTION_TYPES } from "consts";

function ChapterResult(props) {
  const { chapterProgress, chapterNumber } = props;
  const { t } = useTranslation();

  const completedTaskProgresses = getCompletedTaskProgressesFromChapterProgress(
    chapterProgress
  );

  const cls =
    "Chapter-result" +
    (chapterProgress.success
      ? " Chapter-result--isSuccess"
      : " Chapter-result--isFail");

  // Sort tasks ascending of when
  // they were answered
  const sortedCompletedTaskProgresses = _.sortBy(
    completedTaskProgresses,
    _taskProgress => {
      return _taskProgress.touched;
    }
  );
  return (
    <section className={cls}>
      <header className="Chapter-result__header">
        <span className="Chapter-result__headerdesc">
          {t("COMPLETED_TASKS_ON_CHAPTER_X", { chapterNumber })}:
        </span>
        <h2 className="Chapter-result__chaptertitle">
          {chapterProgress.title}
        </h2>
      </header>
      <div className="Chapter-result__tasks">
        {sortedCompletedTaskProgresses.map((taskProgress, i) => {
          // TODO maybe combine instances of
          // the same task, in case user answered it
          // multiple times?

          const isEven = i % 2 === 0;
          const key = taskProgress.id + i;
          return taskProgress.taskType === TASK_OPTION_TYPES.SLIDER ? (
            <TaskSliderResult
              key={key}
              taskProgress={taskProgress}
              isEven={isEven}
            />
          ) : (
            <TaskResult
              key={key}
              taskProgress={taskProgress}
              isEven={isEven}
            />
          );
        })}
        {!sortedCompletedTaskProgresses.length ? (
          <p className="Chapter-result__notasks">
            {t("THERE_WERE_NO_TASKS_IN_THIS_CHAPTER")}
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default ChapterResult;
