import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";
import { useParams } from "react-router-dom";
import PancakeLoader from "components/PancakeLoader/PancakeLoader";

import "./Course.scss";

import {
  openTaskOverlay,
  closeTaskOverlay,
  closeChapterCompletedOverlay,
  setWaitedLongEnough,
  setPrefaceExpanded,
  setAccessStatus,
  notifyAccessStatusChange
} from "actions";

import { ENTITY_STATUSES, IS_BYPASS_SSO, IS_SCORM, IS_DEMO } from "consts";

import { postSectionStarted, postChapterStarted } from "tracker-api/statements";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useSetupCourse from "hooks/useSetupCourse";
import useCurrentCourse from "hooks/useCurrentCourse";
import useCourseProgress from "hooks/useCourseProgress";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import CourseSectionSelect from "pages/CourseSectionSelect/CourseSectionSelect";
import Chapter from "pages/Chapter/Chapter";
import { getAccessStatus } from "tracker-api/sessions";

import CourseHeader from "components/CourseHeader/CourseHeader";
import LoginBridge from "components/LoginBridge/LoginBridge";
import CourseNotFound from "components/CourseNotFound/CourseNotFound";
import Error from "components/Error/Error";
import MainLoading from "components/MainLoading/MainLoading";
import CourseSectionChapterNav from "components/CourseSectionChapterNav/CourseSectionChapterNav";
import ChapterRedirect from "components/ChapterRedirect/ChapterRedirect";
import CourseDiploma from "components/CourseDiploma/CourseDiploma";
import Preface from "components/Preface/Preface";
import UserHeader from "components/UserHeader/UserHeader";
import LogoutBridge from "components/LogoutBridge/LogoutBridge";

import debugLog from "utils/debugLog";

function Course(props) {
  const sectionToVerify = useSelector(state => state.course.sectionToVerify);

  const selectedSection = useSelector(state => state.course.selectedSection);
  const selectedChapter = useSelector(state => state.course.selectedChapter);
  const selectedTaskGroup = useSelector(
    state => state.course.selectedTaskGroup
  );

  const waitedLongEnough = useSelector(state => state.layout.waitedLongEnough);

  const user = useSelector(getUserWithAccessStatus);

  // To be able to use user within
  // effects without depending on it,
  // to avoid endless loops.
  const userRef = useRef(user);
  useEffect(() => {
    userRef.current = user;
  });

  const prefaceExpanded = useSelector(state => state.layout.prefaceExpanded);
  const availableLanguages = useSelector(
    state => state.layout.availableLanguages
  );

  const userIsLoggingOut = useSelector(state => state.session.userLogOut);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const {
    isLoading: isLoadingSetupCourse,
    isDone: isDoneSetupCourse,
    isParticipant,
    initError
  } = useSetupCourse(sco);

  // Get the course ID from CMS
  const { data, loading, courseHasTasks } = useCurrentCourse();

  // Calculated course progress. TODO Created infinite
  // loop when included in useCurrentCourse for some
  // reason, so keeping it here for now
  const progress = useCourseProgress();

  const dispatch = useDispatch();
  const { courseSlug } = useParams();
  const eduadminId = useCurrentEduadminId();

  // This is just to show off the book animation
  // if loading goes too fast :)
  useEffect(() => {
    setTimeout(() => {
      if (!waitedLongEnough) {
        debugLog("Done waiting for book animation");
        dispatch(setWaitedLongEnough());
      }
    }, 3400); // Book closes one way
  }, [dispatch, waitedLongEnough]);

  // Listen for task group changes and open/close
  // overlay accordingly
  useEffect(() => {
    dispatch(selectedTaskGroup ? openTaskOverlay() : closeTaskOverlay());
  }, [selectedTaskGroup, dispatch]);

  // Make sure that the chapter completed
  // overlay closes when we move on to
  // verifying from last chapter
  useEffect(() => {
    dispatch(closeChapterCompletedOverlay());
  }, [sectionToVerify, dispatch]);

  useEffect(() => {
    // When student enters a section, let tracker know
    const _postSectionStarted = async () => {
      if (userRef.current || IS_BYPASS_SSO) {
        await postSectionStarted(
          selectedSection,
          eduadminId,
          userRef.current,
          sco
        );
        debugLog("postSectionStarted", selectedSection);
        // We don't need to reload state here,
        // because neither tracker or scorm data
        // changes from this call

        // After first section has started,
        // tracker changes hasActiveCourse to true
        dispatch(notifyAccessStatusChange());
      }
    };

    if (selectedSection) {
      _postSectionStarted();
    } else {
      // Scroll to top when section is unset
      window.scrollTo(0, 0);
    }
  }, [selectedSection, eduadminId, sco, dispatch]);

  useEffect(() => {
    // When student enters a chapter, let tracker know
    const _postChapterStarted = async () => {
      if (userRef.current || IS_BYPASS_SSO) {
        await postChapterStarted(
          selectedChapter,
          eduadminId,
          userRef.current,
          sco
        );
        debugLog("postChapterStarted", selectedChapter);
        // We don't need to reload state here,
        // because neither tracker or scorm data
        // changes from this call
      }
    };
    if (selectedChapter) {
      _postChapterStarted();

      // Scroll to top when entering a chapter
      window.scrollTo(0, 0);
    }
  }, [selectedChapter, eduadminId, sco]);

  const accessStatusRefreshKey = useSelector(
    state => state.session.accessStatusRefreshKey
  );

  useEffect(() => {
    const _getAccessStatus = async () => {
      // After the refresh key is
      // incremented in conjuction
      // with certain statements to
      // the tracker as the user
      // progresses, get and set
      // the access status again here
      const accessStatusRes = await getAccessStatus(
        eduadminId,
        userRef.current
      );
      debugLog("refreshed access status", accessStatusRes);
      if (accessStatusRes) {
        dispatch(setAccessStatus(accessStatusRes));
      }
    };

    if (accessStatusRefreshKey && userRef.current && !IS_DEMO) {
      _getAccessStatus();
    }
  }, [accessStatusRefreshKey, eduadminId, dispatch]);

  const isCourseVerifiedSuccess =
    progress && progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS;

  // Once course is completed, hide preface
  // as default
  useEffect(() => {
    if (
      isCourseVerifiedSuccess ||
      (userRef.current &&
        userRef.current.accessStatus &&
        userRef.current.accessStatus.hasCompleteCourse) ||
      (userRef.current &&
        userRef.current.accessStatus &&
        !userRef.current.accessStatus.hasActiveCourse &&
        userRef.current.accessStatus.hasActiveCertificate)
    ) {
      dispatch(setPrefaceExpanded(false));
    }
  }, [isCourseVerifiedSuccess, dispatch]);

  // When student enters a task is reported in Task.js
  // because we must resolve which task is going
  // to be displayed first

  const isFasttrackSlug = availableLanguages
    .map(lang => lang.course_slug)
    .includes(courseSlug);
  const isFasttrack =
    data?.course?.blbGroupCourseEduadminId?.blbCourseIsFasttrack ||
    isFasttrackSlug;
  const isPendingFasttrackCertificate =
    isFasttrack &&
    user &&
    user.accessStatus &&
    user.accessStatus.hasCompleteCourse &&
    !user.accessStatus.hasActiveCertificate;
  const isReceivedFasttrackCertificate =
    isFasttrack &&
    user &&
    user.accessStatus &&
    !user.accessStatus.hasActiveCourse &&
    user.accessStatus.hasActiveCertificate;
  debugLog("availableLanguages", availableLanguages);
  debugLog("isFasttrackSlug", isFasttrackSlug);
  // In fasttrack, poll for changes in
  // access status as long as we're in pending
  // state, since it sometimes takes a while
  // for the certificate to be issued.
  useEffect(() => {
    if (!isPendingFasttrackCertificate) return;

    let timeout = setTimeout(() => {
      dispatch(notifyAccessStatusChange());
    }, 2000);

    return () => clearTimeout(timeout);
    // Dependending on accessStatusRefreshKey
    // will create the polling effect
  }, [isPendingFasttrackCertificate, accessStatusRefreshKey, dispatch]);

  if (loading || isLoadingSetupCourse || !waitedLongEnough)
    return <MainLoading />;

  // CMS request was made, but WP didn't return a course
  if (data && !data.course) {
    return <CourseNotFound isFasttrack={isFasttrack} />;
  }

  // TODO more errorss?
  // Unexpected errors only. If participant id is missing,
  // it will be handled below
  if (initError) {
    return <Error error={initError} />;
  }

  // If setup is done, check that user is logged
  // in and has received a participant id. If not,
  // redirect appropriatly.

  if (!IS_BYPASS_SSO) {
    if (!user && !userIsLoggingOut) {
      return <LoginBridge isFasttrack={isFasttrackSlug} />;
    } else if (!user && userIsLoggingOut) {
      return <LogoutBridge />;
    } else if (user && !isParticipant) {
      return <CourseNotFound isFasttrack={isFasttrack} />;
    }
  }

  // After this point, never render
  // anything dependent on coursestate
  // if setup is not done. Block it just
  // in case React tries to render
  // something "inbetween" loading states.
  if (!isDoneSetupCourse) return null;

  // You can't view a section without a selected
  // chapter, so ChapterRedirect sets the next
  // incompleted for you
  if (selectedSection && !selectedChapter) return <ChapterRedirect />;

  const wrapcls = "Course" + (selectedSection ? ` Course--insection` : "");

  return (
    <div className={wrapcls}>
      <div className="Course__usermenu">
        {!IS_DEMO && isFasttrack ? <UserHeader /> : null}
      </div>
      <div className="Course__headerwrap">
        <CourseHeader isFasttrack={isFasttrack} />
      </div>

      <div className="Course__inner">
        {!selectedSection && prefaceExpanded ? <Preface /> : null}
        {isPendingFasttrackCertificate ? <PancakeLoader /> : null}
        {!selectedChapter &&
        courseHasTasks &&
        ((isFasttrack && isReceivedFasttrackCertificate) ||
          ((!isFasttrack || IS_DEMO) && isCourseVerifiedSuccess)) ? (
          <CourseDiploma isFasttrack={isFasttrack} />
        ) : null}
        {selectedChapter ? (
          <>
            <CourseSectionChapterNav />
            <Chapter key={selectedChapter} />
          </>
        ) : null}
        {!selectedSection ? (
          <CourseSectionSelect
            isCourseVerifiedSuccess={isCourseVerifiedSuccess}
          />
        ) : null}
      </div>
    </div>
  );
}

const Comp = IS_SCORM ? withScorm()(Course) : Course;

export default Comp;
