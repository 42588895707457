import React, { forwardRef } from "react";

import stripHtml from "utils/stripHtml";

import "./ParagraphFromHtml.scss";

const ParagraphFromHtml = forwardRef(function ParagraphFromHtml({ content, className }, ref) {
  const strippedContent = stripHtml(content);

  // Don't allow empty paragraphs
  if (!strippedContent) return null;

  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = content;

  const ps = tempDiv.querySelectorAll("p");

  if (!ps.length) return null;
  return (
    <p
      ref={ref}
      className={className}
      dangerouslySetInnerHTML={{
        __html: ps[0].innerHTML
      }}
    />
  );
})

export default ParagraphFromHtml;
