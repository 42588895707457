import React from "react";

import "./SummaryHeader.scss";

function SummaryHeader(props) {
  const { preText, afterText, headingText, isSuccess } = props;

  const cls =
    "Summary-header" + (isSuccess ? " Summary-header--isSuccess" : "");
  return (
    <header className={cls}>
      <p className="Summary-header__preheading">{preText}</p>
      <h1 className="Summary-header__heading">{headingText}</h1>
      {afterText ? <p className="Summary-header__afterheading">{afterText}</p> : null}
    </header>
  );
}

export default SummaryHeader;
