import React from "react";
import PropTypes from "prop-types";

import "./TaskOption.scss";

function TaskOption(props) {
  const cls =
    "Task-option" +
    (props.isSelected ? " Task-option--isselected" : "") +
    (props.imageSrc ? "  Task-option--hasimg" : "") +
    (props.showAnswer && props.isCorrect && !props.isSelected
      ? " Task-option--show-correct"
      : "") +
    (props.showAnswer && props.isCorrect && props.isSelected ? " Task-option--correct" : "") +
    (props.isSelected && !props.isCorrect && props.showAnswer ? " Task-option--incorrect" : "") +
    (props.dc ? " Task-option--dc" : "");
  return (
    <label className={cls}>
      {props.imageSrc ? (
        <div className="Task-option__image">
          <img src={props.imageSrc} alt={props.imageAlt ? props.imageAlt : ""} />
        </div>
      ) : null}
      <span className="Task-option__text">{props.label}</span>
      <input
        className="Task-option__element"
        type={
          props.isMultiple || props.allowDeselectOfSingleAnswer ? "checkbox" : "radio"
        }
        value={props.value}
        onChange={props.selectFn.bind(null, props.value)}
        checked={props.isSelected}
        disabled={props.isDisabled}
      />
    </label>
  );
}

TaskOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  selectFn: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  dc: PropTypes.bool, // dc = debug correct
  isCorrect: PropTypes.bool
};

export default TaskOption;
