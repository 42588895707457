import React from "react";
import { useTranslation } from "react-i18next";
import useCurrentChapter from "hooks/useCurrentChapter";

import "./TaskGroupProgress.scss";

function TaskGroupProgress() {
  const { t } = useTranslation();
  const currentChapter = useCurrentChapter();
  const totalTasks = currentChapter.data.progress.taskGroups.length;
  const completedTasks = (currentChapter.data.progress.taskGroups.filter(task => task.completed === true).length);
  const progressPercent = (completedTasks / totalTasks) * 100;

  return (
    <div className="Task-group-progress">
      <div className="Task-group-progress__progressbar">
        <div
          className="Task-group-progress__progressbar-inner"
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
      <p className="Task-group-progress__progresstext">
        { t("ANSWERED") } {" "}
        <span>{ t("X_OF_X", { completedChaptersCount: completedTasks, chaptersCount: totalTasks })}</span>
      </p>
    </div>
  );
}

export default TaskGroupProgress;
