import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import "./ParticipantSearch.scss";

import ButtonLoading from "components/ButtonLoading/ButtonLoading";
import ParticipantSearchResult from "components/ParticipantSearchResult/ParticipantSearchResult";

import {
  searchParticipantByEmail,
  searchParticipantByPn
} from "tracker-api/analytics";

import { IS_BYPASS_SSO } from "consts";

import {
  setEmailInput,
  setPnInput,
  setSearchResults,
  setActiveSearchTab
} from "actions";

import LoginBridge from "components/LoginBridge/LoginBridge";

function ParticipantSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabs = [
    {
      id: "pn",
      label: t("analytics.PERSONAL_NUMBER")
    },
    {
      id: "email",
      label: t("analytics.EMAIL")
    }
  ];
  const user = useSelector(state => state.oidc.user);
  const emailInput = useSelector(state => state.analytics.emailInput);
  const pnInput = useSelector(state => state.analytics.pnInput);
  const searchResults = useSelector(state => state.analytics.searchResults);
  const activeSearchTab = useSelector(state => state.analytics.activeSearchTab);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const search = useCallback(() => {
    const _searchParticipant = async () => {
      setIsSubmitLoading(true);
      let getRes = [];
      try {
        getRes =
          activeSearchTab === "email"
            ? await searchParticipantByEmail(emailInput, user)
            : activeSearchTab === "pn"
            ? await searchParticipantByPn(pnInput, user)
            : [];
      } catch (e) {
        setSubmitError(t(e.message));
      }

      dispatch(setSearchResults(getRes));
      setIsSubmitLoading(false);
    };
    _searchParticipant();
  }, [
    activeSearchTab,
    emailInput,
    pnInput,
    user,
    dispatch,
    setIsSubmitLoading,
    setSubmitError,
    t
  ]);

  if (!user && !IS_BYPASS_SSO) return <LoginBridge />;

  return (
    <article className="Participant-search">
      <header className="Participant-search__header">
        <h1 className="Participant-search__mainheading">
          {t("analytics.PARTICIPANT_SEARCH")}
        </h1>
        <p className="Participant-search__tagline">
          {t("analytics.FOR_BUC_ONLINE_COURSES")}
        </p>
      </header>
      <ul className="Participant-search__tabs">
        {tabs.map(tab => {
          const cls =
            "Participant-search__tab" +
            (tab.id === activeSearchTab
              ? " Participant-search__tab--active"
              : "");
          return (
            <li
              key={tab.id}
              className={cls}
              onClick={() => dispatch(setActiveSearchTab(tab.id))}
            >
              {tab.label}
            </li>
          );
        })}
      </ul>
      <div className="Participant-search__form">
        <form
          onSubmit={e => {
            e.preventDefault();
            search();
          }}
        >
          {activeSearchTab === "pn" ? (
            <div className="Participant-search__formgroup">
              <label
                className="Participant-search__label"
                htmlFor="participantsearchpninput"
              >
                <strong className="Bold">{t("analytics.FORMAT")}:</strong>{" "}
                <span>YYYYMMDD-XXXX</span>
              </label>
              <input
                id="participantsearchpninput"
                type="text"
                value={pnInput}
                className="Participant-search__input"
                onChange={e => dispatch(setPnInput(e.target.value))}
              />
            </div>
          ) : activeSearchTab === "email" ? (
            <div className="Participant-search__formgroup">
              <label
                className="Participant-search__label"
                htmlFor="participantsearchemailinput"
              >
                <span>{t("analytics.EMAIL_ADDRESS")}</span>
              </label>
              <input
                id="participantsearchemailinput"
                type="email"
                value={emailInput}
                className="Participant-search__input"
                onChange={e => dispatch(setEmailInput(e.target.value))}
              />
            </div>
          ) : null}
          <button
            type="submit"
            disabled={
              (activeSearchTab === "pn" && !pnInput) ||
              (activeSearchTab === "email" && !emailInput) ||
              isSubmitLoading
            }
            className="Participant-search__submitbtn"
          >
            {t("analytics.SEARCH_PARTICIPANT")}
            {isSubmitLoading ? <ButtonLoading /> : null}
          </button>
        </form>
      </div>
      {searchResults ? (
        <div className="Participant-search__results">
          {searchResults.map(result => (
            <ParticipantSearchResult key={result.ssoId} result={result} />
          ))}

          {_.isEmpty(searchResults) && !submitError ? (
            <p className="Participant-search__noresults">
              {t("analytics.NO_RESULTS")}
            </p>
          ) : null}
          {submitError ? (
            <p className="Participant-search__error">{submitError}</p>
          ) : null}
        </div>
      ) : null}
    </article>
  );
}

export default ParticipantSearch;
