import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./VimeoVideo.scss";

import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentChapter from "hooks/useCurrentChapter";

import getCurrentLangSlug from "utils/getCurrentLangSlug";

import { BYGGFORETAGEN_C_ACTION_PRIMARY, BUC_C_ACTION_PRIMARY } from "consts";

function VimeoVideo(props) {
  const { vimeoUrl } = props;
  const courseObj = useCurrentCourse();
  const chapterObj = useCurrentChapter();
  const { t } = useTranslation();
  const theme = useSelector(state => state.layout.theme);
  if (!vimeoUrl) return null;

  const noTrailingSlash = vimeoUrl.replace(/\/$/, "");
  const id = noTrailingSlash.substring(noTrailingSlash.lastIndexOf("/") + 1);

  const langSlug = courseObj.data ? getCurrentLangSlug(courseObj.data) : null;

  const color =
    theme === "byggforetagen" || theme === "arrangorsutbildningar"
      ? BYGGFORETAGEN_C_ACTION_PRIMARY
      : BUC_C_ACTION_PRIMARY;

  let src = `https://player.vimeo.com/video/${id}?color=${color}&byline=false&title=false`;

  src = src + (langSlug ? `&texttrack=${langSlug}` : "");

  const title = chapterObj.data
    ? t("VIDEO_ABOUT_X", { chapterTitle: chapterObj.data.titleRendered })
    : t("VIDEO");
  return (
    <div className="Vimeo-video">
      <iframe
        src={src}
        title={title}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  );
}

VimeoVideo.propTypes = {
  vimeoUrl: PropTypes.string.isRequired
};

export default VimeoVideo;
