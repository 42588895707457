import React from "react";
import { useTranslation } from "react-i18next";

import "./ChapterCompletedOverlay.scss";

import ChapterIntersection from "components/ChapterIntersection/ChapterIntersection";

function ChapterCompletedOverlay() {
  const { t } = useTranslation();
  return (
    <article className="Chapter-completed-overlay">
      <h2 className="Chapter-completed-overlay__heading">{t("THANK_YOU_FOR_THE_ANSWER")}!</h2>
      <div className="Chapter-completed-overlay__content">
        <ChapterIntersection isInOverlay={true} />
      </div>
    </article>
  );
}

export default ChapterCompletedOverlay;
