import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";

// This is designed for types Text or Image only
function getTaskSelectFn(
  selectedValues,
  selectFn,
  allowDeselectOfSingleAnswer,
  isMultiAnswerTask
) {
  const selectedValuesIds = getTaskSelectedValuesIds(selectedValues);
  return isMultiAnswerTask
    ? optionId => {
        // If it's a multianswer task,
        // return a function that adds the
        // selected if it's not already selected.
        // Otherwise, remove that value from selected array.
        const newArray = selectedValuesIds.includes(optionId)
          ? selectedValues.filter(_val => {
              return _val.optionId !== optionId;
            })
          : [...selectedValues, { optionId }];
        selectFn(newArray);
      }
    : optionId => {
        // If it's a single answer task, just replace the value.
        // If deselect is allowed, pass empty array if it's the already
        // included value that's selected.
        if (allowDeselectOfSingleAnswer) {
          selectFn(optionId === selectedValuesIds[0] ? [] : [{ optionId }]);
        } else {
          selectFn([{ optionId }]);
        }
      };
}

export default getTaskSelectFn;
