import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import "./TaskResult.scss";

import { TASK_OPTION_TYPES } from "consts";

import debugLog from "utils/debugLog";

const getOptionWithMatchText = (option, idProp, taskProgress) => {
  const matchId = option.answer[idProp];
  const match = taskProgress.matches.find(match => match.id === matchId);
  if (!match) return option.content;

  return `${option.content} ←→ ${match.content}`;
};

function TaskResult(props) {
  const { taskProgress, isEven } = props;
  const { t } = useTranslation();

  debugLog("taskProgress", taskProgress);

  const type = taskProgress.taskType;

  const isReflective =
    type !== TASK_OPTION_TYPES.ORDER &&
    taskProgress.options.filter(_option => {
      return _option.correct;
    }).length === 0;

  // If not a success, show all the options if
  // it's an order/match task, and all the selected ones
  // for other task types. A correct answer can show up
  // here, in the cases user needs all correct answers
  // to succeed.
  const failAnswersContents = _(taskProgress.options)
    .filter(_option => {
      return (
        !taskProgress.success &&
        (type === TASK_OPTION_TYPES.ORDER ||
          type === TASK_OPTION_TYPES.MATCH ||
          _option.answer.selected)
      );
    })
    .sortBy((_option, i) => {
      return type === TASK_OPTION_TYPES.ORDER ? _option.answer.index : i;
    })
    .map(_option => {
      if (type === TASK_OPTION_TYPES.MATCH) {
        return getOptionWithMatchText(_option, "match", taskProgress);
      }
      return _option.content;
    })
    .value();

  // successAnswersContents is always shown, either with
  // the purpose of confirming the success or showing
  // the right answers when not success.
  //
  // Here, we're covering a few cases:
  // 1. If it's an ORDER or MATCH task, we always want to show all options regardless
  // 2. If the user didn't succeed, we want to show the correct answers
  // 3. If the user did succeed, we want to show all the selected answers. We
  //    can't just rely on correct prop here, since some tasks allow wrong answers.
  const successAnswersContents = _(taskProgress.options)
    .filter(_option => {
      return (
        type === TASK_OPTION_TYPES.ORDER ||
        type === TASK_OPTION_TYPES.MATCH ||
        (!taskProgress.success && _option.correct) ||
        (taskProgress.success && _option.answer.selected)
      );
    })
    .sortBy((_option, i) => {
      return type === TASK_OPTION_TYPES.ORDER ? _option.answer.correctIndex : i;
    })
    .map(_option => {
      if (type === TASK_OPTION_TYPES.MATCH) {
        return getOptionWithMatchText(_option, "correctMatch", taskProgress);
      }
      return _option.content;
    })
    .value();

  const stepAnswerContents = _(taskProgress.sequentSteps)
    .map(obj => {
      return {
        title: obj.title,
        options: _(obj.options)
          .filter(_option => {
            return (
              _option.answer.selected ||
              typeof _option.answer.grade !== "undefined"
            );
          })
          .map(_option => {
            return (
              _option.content +
              (_option.answer.grade
                ? ` - ${_option.answer.grade}/${_option.answer.max}`
                : "")
            );
          })
          .value()
      };
    })
    .value();

  const cls =
    "Task-result" +
    (isEven ? " Task-result--isEven" : " Task-result--isOdd") +
    (taskProgress.success ? " Task-result--isSuccess" : " Task-result--isFail");

  const List = type === TASK_OPTION_TYPES.ORDER ? "ol" : "ul";

  // not so important TODO, maybe the title
  // should be returned by the tracker if it
  // changes in WP?

  return (
    <div className={cls}>
      <h3 className="Task-result__title"># {taskProgress.title}</h3>

      {/* TODO pluralization of headings */}
      {failAnswersContents.length ? (
        <div className="Task-result__failanswers">
          <h4 className="Task-result__subheading">
            {t("YOUR_ANSWERS_THAT_WERE_WRONG")}:
          </h4>
          <List className="Task-result__list">
            {failAnswersContents.map((content, i) => {
              return (
                <li key={content + i} className="Task-result__listitem">
                  {content}
                </li>
              );
            })}
          </List>
        </div>
      ) : null}

      <div className="Task-result__successanswers">
        <h4 className="Task-result__subheading">
          {taskProgress.success
            ? isReflective
              ? t("YOUR_ANSWERS")
              : t("YOUR_ANSWERS_THAT_WERE_CORRECT")
            : t("CORRECT_ANSWER_WAS")}
          :
        </h4>
        <List className="Task-result__list">
          {successAnswersContents.map((content, i) => {
            return (
              <li key={content + i} className="Task-result__listitem">
                {content}
              </li>
            );
          })}
        </List>
      </div>
      {stepAnswerContents.map((stepObj, i) => {
        return (
          <div className="Task-result__stepresult" key={i}>
            <h3 className="Task-result__title">
              # {t("RELATED_TO_ABOVE")}
              {": "}
              {stepObj.title}
            </h3>
            <div className="Task-result__stepanswers">
              <h4 className="Task-result__subheading">{t("YOUR_ANSWERS")}:</h4>
              <List className="Task-result__list">
                {stepObj.options.map((content, i) => {
                  return (
                    <li key={content + i} className="Task-result__listitem">
                      {content}
                    </li>
                  );
                })}
              </List>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TaskResult;
