import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CallbackComponent } from "redux-oidc";
import { useCookies } from "react-cookie";
import getUserManager from "userManager";

import { REDIRECT_TO_COOKIE_NAME } from "consts";

import "./Callback.scss";

import debugLog from "utils/debugLog";

function Callback() {
  const userManager = getUserManager();
  const { t } = useTranslation();
  const [shouldSuccessCallback, setShouldSuccessCallback] = useState(false);
  const [shouldErrorCallback, setShouldErrorCallback] = useState(false);
  const [cookies, setCookie] = useCookies([REDIRECT_TO_COOKIE_NAME]);
  const courseSlug = cookies[REDIRECT_TO_COOKIE_NAME];

  if (shouldSuccessCallback) {
    return <Redirect to={courseSlug ? `/course/${courseSlug}` : "/"} />;
  } else if (shouldErrorCallback) {
    return <Redirect to={"/"} />;
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={user => {
        debugLog("Success login", user);
        setShouldSuccessCallback(true);
      }}
      errorCallback={error => {
        console.error(error);
        setShouldErrorCallback(true);
      }}
    >
      <div className="Callback">
        <h1 className="Callback__heading">{t("REDIRECTING")}...</h1>
      </div>
    </CallbackComponent>
  );
}

export default Callback;
