import React from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemTypes = {
  CARD: "card"
};

function TaskOrderCard({
  baseClass,
  taskOption,
  position,
  correctAnswer,
  IS_DEBUG,
  showAnswer,
  moveCard,
  onDrop
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item, monitor) {
      onDrop();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = position;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: taskOption.blbTaskTypeOrderOptionId, position };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: () => {
      return !showAnswer;
    }
  });

  const opacity = isDragging ? 0 : 1;
  const isCorrect =
    parseInt(taskOption.blbTaskTypeOrderOptionCorrectPosition) === position + 1;

  drag(drop(ref));
  baseClass = `${baseClass}__listitem`;
  const answerCls =
    `${baseClass}` +
    (showAnswer
      ? parseInt(taskOption.blbTaskTypeOrderOptionCorrectPosition) === position + 1
        ? " --correct"
        : " --incorrect" //
      : "");

  return (
    <li
      className={answerCls}
      ref={ref}
      style={{ opacity: opacity }}
      data-handler-id={handlerId}
    >
      <p className={`${baseClass}-text`}>
        {taskOption.blbTaskTypeOrderOptionLabel}
        {showAnswer && !isCorrect ? (
          <span className="right-answer">{` (Rätt svar: ${correctAnswer})`}</span>
        ) : null}
      </p>
      {IS_DEBUG || showAnswer ? (
        <span className={`${baseClass}-debug`}>
          {taskOption.blbTaskTypeOrderOptionCorrectPosition}
        </span>
      ) : null}
    </li>
  );
}

export default TaskOrderCard;
