import { useSelector } from "react-redux";
import useCurrentSection from "hooks/useCurrentSection";
import useCurrentChapter from "hooks/useCurrentChapter";
import getTaskGroupStatus from "utils/getTaskGroupStatus";

function useTaskGroupProgress(taskGroup) {
  const courseState = useSelector(state => state.course.courseState);
  const section = useCurrentSection();
  const chapter = useCurrentChapter();

  if (!courseState || !taskGroup || !section.data || !chapter.data) return null;

  const sectionState = courseState.sections.find(_section => {
    return _section.id === section.data.blbCourseSectionId;
  });

  if (!sectionState) return null;

  const chapterState = sectionState.chapters.find(_chapter => {
    return _chapter.id === chapter.data.blbChapterId;
  });

  if (!chapterState) return null;

  const taskGroupState = chapterState.taskGroups.find(_taskGroup => {
    return _taskGroup.id === taskGroup.blbChapterTaskGroupId;
  });

  if (!taskGroupState) return null;

  return { ...taskGroupState, status: getTaskGroupStatus(taskGroupState) };
}

export default useTaskGroupProgress;
