// This stores user/session-related data
// that is not part of oidc

const analytics = (
  state = {
    sectionProgress: null,
    pnInput: "",
    emailInput: "",
    searchResults: null,
    activeSearchTab: "pn"
  },
  action
) => {
  switch (action.type) {
    case "SET_SECTION_DATA_TO_ANALYTICS_RESULT_VIEW":
      return {
        ...state,
        sectionProgress: action.data
      };
    case "SET_PN_INPUT":
      return {
        ...state,
        pnInput: action.data
      };
    case "SET_EMAIL_INPUT":
      return {
        ...state,
        emailInput: action.data
      };
    case "SET_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.data
      };
    case "SET_ACTIVE_SEARCH_TAB":
      return {
        ...state,
        activeSearchTab: action.data
      };

    default:
      return state;
  }
};

export default analytics;
