import { useSelector } from "react-redux";
import _ from "lodash";
import useCurrentChapter from "hooks/useCurrentChapter";
import useTaskGroupProgress from "hooks/useTaskGroupProgress";

function useNextTaskGroup() {
  const selectedTaskGroup = useSelector(
    state => state.course.selectedTaskGroup
  );

  const { loading, error, data } = useCurrentChapter();

  const taskGroupsExist =
    data &&
    data.blbGroupChapterTasks &&
    data.blbGroupChapterTasks.blbChapterTasks &&
    data.blbGroupChapterTasks.blbChapterTasks.length;

  // Check the progress result for the first task group
  // that's not already completed
  const firstIncompleteTaskGroupProgress = data
    ? _.find(data.progress.taskGroups, _taskGroup => {
        // If a taskgroup is currently selected,
        // we don't want that to be the next task
        // since the next task is resolved before
        // the current is marked as completed

        return selectedTaskGroup
          ? !_taskGroup.completed && selectedTaskGroup !== _taskGroup.id
          : !_taskGroup.completed;
      })
    : null;

  // Get the index of the "content" for the
  // task group, by using the id we got from progress.
  // Index should be the same but you never know.
  // If there is no firstIncompleteTaskGroupProgress, it
  // probably means we're on the last task.
  const firstIncompleteTaskGroupIndex =
    taskGroupsExist && firstIncompleteTaskGroupProgress
      ? _.findIndex(data.blbGroupChapterTasks.blbChapterTasks, _taskGroup => {
          return (
            _taskGroup.blbChapterTaskGroupId ===
            firstIncompleteTaskGroupProgress.id
          );
        })
      : -1;

  // Get the task group by index
  const firstIncompleteTaskGroup =
    firstIncompleteTaskGroupIndex !== -1
      ? data.blbGroupChapterTasks.blbChapterTasks[firstIncompleteTaskGroupIndex]
      : null;

  const progress = useTaskGroupProgress(firstIncompleteTaskGroup);
  // If we found a task group, return that. If not, just return null,
  // which consuming components should interpret as moving away
  // from that chapter
  return {
    loading,
    error,
    data: firstIncompleteTaskGroup && progress
      ? {
          ...firstIncompleteTaskGroup,
          taskNumber: firstIncompleteTaskGroupIndex + 1,
          progress
        }
      : null
  };
}

export default useNextTaskGroup;
