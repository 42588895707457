import _ from "lodash";

function getCompletedTaskProgressesFromChapterProgress(chapter) {
  return _(chapter.taskGroups)
    .map((taskGroup) => {
      return taskGroup.tasks;
    })
    .flatten()
    .filter((task) => {
      return task.completed;
    })
    .value();
}
export default getCompletedTaskProgressesFromChapterProgress;
