import _ from "lodash";

function sectionHasTasks(section) {
  return (
    section.blbCourseSectionChapterPosts.filter(chapter => {
      return (
        _.isArray(chapter.blbGroupChapterTasks.blbChapterTasks) &&
        chapter.blbGroupChapterTasks.blbChapterTasks.length > 0
      );
    }).length > 0
  );
}

export default sectionHasTasks;
