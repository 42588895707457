import React from "react";

import "./RichListItem.scss";

function RichListItem(props) {
  const { image, body, imageAlt, imageSize } = props;

  const cls =
    "Rich-list-item" +
    (imageSize === "large" ? " Rich-list-item--largeImg" : "");
    
  return (
    <div className={cls}>
      <div className="Rich-list-item__image">
        <img src={image} alt={imageAlt} />
      </div>
      <p
        className="Rich-list-item__body"
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  );
}

export default RichListItem;
