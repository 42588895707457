import { useSelector } from "react-redux";
import getSectionStatus from "utils/getSectionStatus";

function useSectionProgress(section) {
  const courseState = useSelector(state => state.course.courseState);

  if (!courseState || !section) return null;

  const sectionState = courseState.sections.find(_section => {
    return _section.id === section.blbCourseSectionId;
  });

  if (!sectionState) return null;

  return { ...sectionState, status: getSectionStatus(sectionState) };
}

export default useSectionProgress;
