import React from "react";

import useCurrentCourse from "hooks/useCurrentCourse";
import _ from "lodash";

import "./CourseSectionSelect.scss";

import CourseSectionSelectItem from "components/CourseSectionSelectItem/CourseSectionSelectItem";

import { GQL_SECTIONS_NEST } from "consts";

import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";

function CourseSectionSelect(props) {
  const { isCourseVerifiedSuccess } = props;
  const { loading, error, data } = useCurrentCourse();

  if (loading) return <Loading />;
  if (error || !data || !data.course) return <Error error={error} />;

  const sections = _.get(data, GQL_SECTIONS_NEST);

  return (
    <div className="Course-section-select">
      <ul className="Course-section-select__list">
        {sections.map((section) => (
          <CourseSectionSelectItem
            key={section.blbCourseSectionId}
            section={section}
            isCourseVerifiedSuccess={isCourseVerifiedSuccess}
          />
        ))}
      </ul>
    </div>
  );
}

export default CourseSectionSelect;
