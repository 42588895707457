function getTrackerReqHeaders(user) {
  let headers = { "Content-Type": "application/json" };

  // Would be great if auth was enforced by tracker in
  // prod only for mock purposes. TODO discuss with Micke.
  return user && user.access_token
    ? { ...headers, Authorization: "Bearer " + user.access_token }
    : headers;
}

export default getTrackerReqHeaders;
