import React from "react";

import { TASK_OPTION_TYPES } from "consts";

import TaskInteractionText from "components/TaskInteractionText/TaskInteractionText";
import TaskInteractionImage from "components/TaskInteractionImage/TaskInteractionImage";
import TaskInteractionOrder from "components/TaskInteractionOrder/TaskInteractionOrder";
import TaskInteractionSlider from "components/TaskInteractionSlider/TaskInteractionSlider";
import TaskInteractionGradingStep from "components/TaskInteractionGradingStep/TaskInteractionGradingStep";
import TaskInteractionMatch from "components/TaskInteractionMatch/TaskInteractionMatch";

const getFauxObjForStep = (stepOptions, type) => {
  let obj = {
    blbGroupTaskOptions: {
      blbTaskOptionType: type
    }
  };
  obj.blbGroupTaskOptions[`blbTaskOptionType${type}Options`] = stepOptions;
  return obj;
};

const shouldShowStepGrading = (
  currentTaskStep,
  taskData,
  stepOptionsOverride
) => {
  if (currentTaskStep < 0) return false;

  const step = taskData.blbGroupTaskSteps.blbTaskSteps[currentTaskStep];
  return !!stepOptionsOverride && !!step && step.blbTaskStepShowGrade;
};

function TaskInteractionWrapper({
  taskData,
  selectedValues,
  selectFn,
  type,
  stepOptionsOverride,
  isInFacts,
  currentTaskStep,
  showAnswer,
  isDisabled
}) {
  switch (type) {
    case TASK_OPTION_TYPES.TEXT:
      return shouldShowStepGrading(
        currentTaskStep,
        taskData,
        stepOptionsOverride
      ) ? (
        <TaskInteractionGradingStep
          taskData={
            stepOptionsOverride
              ? getFauxObjForStep(stepOptionsOverride, TASK_OPTION_TYPES.TEXT)
              : taskData
          }
          selectedValues={selectedValues}
          selectFn={selectFn}
        />
      ) : (
        <TaskInteractionText
          taskData={
            stepOptionsOverride
              ? getFauxObjForStep(stepOptionsOverride, TASK_OPTION_TYPES.TEXT)
              : taskData
          }
          selectedValues={selectedValues}
          selectFn={selectFn}
          isInFacts={isInFacts}
          showAnswer={showAnswer}
          isDisabled={isDisabled}
        />
      );
    case TASK_OPTION_TYPES.IMAGE:
      return shouldShowStepGrading(
        currentTaskStep,
        taskData,
        stepOptionsOverride
      ) ? (
        <TaskInteractionGradingStep
          taskData={
            stepOptionsOverride
              ? getFauxObjForStep(stepOptionsOverride, TASK_OPTION_TYPES.IMAGE)
              : taskData
          }
          selectedValues={selectedValues}
          selectFn={selectFn}
        />
      ) : (
        <TaskInteractionImage
          taskData={
            stepOptionsOverride
              ? getFauxObjForStep(stepOptionsOverride, TASK_OPTION_TYPES.IMAGE)
              : taskData
          }
          selectedValues={selectedValues}
          selectFn={selectFn}
          isInFacts={isInFacts}
          showAnswer={showAnswer}
          isDisabled={isDisabled}
        />
      );
    case TASK_OPTION_TYPES.ORDER:
      return (
        <TaskInteractionOrder
          taskData={taskData}
          selectedValues={selectedValues}
          selectFn={selectFn}
          isInFacts={isInFacts}
          showAnswer={showAnswer}
        />
      );
    case TASK_OPTION_TYPES.SLIDER:
      return (
        <TaskInteractionSlider
          taskData={taskData}
          selectedValues={selectedValues}
          selectFn={selectFn}
          isInFacts={isInFacts}
        />
      );
    case TASK_OPTION_TYPES.MATCH:
      return (
        <TaskInteractionMatch
          taskData={taskData}
          selectedValues={selectedValues}
          selectFn={selectFn}
          showAnswer={showAnswer}
          isDisabled={isDisabled}
        />
      );
    default:
      return null;
  }
}

export default TaskInteractionWrapper;
