import React, { useEffect, useState } from "react";

import "./TaskInteractionSlider.scss";

import SliderInput from "components/SliderInput/SliderInput";

import { MIN_SLIDER_DEFAULT_VALUE, MAX_SLIDER_DEFAULT_VALUE } from "consts";

const DEFAULT_SLIDER_VALUE = 50;

function TaskInteractionSlider(props) {
  const { taskData, selectFn } = props;
  const [sliderValue, setSliderValue] = useState(DEFAULT_SLIDER_VALUE);
  const [isDirty, setIsDirty] = useState(false);

  // Only 1 slider per task is allowed in the CMS
  // at the moment, but it still comes as an array
  const option = taskData.blbGroupTaskOptions.blbTaskOptionTypeSliderOptions[0];

  useEffect(() => {
    if (!isDirty || !option) return;
    selectFn([
      { optionId: option.blbTaskTypeSliderOptionId, extraData: sliderValue }
    ]);
  }, [sliderValue, selectFn, isDirty, option]);

  const onChange = e => {
    const val = Number(e.target.value);
    if (!isDirty) setIsDirty(true);
    setSliderValue(val);
  };

  return (
    <div className="Task-interaction-slider">
      {option.blbTaskTypeSliderOptionImage ? (
        <div className="Task-interaction-slider__image">
          <img
            src={option.blbTaskTypeSliderOptionImage.sourceUrl}
            alt={
              option.blbTaskTypeSliderOptionImage.altText
                ? option.blbTaskTypeSliderOptionImage.altText
                : ""
            }
          />
        </div>
      ) : null}
      {option.blbTaskTypeSliderOptionLabel ? (
        <p className="Task-interaction-slider__text">
          {option.blbTaskTypeSliderOptionLabel}
        </p>
      ) : null}
      <div className="Task-interaction-slider__element">
        <SliderInput
          value={sliderValue}
          onChange={onChange}
          min={MIN_SLIDER_DEFAULT_VALUE}
          max={MAX_SLIDER_DEFAULT_VALUE}
          labelLeft={
            option.blbTaskTypeSliderSettings.blbTaskTypeSliderSettingLabelLeft
          }
          labelRight={
            option.blbTaskTypeSliderSettings.blbTaskTypeSliderSettingLabelRight
          }
        />
      </div>
    </div>
  );
}

export default TaskInteractionSlider;
