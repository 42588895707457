import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./CourseSectionProgress.scss";

const renderStatusText = (props, t) => {
  const cls = "Course-section-progress__progresstext";
  const { completedChaptersCount, chaptersCount } = props;
  switch (props.status) {
    case 1:
      return (
        <p className={cls}>
          <span>{t("NO_TASKS_COMPLETED_FOR_ANY_CHAPTER")}</span>
        </p>
      );
    case 2:
    case 3:
      return (
        <p className={cls}>
          <span className="Course-section-progress__highlight">
            {t("X_OF_X", { completedChaptersCount, chaptersCount })}
          </span>{" "}
          {t("CHAPTERS_HAVE_COMPLETED_TASKS")}.
        </p>
      );

    case 4:
      return null;
    case 5:
      return <p className={cls}>{t("EVERYTHING_DONE")}!</p>;
    default:
      return null;
  }
};

function CourseSectionProgress(props) {
  const { t } = useTranslation();
  const progressPercent =
    (props.completedChaptersCount / props.chaptersCount) * 100;

  const cls =
    "Course-section-progress" +
    (props.status ? ` Course-section-progress--status${props.status}` : "") +
    (props.inBreadcrumb ? " Course-section-progress--inBreadcrumb" : "");
  return (
    <div className={cls}>
      <div className="Course-section-progress__progressbar">
        <div
          className="Course-section-progress__progressbar-inner"
          style={{ width: progressPercent + "%" }}
        ></div>
      </div>

      {props.hideText ? null : renderStatusText(props, t)}
    </div>
  );
}

CourseSectionProgress.propTypes = {
  chaptersCount: PropTypes.number.isRequired,
  completedChaptersCount: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  onDark: PropTypes.bool
};

export default CourseSectionProgress;
