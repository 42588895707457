import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import TaskMatchOptionCard from "components/TaskMatchOptionCard/TaskMatchOptionCard";
import TaskMatchOptionDroptarget from "components/TaskMatchOptionDroptarget/TaskMatchOptionDroptarget";

import "./TaskInteractionMatch.scss";

const swap = (array, indexA, indexB) => {
  const arr = [...array];
  var temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};

function TaskInteractionMatch(props) {
  const { taskData, selectFn, showAnswer, isDisabled } = props;
  const refDrop = useRef(null);

  const statements = taskData.blbGroupTaskOptions.blbTaskOptionTypeMatchOptions;
  const matchoptions = taskData.blbGroupTaskOptions.blbTaskMatchoptions;

  const [matchOptions, setMatchOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isAllPuzzled, setIsAllPuzzled] = useState(false); // is the puzzle done

  //check if everything is puzzled
  useEffect(() => {
    setIsAllPuzzled(answers.filter(card => card?.puzzled).length === statements.length);
  }, [answers, statements]);

  // when averything is puzzled collect answer
  useEffect(() => {
    if (isAllPuzzled) {
      selectFn(
        answers.map(({ blbTaskMatchoptionId, blbTaskMatchoptionLabel }, i) => {
          return {
            optionId: blbTaskMatchoptionId,
            optionLabel: blbTaskMatchoptionLabel,
            extraData: statements[i].blbTaskTypeMatchOptionCorrectMatchoptionId
          };
        })
      );
    } else {
      selectFn([]);
    }
  }, [answers, statements, selectFn, isAllPuzzled]);

  useEffect(() => {
    setMatchOptions(
      taskData.blbGroupTaskOptions.blbTaskMatchoptions.map(opt => {
        return { ...opt, puzzled: false, correct: false, currentPosition: null };
      })
    );
    setAnswers(
      taskData.blbGroupTaskOptions.blbTaskMatchoptions.map(opt => {
        return null;
      })
    );
  }, [taskData]);

  const moveCard = (originalIndex, targetIndex, item) => {
    const newMatchCards = [...answers];

    if (item.origin === "option") {
      const dropItem = matchOptions[originalIndex];
      if (newMatchCards[targetIndex]) {
        const targetItem = newMatchCards[targetIndex];
        matchOptions[originalIndex] = {
          ...targetItem,
          puzzled: false
        };
        setMatchOptions(matchOptions);
        newMatchCards[targetIndex] = {
          ...dropItem,
          puzzled: true
        };
      } else {
        matchOptions[originalIndex] = null;
        setMatchOptions(matchOptions);
        newMatchCards[targetIndex] = {
          ...dropItem,
          puzzled: true
        };
      }

      setAnswers(newMatchCards);
    } else {
      const dropItem = answers[originalIndex];

      if (newMatchCards[targetIndex]) {
        const swapped = swap(answers, originalIndex, targetIndex);
        setAnswers(swapped);
      } else {
        newMatchCards[targetIndex] = dropItem;
        newMatchCards[originalIndex] = null;
        setAnswers(newMatchCards);
      }
    }
  };
  console.log("taskData: ", taskData);
  return (
    <DndProvider options={HTML5toTouch}>
      <div className="Task-interaction-match">
        <div className="Task-interaction-match-options">
          {matchOptions.map((card, index) => {
            return card ? (
              <TaskMatchOptionCard
                key={card?.blbTaskMatchoptionId || `first-${index}`}
                index={index}
                id={card?.blbTaskMatchoptionId}
                text={card?.blbTaskMatchoptionLabel}
                moveCard={!isDisabled ? moveCard : null}
                puzzled={card?.puzzled}
                correct={card?.correct}
                showAnswer={showAnswer}
                origin={"option"}
              />
            ) : (
              <div
                ref={refDrop}
                className="Task-interaction-match-option-card__neutral"
                key={`first1-${index}`}
              >
                <span className="Task-match-option-card__text"></span>
              </div>
            );
          })}
        </div>
        <div className="Task-interaction-match__puzzle">
          <div className="Task-interaction-match__statements">
            {statements.map((statement, index) => {
              const matchedCard = answers.find((c, i) => {
                return i === index;
              });
              const correctAnswer = matchoptions.find((matchoption, i) => {
                return (
                  statement.blbTaskTypeMatchOptionCorrectMatchoptionId ===
                  matchoption.blbTaskMatchoptionId
                );
              });

              const puzzled = matchedCard?.puzzled;
              const answerIsCorrect =
                statement.blbTaskTypeMatchOptionCorrectMatchoptionId ===
                answers[index]?.blbTaskMatchoptionId;
              const cls = `Task-interaction-match__statement ${
                puzzled ? "Task-interaction-match__statement--puzzled" : ""
              } ${
                puzzled && showAnswer
                  ? answerIsCorrect
                    ? "Task-interaction-match__statement--correct"
                    : "Task-interaction-match__statement--incorrect"
                  : ""
              }`;

              return (
                <div className={cls} key={statement.blbTaskTypeMatchOptionId}>
                  <span className="Task-interaction-match__statement__text">
                    {statement.blbTaskTypeMatchOptionLabel}
                  </span>
                  {showAnswer && !answerIsCorrect ? (
                    <span className="right-answer">{`(Rätt svar: ${correctAnswer.blbTaskMatchoptionLabel})`}</span>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="Task-interaction-match__answers">
            {answers.map((card, index) => {
              return card ? (
                <TaskMatchOptionCard
                  key={card?.blbTaskMatchoptionId || `second-${index}`}
                  index={index}
                  id={card?.blbTaskMatchoptionId}
                  text={card?.blbTaskMatchoptionLabel}
                  puzzled={card?.puzzled}
                  correct={
                    showAnswer &&
                    card?.blbTaskMatchoptionId &&
                    card?.blbTaskMatchoptionId ===
                      statements[index].blbTaskTypeMatchOptionCorrectMatchoptionId
                  }
                  showAnswer={showAnswer}
                  origin={"match"}
                  moveCard={!isDisabled ? moveCard : null}
                />
              ) : (
                <TaskMatchOptionDroptarget
                  key={`drop-${index}`}
                  moveCard={!isDisabled ? moveCard : null}
                  targetIndex={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default TaskInteractionMatch;
