import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

const getTaskType = task => {
  // By capitalizing the first letter,
  // the returned type can be used
  // to build strings for accessing
  // task type-specific properties from
  // gql, such as `blbTaskOptionType${getTaskType(task)}Options`
  return capitalizeFirstLetter(task.blbGroupTaskOptions.blbTaskOptionType);
};

export default getTaskType;
